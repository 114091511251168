<template>
  <bbh-dialog
    ref="search-dialog"
    :is-search-bar="true"
    :open-modal="showDialog"
    @close="closeDialog"
  >
    <template #opener>
      <bbh-tooltip
        :open-delay="500"
      >
        <template #activator>
          <bbh-icon-button
            id="search-btn"
            icon="$search"
            :ripple="false"
            class="header-menu-btn"
            @click="openSearch()"
          />
        </template>
        {{ btn.infoText }}
        <span class="short-cut-text">
          ({{ btn.shortCut }})
        </span>
      </bbh-tooltip>
    </template>
    <template #text>
      <bbh-search
        id="bbh-global-search"
        :items="items"
        :show-dialog="showDialog"
        :routes="routes"
        @close="closeDialog"
        @select="handleSelect"
      />
    </template>
  </bbh-dialog>
</template>

<script>
import ElementTyp from '@src/model/enums/ElementTyp';
import UserRolle from '@src/model/enums/UserRolle';
import types from '@src/store/types';
import { mapGetters } from 'vuex';

import routes from '@src/router/routes';

import services from '@src/services';
import BbhSearch from './BbhSearch.vue';

const userGetters = types.getters.user;

export default {
  name: 'BbhSearchDialog',
  components: {
    BbhSearch,
  },
  data: () => ({
    showDialog: false,

    searchValue: '',
    items: [],

    btn: {
      infoText: 'Suche',
      shortCut: 'Strg + K',
    },

    eventListenerDebounce: null,
  }),
  computed: {
    ...mapGetters({
      userHasMinOneOfRoles: userGetters.USER_HAS_MIN_ONE_OF_ROLES,
      userClients: userGetters.USER_CLIENTS,
    }),

    routes() {
      return this.getRoutes(routes);
    },
  },
  mounted() {
    this.fetchItems();

    document.addEventListener('keydown', this.shortCuts, true);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.shortCuts, true);
  },
  methods: {
    fetchItems() {
      const requiresAuth = [
        UserRolle.PARTNER,
        UserRolle.ADMINPARTNER,
        UserRolle.PRODUKTKOORDINATOR,
      ];

      let promises = [ services.editor.client.getSimpleList(this.userClients[0].clientId) ];

      if (this.userHasMinOneOfRoles(requiresAuth)) {
        promises = [
          ...promises,
          services.editor.contract.getSimpleListOfType(ElementTyp.MUSTERDOKUMENT),
          services.editor.contract.getSimpleListOfType(ElementTyp.KLAUSEL),
          services.editor.contract.getSimpleListOfType(ElementTyp.MUSTERVERTRAG),
        ];
      }

      return Promise.all(promises)
        .then((results) => {
          this.items = results.reduce((acc, result, index) => {
            const mappedResult = result.data.elementList.map((item) => ({
              ...item,
              type: [
                ElementTyp.DOKUMENT,
                ElementTyp.MUSTERDOKUMENT,
                ElementTyp.KLAUSEL,
                ElementTyp.MUSTERVERTRAG,
              ][index],
            }));

            return [
              ...acc,
              ...mappedResult,
            ];
          }, [ ...this.routes ]);
        });
    },

    getRoutes(routesProp) {
      return routesProp.reduce((acc, cv) => {
        if (
          cv.meta?.administration
          && !this.userHasMinOneOfRoles(cv.meta.requiresAuth)
        ) {
          return acc;
        }

        if (cv.children) {
          // eslint-disable-next-line no-param-reassign
          acc = [
            ...acc,
            ...this.getRoutes(cv.children),
          ];
        }
        if (!cv.name) return acc;

        const cvHasRequiredParams = cv.path
          .split('/')
          .some((path) => path.includes(':') && !path.includes('?'));

        if (cvHasRequiredParams) return acc;
        acc.push({ ...cv, type: 'ROUTE' });
        return acc;
      }, []);
    },

    openSearch() {
      this.showDialog = false;

      this.$nextTick(() => {
        this.showDialog = true;
      });
    },
    closeDialog() {
      this.showDialog = false;
    },

    handleSelect(item) {
      switch (item.type) {
      case ElementTyp.MUSTERDOKUMENT:
        this.$router.push({
          name: 'InternerEditor',
          params: { musterdokumentId: item.elementId },
        }).catch(() => {});
        break;
      case ElementTyp.KLAUSEL:
        this.$router.push({ name: 'KlauselEditor', params: { klauselId: item.elementId } });
        break;
      case ElementTyp.MUSTERVERTRAG:
        this.$router.push({ name: 'Mustervertraege', params: { contractId: item.elementId } });
        break;
      case ElementTyp.DOKUMENT:
        this.$router.push({ name: 'MeineDokumente', params: { openNewContractDialog: 'close', clientElementId: item.clientElementId } });
        break;
      case 'ROUTE':
        this.$router.push({
          name: item.name,
        })
          .catch(() => {});
        break;
      default:
        break;
      }
      this.closeDialog();
    },

    shortCuts(e) {
      if (this.eventListenerDebounce) {
        clearTimeout(this.eventListenerDebounce);
      }
      this.eventListenerDebounce = setTimeout(() => {
        if (!e.ctrlKey) return;

        switch (e.key) {
        case 'k':
          this.openSearch();
          break;
        default:
          break;
        }
      }, 100);
    },
  },
};
</script>

<style lang='scss' scoped>
#search-btn {
  width: 24px;
  height: 24px;

  &::before {
    display: none;
  }
}

.short-cut-text {
  font-size: 14px;
  color: var(--bbh-grey-80);
}

#bbh-global-search {
  position: relative;
  left: -10px;
  top: -10px;
  height: calc(100% + 20px);
}
</style>

<style lang='scss'>
#search-icon.v-icon .v-icon__component {
  width: 24px;
  height: 24px;
  --bbh-icon-color: var(--bbh-black)
}
#bbh-global-search {
  & > div,
  & .search-bar.v-text-field.v-input,
  & .search-bar.v-text-field.v-input .v-input__control,
  & .search-bar.v-text-field.v-input .v-input__slot {
    height: 100%;
  }
}
</style>
