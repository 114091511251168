import UserRolle from '../model/enums/UserRolle';

export default [
  {
    path: '/',
    redirect: '/bbh',
  },
  {
    path: '/bbh',
    name: 'Home',
    text: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
      ],
    },
  },
  {
    path: '/administration',
    component: {
      // Inline declaration of a component that renders our <router-view>
      render: (c) => c('router-view'),
    },
    meta: {
      administration: true,
      requiresAuth: [
        UserRolle.PARTNER,
        UserRolle.ADMINPARTNER,
        UserRolle.PRODUKTKOORDINATOR,
        UserRolle.MANDANTENFREIGABE,
        UserRolle.PRODUKTFREIGABE,
      ],
      breadCrumb() {
        return [
          {
            text: 'Home',
            to: { name: 'Home' },
          },
          {
            text: 'Administration',
          },
        ];
      },
    },
    children: [
      {
        path: 'verwaltungsansichten',
        name: 'Verwaltungsansichten',
        component: () => import(
          '../views/verwaltungsansichten/Verwaltungsansichten.vue'
        ),
        meta: {
          administration: true,
          requiresAuth: [
            UserRolle.PARTNER,
            UserRolle.ADMINPARTNER,
            UserRolle.PRODUKTKOORDINATOR,
            UserRolle.MANDANTENFREIGABE,
            UserRolle.PRODUKTFREIGABE,
          ],
          breadCrumb() {
            return [
              {
                text: 'Home',
                to: { name: 'Home' },
              },
              {
                text: 'Administration',
                disabled: true,
              },
              {
                text: 'Verwaltungsansichten',
              },
            ];
          },
        },
        children: [
          {
            path: 'hashtags',
            name: 'Hashtags',
            component: () => import(
              '../views/verwaltungsansichten/Hashtags.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.ADMINPARTNER,
                UserRolle.PRODUKTKOORDINATOR,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Hashtags',
                  },
                ];
              },
            },
          },
          {
            path: 'entscheidungen',
            name: 'Entscheidungen',
            component: () => import(
              '../views/verwaltungsansichten/Entscheidungen.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.ADMINPARTNER,
                UserRolle.PRODUKTKOORDINATOR,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Entscheidungen',
                  },
                ];
              },
            },
          },
          {
            path: 'musterdokumente',
            name: 'Musterdokumente',
            component: () => import(
              '../views/verwaltungsansichten/Musterdokumente.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.ADMINPARTNER,
                UserRolle.PRODUKTKOORDINATOR,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Musterdokumente',
                  },
                ];
              },
            },
          },
          {
            path: 'kollektionen-bearbeiten/:id?',
            name: 'KollektionenBearbeiten',
            component: () => import(
              '../views/verwaltungsansichten/KollektionenBearbeiten.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [ UserRolle.PRODUKTKOORDINATOR ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Kollektionen bearbeiten',
                  },
                ];
              },
            },
          },
          {
            path: 'musterklauseln',
            name: 'Musterklauseln',
            component: () => import(
              '../views/verwaltungsansichten/Musterklauseln.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.ADMINPARTNER,
                UserRolle.PRODUKTKOORDINATOR,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Musterklauseln',
                  },
                ];
              },
            },
          },
          {
            // id := parentHeadingId
            path: 'vertrag-bearbeiten/:collectionId/:id/:elementId/:vertragsId/:vertragsRevision/:vertragsName?',
            name: 'VertragBearbeiten',
            component: () => import(
              '../views/verwaltungsansichten/VertragBearbeiten.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.ADMINPARTNER,
                UserRolle.PRODUKTKOORDINATOR,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Vertrag bearbeiten',
                  },
                ];
              },
            },
          },
          {
            path: 'mustervertraege/:contractId?',
            name: 'Mustervertraege',
            component: () => import(
              '../views/verwaltungsansichten/Mustervertraege.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.ADMINPARTNER,
                UserRolle.PRODUKTKOORDINATOR,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Musterverträge',
                  },
                ];
              },
            },
          },
          {
            path: 'taskansicht',
            name: 'Taskansicht',
            component: () => import(
              '../views/verwaltungsansichten/Taskansicht.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.ADMINPARTNER,
                UserRolle.PRODUKTKOORDINATOR,
                UserRolle.MANDANTENFREIGABE,
                UserRolle.PRODUKTFREIGABE,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Taskansicht',
                  },
                ];
              },
            },
          },
          {
            path: 'rechnungen',
            name: 'Rechnungen',
            component: () => import(
              '../views/verwaltungsansichten/Rechnungen.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.ADMINPARTNER,
                UserRolle.PRODUKTKOORDINATOR,
                UserRolle.MANDANTENFREIGABE,
                UserRolle.PRODUKTFREIGABE,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Rechnungen',
                  },
                ];
              },
            },
          },
          {
            path: 'paketverwaltung/:id?',
            name: 'Paketverwaltung',
            component: () => import(
              '../views/verwaltungsansichten/Paketverwaltung.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.PRODUKTFREIGABE,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Paketverwaltung',
                  },
                ];
              },
            },
          },
          {
            path: 'mandanten-paketverwaltung',
            name: 'MandantenPaketverwaltung',
            component: () => import(
              '../views/verwaltungsansichten/MandantenPaketverwaltung.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.PRODUKTFREIGABE,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'MandantenPaketverwaltung',
                  },
                ];
              },
            },
            children: [
              {
                path: 'paketverwaltung/:clientId',
                name: 'Paketaktualisierung',
                component: () => import(
                  '../views/verwaltungsansichten/Paketaktualisierung.vue'
                ),
                meta: {
                  administration: true,
                  requiresAuth: [ UserRolle.PRODUKTFREIGABE ],
                  breadCrumb() {
                    return [
                      {
                        text: 'Home',
                        to: { name: 'Home' },
                      },
                      {
                        text: 'Administration',
                        disabled: true,
                      },
                      {
                        text: 'Verwaltungsansichten',
                        to: { name: 'Verwaltungsansichten' },
                      },
                      {
                        text: 'MandantenPaketverwaltung',
                        to: { name: 'MandantenPaketverwaltung' },
                      },
                      {
                        text: 'Paketverwaltung',
                      },
                    ];
                  },
                },
              },
            ],
          },
          {
            path: 'fragebogen/:elementId',
            name: 'FragebogenBearbeiten',
            component: () => import(
              '../views/verwaltungsansichten/FragebogenBearbeiten.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.ADMINPARTNER,
                UserRolle.PRODUKTKOORDINATOR,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Musterverträge',
                    to: { name: 'Mustervertraege' },
                  },
                  {
                    text: 'Fragebogen bearbeiten',
                  },
                ];
              },
            },
          },
          {
            path: 'servicezugangverwaltung',
            name: 'Servicezugangverwaltung',
            component: () => import(
              '../views/verwaltungsansichten/Servicezugangverwaltung.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.ADMINPARTNER,
                UserRolle.PRODUKTKOORDINATOR,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Servicezugänge',
                  },
                ];
              },
            },
          },
          {
            path: 'mandanten-users-list',
            name: 'MandantenUsersList',
            component: () => import(
              '../views/mandantenUsers/MandantenUsersList.vue'
            ),
            meta: {
              administration: true,
              requiresAuth: [
                UserRolle.PARTNER,
                UserRolle.ADMINPARTNER,
                UserRolle.PRODUKTKOORDINATOR,
              ],
              breadCrumb() {
                return [
                  {
                    text: 'Home',
                    to: { name: 'Home' },
                  },
                  {
                    text: 'Administration',
                    disabled: true,
                  },
                  {
                    text: 'Verwaltungsansichten',
                    to: { name: 'Verwaltungsansichten' },
                  },
                  {
                    text: 'Liste aller Nutzer aller Mandanten',
                  },
                ];
              },
            },
          },
        ],
      },
      {
        path: 'neuer-mandant/:aufgabeBearbeiterId/:isProduktfreigabe?',
        name: 'NeuerMandant',
        component: () => import(
          '../views/verwaltungsansichten/NeuerMandant.vue'
        ),
        meta: {
          administration: true,
          requiresAuth: [
            UserRolle.MANDANTENFREIGABE,
            UserRolle.PRODUKTFREIGABE,
          ],
          breadCrumb() {
            return [
              {
                text: 'Home',
                to: { name: 'Home' },
              },
              {
                text: 'Administration',
                disabled: true,
              },
              {
                text: 'Neuer Mandant',
              },
            ];
          },
        },
      },
    ],
  },
  {
    path: '/editor/mandant/:clientElementId/:subElementId',
    name: 'Editor',
    component: () => import('../views/dokumentenansicht/MandantEditor.vue'),
    meta: {
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Meine Dokumente',
          to: { name: 'MeineDokumente' },
        },
        {
          text: 'Editor',
        },
      ],
    },
  },
  {
    path: '/editor/intern/klausel/:klauselId/:falseId?',
    name: 'KlauselEditor',
    component: () => import('../views/dokumentenansicht/KlauselEditor.vue'),
    meta: {
      requiresAuth: [
        UserRolle.PARTNER,
        UserRolle.ADMINPARTNER,
        UserRolle.PRODUKTKOORDINATOR,
      ],
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Administration',
          disabled: true,
        },
        {
          text: 'Verwaltungsansichten',
          to: { name: 'Verwaltungsansichten' },
        },
        {
          text: 'Musterklauseln',
          to: { name: 'Musterklauseln' },
        },
        {
          text: 'KlauselEditor',
        },
      ],
    },
  },
  {
    path: '/editor/intern/dokument/:mustervertragId?/:musterdokumentId',
    name: 'InternerEditor',
    component: () => import('../views/dokumentenansicht/InternerEditor.vue'),
    meta: {
      requiresAuth: [
        UserRolle.PARTNER,
        UserRolle.ADMINPARTNER,
        UserRolle.PRODUKTKOORDINATOR,
      ],
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Administration',
          disabled: true,
        },
        {
          text: 'Verwaltungsansichten',
          to: { name: 'Verwaltungsansichten' },
        },
        {
          text: 'Musterdokumente',
          to: { name: 'Musterdokumente' },
        },
        {
          text: 'Interner Editor',
        },
      ],
    },
  },
  {
    path: '/meine-dokumente/:openNewContractDialog?/:clientElementId?',
    name: 'MeineDokumente',
    component: () => import(
      '../views/verwaltungsansichten/MeineDokumente.vue'
    ),
    meta: {
      breadCrumb() {
        return [
          {
            text: 'Home',
            to: { name: 'Home' },
          },
          {
            text: 'Meine Dokumente',
          },
        ];
      },
    },
  },
  {
    path: '/meine-rechnungen/',
    name: 'MeineRechnungen',
    component: () => import(
      '../views/verwaltungsansichten/MeineRechnungen.vue'
    ),
    meta: {
      breadCrumb() {
        return [
          {
            text: 'Home',
            to: { name: 'Home' },
          },
          {
            text: 'Meine Rechnungen',
          },
        ];
      },
    },
  },
  {
    path: '/meine-abos/',
    name: 'MeineAbos',
    component: () => import(
      '../views/verwaltungsansichten/MeineAbos.vue'
    ),
    meta: {
      breadCrumb() {
        return [
          {
            text: 'Home',
            to: { name: 'Home' },
          },
          {
            text: 'Meine Abos',
          },
        ];
      },
    },
  },
  {
    path: '/kollektionen/:id?',
    name: 'Kollektionen',
    component: () => import(
      '../views/verwaltungsansichten/Kollektionen.vue'
    ),
    meta: {
      breadCrumb() {
        return [
          {
            text: 'Home',
            to: { name: 'Home' },
          },
          {
            text: 'Vertragswelt',
          },
        ];
      },
    },
  },
  {
    path: '/kollektionen/:collectionId/:id/vertrag-details/:vertragsId/:vertragsRevision',
    name: 'VertragsDetails',
    component: () => import(
      '../views/verwaltungsansichten/VertragsDetails.vue'
    ),
    meta: {
      breadCrumb() {
        return [
          {
            text: 'Home',
            to: { name: 'Home' },
          },
          {
            text: 'Vertragswelt',
            to: { name: 'Kollektionen', params: { id: '' } },
          },
          {
            text: 'Vertrags-Details',
          },
        ];
      },
    },
  },
  {
    path: '/fragebogen/:contractId/:revision/:update?/:clientElementId?',
    name: 'Fragebogen',
    component: () => import(
      '../views/verwaltungsansichten/Fragebogen.vue'
    ),
    meta: {
      breadCrumb() {
        return [
          {
            text: 'Home',
            to: { name: 'Home' },
          },
          {
            text: 'Fragebogen',
          },
        ];
      },
    },
  },
  {
    path: '/benutzereinstellungen',
    name: 'Benutzereinstellungen',
    component: () => import(
      '../views/benutzer/Benutzereinstellungen.vue'
    ),
    meta: {
      breadCrumb() {
        return [
          {
            text: 'Home',
            to: { name: 'Home' },
          },
          {
            text: 'Benutzereinstellungen',
          },
        ];
      },
    },
  },
  {
    path: '/secret-view',
    name: 'SecretView',
    component: () => import(
      '../views/secret/SecretView.vue'
    ),
    meta: {
      administration: true,
      requiresAuth: [
        UserRolle.PARTNER,
        UserRolle.ADMINPARTNER,
        UserRolle.PRODUKTKOORDINATOR,
      ],
      breadCrumb() {
        return [
          {
            text: 'Home',
            to: { name: 'Home' },
          },
          {
            text: 'SecretView',
          },
        ];
      },
    },
  },
  {
    path: '*',
    redirect: '/bbh',
  },
];
