import config from './config';
import { get, post } from '../axios';

const { API_PREFIX } = config;

const VALID_LOGIC_OPERATOR = [
  'and', 'or',
];

export default {
  /**
   *
   * @param {String} logicOperatorBetweenHashtagTypes
   * @param {String} logicOperatorWithinHashtagTypes
   * @param {Array} types
   * @param {Array} hashtagTypes
   * @returns
   */
  fetchSearch(
    {
      logicOperatorBetweenHashtagTypes,
      logicOperatorWithinHashtagTypes,
      types,
      hashtagTypes,
    },
  ) {
    const URL = `${API_PREFIX}/contractGeneratorSearch`;

    const PAYLOAD = {
      logicOperatorBetweenHashtagTypes,
      logicOperatorWithinHashtagTypes,
      types,
      hashtagTypes,
    };

    return new Promise((resolve, reject) => {
      if (!VALID_LOGIC_OPERATOR.includes(logicOperatorBetweenHashtagTypes)) {
        reject(new Error('invalid logic operator'));
        return;
      }

      if (!VALID_LOGIC_OPERATOR.includes(logicOperatorWithinHashtagTypes)) {
        reject(new Error('invalid logic operator'));
        return;
      }

      post(URL, PAYLOAD)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {String} queryText
   * @returns
   */
  fulltextSearch(queryText) {
    const URL = `${API_PREFIX}/clauseFulltextSearch/${queryText}`;

    return new Promise((resolve, reject) => {
      if (!queryText || typeof queryText !== 'string' || !queryText.trim()) {
        reject(new Error('invalid query text'));
        return;
      }

      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};
