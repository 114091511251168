import { v4 as uuidv4 } from 'uuid';

function uuid() {
  return uuidv4();
}

function validate(id) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id);
}

export {
  uuid,
  validate,
};
