import config from './config';

import { get, put } from '../axios';

const { API_PREFIX } = config;

export default {
  fetchAll() {
    const url = `${API_PREFIX}/choice`;
    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {String} elementId
   * @param {Number} revision
   * @returns Array of choices
   */
  fetchElementChoices(elementId, revision) {
    const url = `${API_PREFIX}/choice/${revision}/${elementId}`;
    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {JSON} choice
   * @returns
   */
  save(choice) {
    const url = `${API_PREFIX}/choice`;
    return new Promise((resolve, reject) => {
      put(url, choice)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

};
