import style from './default-style';

export default {
  name: 'bold',
  attrs: {
    id: { default: null },
  },
  parseDOM: [
    {
      tag: 'marker',
    },
  ],
  toDOM() {
    const element = document.createElement('marker');
    Object.assign(element.style, style, { backgroundColor: 'orange' });
    return element;
  },
};
