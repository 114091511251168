import {
  get, post,
} from '../axios';

const REQUEST_MAPPING = 'clientVertragUpdate';

export default {
  createMustervertragNachfolger(vertragId, vertragsRevions) {
    const url = `/${REQUEST_MAPPING}/${vertragId}/${vertragsRevions}/erzeugeNachfolger`;

    return post(url);
  },

  checkNachfolger(vertragId, vertragsRevions) {
    const url = `/${REQUEST_MAPPING}/${vertragId}/${vertragsRevions}/nachfolgeStatus`;

    return get(url);
  },

  /**
   * get FragebogenDisclaimer für elementId
   *
   * @param {String} elementId
   */
  getFragebogenDisclaimer(elementId) {
    const url = `/${REQUEST_MAPPING}/${elementId}/disclaimer/fragebogen`;

    return get(url);
  },

  /**
   * get DokumentenDisclaimer für elementId
   *
   * @param {String} elementId
   */
  getDokumentenDisclaimer(elementId) {
    const url = `/${REQUEST_MAPPING}/${elementId}/disclaimer/dokumente`;

    return get(url);
  },

  /**
   * get ElementId by ClientElementId and Revision
   *
   * @param {String} clientElementId
   * @param {Number} revision
   */
  getElementIdByClientElementIdAndRevision(clientElementId, revision) {
    const url = `/${REQUEST_MAPPING}/${clientElementId}/${revision}/elementId`;
    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => {
          if (error.response.status === 404) {
            resolve(null);
            return;
          }
          reject(error);
        });
    });
  },
};
