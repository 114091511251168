import {
  get, post, put, remove,
} from '../axios';

const REQUEST_MAPPING = 'client';

export default {
  /**
   * Updaten der UpdateListe eines clientElements
   *
   * @param {String} clientElementId
   * @param {Object} body - Update-Objekt
   */
  updateUpdateList(clientElementId, body) {
    const url = `/${REQUEST_MAPPING}/clientElement/${clientElementId}/updates`;

    return new Promise((resolve, reject) => {
      put(url, body)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   * Setzen des Update-Abgeschlossen-Status eines clientElements
   *
   * @param {String} clientId
   * @param {String} clientElementId
   */
  setUpdateCompleted(clientId, clientElementId) {
    const url = `/${REQUEST_MAPPING}/${clientId}/${clientElementId}/updateAbgeschlossen`;

    return new Promise((resolve, reject) => {
      post(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   * Deletes a Zwangsupdate section for a specific client and client element.
   *
   * @param {string} clientId - The ID of the client.
   * @param {string} clientElementId - The ID of the client element.
   * @param {string} sectionId - The ID of the section to be deleted.
   * @returns {Promise}
   */
  deleteZwangsupdateSection(clientId, clientElementId, sectionId) {
    const url = `/${REQUEST_MAPPING}/${clientId}/${clientElementId}`
      + `/zwangsupdateSection/${sectionId}`;

    return new Promise((resolve, reject) => {
      remove(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   * Retrieves the fallback information for a specific client and client element.
   *
   * @param {string} clientId - The ID of the client.
   * @param {string} clientElementId - The ID of the client element.
   * @returns {Promise}
   */
  getFallbackInfo(clientId, clientElementId) {
    const url = `/${REQUEST_MAPPING}/${clientId}/${clientElementId}/fallbackInfo`;

    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },
};
