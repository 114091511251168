import { get } from '../axios';

const API_PREFIX = '/hilfe';

export default {
  /**
   * @param {String} route
   * @returns
   */
  fetchHilfeByRoute(route) {
    const URL = `${API_PREFIX}/route/${route}`;
    return get(URL);
  },

  /**
   * @param {String} videoId
   * @returns
   */
  fetchVideoName(videoId) {
    const URL = `${API_PREFIX}/video/${videoId}/name`;

    return get(URL);
  },
};
