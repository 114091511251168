import config from './config';
import {
  get, post, put, remove,
} from '../axios';

const { API_PREFIX } = config;

export default {
  /**
   * Get user by keycloakUserUuid
   *
   * @param {String} keycloakUserUuid
   * @returns {Promise}
   */
  userByKeycloakUserUuid(keycloakUserUuid) {
    const URL = `${API_PREFIX}/${keycloakUserUuid}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   *  Get all users
   *
   */
  getAllUsers() {
    const url = `${API_PREFIX}/all`;
    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   *  Get all admin users
   *
   */
  getAllAdminUsers() {
    const url = `${API_PREFIX}/admins`;
    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   * Fetches the user information
   * @returns
   */
  getUserDetails() {
    const URL = `${API_PREFIX}/details/lemon-app`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Puts the user information
   *
   * @param {Object} user
   *
   * @returns
   */
  putUserDetails(user) {
    const URL = `${API_PREFIX}/details`;

    return new Promise((resolve, reject) => {
      put(URL, user)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Delete user
   *
   * @param {String} keycloakUserUuid
   */
  deleteUser(keycloakUserUuid) {
    const URL = `${API_PREFIX}/${keycloakUserUuid}`;

    return new Promise((resolve, reject) => {
      remove(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Delete user invitation
   *
   * @param {String} userRegistrierenId
   */
  deleteUserInvitation(userRegistrierenId) {
    const URL = `${API_PREFIX}/eingeladen/${userRegistrierenId}`;

    return new Promise((resolve, reject) => {
      remove(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Sets a new password for the user
   *
   * @param {Object} passwordObject new password in 'newPassword'-Attribute
   * @param {String} keycloakUserUuid
   * @returns
   */
  changePassword(passwordObject, keycloakUserUuid) {
    const URL = `${API_PREFIX}/changePassword/${keycloakUserUuid}`;

    return new Promise((resolve, reject) => {
      put(URL, passwordObject)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Get all tags from user
   *
   * @returns
   */
  getUserTags() {
    const URL = `${API_PREFIX}/clientTag`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Sets new or updates existing tag for user
   *
   * @param {Object} client new or updated TagObject
   * @returns
   */
  putUserTag(client) {
    const URL = `${API_PREFIX}/clientTag`;

    return new Promise((resolve, reject) => {
      put(URL, client)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Sets new or updates existing tag for user
   *
   * @param {String} tagId TagId to delete
   * @returns
   */
  deleteUserTag(tagId) {
    const URL = `${API_PREFIX}/clientTag/${tagId}`;

    return new Promise((resolve, reject) => {
      remove(URL, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * requests user role
   *
   * @param {Object} userRoleObj
   * @returns
   */
  postRequestRole(userRoleObj) {
    const URL = `${API_PREFIX}/role/request`;

    return new Promise((resolve, reject) => {
      post(URL, userRoleObj)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * removes keycloak user role
   *
   * @param {String} keycloakUserUuid
   * @param {String} keycloakRoleUuid
   * @returns
   */
  deleteKeycloakRole(keycloakUserUuid, keycloakRoleUuid) {
    const URL = `${API_PREFIX}/${keycloakUserUuid}/role/keycloak/${keycloakRoleUuid}`;

    return new Promise((resolve, reject) => {
      remove(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * removes additional user role
   *
   * @param {String} keycloakUserUuid
   * @param {String} clientId
   * @param {String} additionalRole
   * @returns
   */
  deleteAdditionalRole(keycloakUserUuid, clientId, additionalRole) {
    const URL = `${API_PREFIX}/${keycloakUserUuid}/role/additonal/${clientId}/${additionalRole}`;

    return new Promise((resolve, reject) => {
      remove(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * requests all available user roles
   *
   * @returns
   */
  getAvailableRoles(clientId = 'undefined') {
    const URL = `${API_PREFIX}/role/available/${clientId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * requests all available additional user roles
   *
   * @param {String} clientId
   * @returns
   */
  getAdditionalRequestedRoles(clientId) {
    const URL = `${API_PREFIX}/role/additonalRequested/${clientId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * requests all available keycloak user roles
   *
   * @returns
   */
  getKeycloakRequestedRoles() {
    const URL = `${API_PREFIX}/role/keycloakRequested`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Gets the settings for and excplicit path
   *
   * @param {String} path for settings
   * @returns
   */
  getPathPreference(path) {
    const URL = `${API_PREFIX}/preference/${path}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Sets the settings for and excplicit path
   *
   * @param {String} path for settings
   * @param {Object} settings for this path
   * @returns
   */
  putPathPreference(path, settings) {
    const URL = `${API_PREFIX}/preference/${path}`;

    return new Promise((resolve, reject) => {
      put(URL, settings)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Invites a user
   *
   * @param {String} clientId
   * @param {String} email
   * @returns
   */
  inviteNewMandantNutzer(clientId, email) {
    const URL = `${API_PREFIX}/mandant/${clientId}/neu`;
    return new Promise((resolve, reject) => {
      post(URL, {
        email,
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * get singleAccessToken for docx file
   *
   * @param {String} id - id of docx file
   */
  getSingleAccessToken(id) {
    const url = `${API_PREFIX}/singleAccessToken/${id}`;
    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },
};
