import { validate } from '../utils/uuid';

export default class Reference {
  /**
   *
   * @param {String} id UUID
   * @param {String} dstId UUID
   * @param {Array} dstRefPath Array of UUIDs
   * @param {Array} srcRefPath Array of UUIDs
   */
  constructor(
    id = null,
    dstId = null,
    dstRefPath = [],
    srcRefPath = [],
  ) {
    this.id = id;
    this.dstId = dstId;
    this.dstRefPath = dstRefPath;
    this.srcRefPath = srcRefPath;
  }

  get validRefId() {
    return this.id === null
      || (!!this.id
        && typeof this.id === 'string'
        && validate(this.id));
  }

  get validDstId() {
    return !!this.dstId
      && typeof this.dstId === 'string'
      && validate(this.dstId);
  }

  get validDstRefPath() {
    return Array.isArray(this.dstRefPath) && this.dstRefPath.every((refPath) => validate(refPath));
  }

  get validSrcRefPath() {
    return Array.isArray(this.srcRefPath) && this.srcRefPath.every((refPath) => validate(refPath));
  }
}
