import { isJSON } from '@st/utils-js';
import Frage from './Frage';
import FrageTyp from './enums/FrageTyp';

export default class Fragebogen {
  /**
   *
   * @param {Array} content Array of Frage
   * @param {JSON} attrs
   * @param {String} type
   */
  constructor(
    content = [],
    attrs = {},
    type = 'questionnaire',
  ) {
    this.attrs = attrs;
    this.type = type;
    this.content = content;
  }

  clone() {
    const antwortIdMapping = {};

    const newContent = this.content.map((frage) => {
      const newFrage = frage.clone(antwortIdMapping);

      if (FrageTyp.isQuestion(frage.type)) {
        frage.content.forEach((antwort, index) => {
          antwortIdMapping[antwort.attrs.id] = newFrage.content[index].attrs.id;
        });
      }

      return newFrage;
    });

    return new Fragebogen(
      newContent,
      this.attrs,
      this.type,
    );
  }

  /**
   * Creates Fragebogen from JSON. If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    let { content = [] } = value;
    const { attrs, type } = value;

    content = content
      .filter((v) => !!v)
      .map((frage) => Frage.fromJSON(frage));

    return new Fragebogen(content, attrs, type);
  }

  /**
   * @returns Fragebogen in JSON representation
   */
  toJSON() {
    const content = [];
    this.content.forEach((frage) => {
      if (frage instanceof Frage) {
        content.push(frage.toJSON());
      } else {
        content.push(frage);
      }
    });

    return {
      type: this.type,
      attrs: this.attrs,
      content,
    };
  }

  get validContent() {
    return Array.isArray(this.content)
      && this.content.length !== 0
      && this.content.filter((f) => !!f).length > 0
      && this.content.every((question) => question instanceof Frage && question.valid);
  }

  get valid() {
    const valid = this.validContent;
    return valid;
  }
}
