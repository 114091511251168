import style from './default-style';

export default {
  parseDOM: [
    { tag: 'underline' },
    { style: 'text-decoration=underline' },
  ],
  toDOM() {
    const element = document.createElement('underline');
    Object.assign(element.style, style, { textDecoration: 'underline' });
    return element;
  },
};
