import {
  get, put, patch, post, remove,
} from '../axios';

const REQUEST_MAPPING = 'contractgenerator';

export default {
  /**
   *
   * @param {String} contractId
   */
  fetch(contractId) {
    const url = `/${REQUEST_MAPPING}/documentContainer/${contractId}/full`;
    return get(url);
  },

  /**
   *
   * @param {JSON} contract contract-object, which should be saved in the database
   * @returns saved contract with new id
   */
  save(contract) {
    const url = `/${REQUEST_MAPPING}/documentContainer`;

    return put(url, contract);
  },

  /**
   *
   * @param {String} contractId
   * @param {Array<Object>} responsibilities
   *    Array with responsibility-objects: {keycloakUserUuid, responsibility}
   * @returns result
   */
  assignResponsibilities(contractId, responsibilities = []) {
    const url = `/${REQUEST_MAPPING}/documentContainerResponsibility/${contractId}`;
    return new Promise((resolve, reject) => {
      put(url, responsibilities)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  /**
   * Fetch Element by elementId
   *
   * @param {} elementId
   */
  getElement(elementId) {
    const url = `/${REQUEST_MAPPING}/element/${elementId}`;
    return get(url);
  },

  /**
   * filter Klausel by hashtags
   *
   * @param {Object} body
   */
  getKlauselByHashtags(body) {
    const url = `/${REQUEST_MAPPING}/element/filter/klausel/byHashtags`;
    return post(url, body);
  },

  /**
   * AutoSave MusterElement
   *
   * @param {String} id
   * @param {JSON} patches
   * @param {number} revision
   * @param {String} saveTag
   */
  patchMusterElement({
    id, patches, revision, saveTag = 'auto_save',
  }) {
    const url = `/${REQUEST_MAPPING}/element/${saveTag}/${id}/${revision}`;
    return patch(url, {
      patch: patches,
    });
  },

  /**
   * Save new Element
   *
   * @param {String} id
   * @param {JSON} patches
   * @param {String} type
   */
  patchNewMusterElement({ id, patches, type }) {
    const url = `/${REQUEST_MAPPING}/element/save/${id}/0`;
    return patch(url, {
      patch: patches,
      state: 'modifiable',
      type,
    });
  },

  /**
   * Save new Element
   *
   * @param {String} id
   * @param {JSON} patches
   * @param {String} type
   */
  persistMusterElement({
    id, patches, type, revision,
  }) {
    const url = `/${REQUEST_MAPPING}/element/save/${id}/${revision}`;
    return patch(url, {
      patch: patches,
      state: 'modifiable',
      type,
    });
  },

  /**
   * Save new Element
   *
   * @param {String} id
   * @param {JSON} patches
   * @param {String} type
   */
  saveChoiceToElement({
    id, patches, revision,
  }) {
    const url = `/${REQUEST_MAPPING}/element/save/${id}/${revision}`;
    return patch(url, {
      patch: patches,
    });
  },

  /**
   * Get all Musterklauseln
   *
   * @param {Object} body
   *
   * @returns {Array<Object>}
   */
  getMusterklauseln(body) {
    const url = `/${REQUEST_MAPPING}/element/all/KLAUSEL`;
    return post(url, body);
  },

  /**
   * Get all required HashtagTypes for elementType and elementSubType
   *
   * @param {String} elementType
   * @param {String} elementSubType
   *
   * @returns {Array<Object>}
   */
  getHashtagTypes(elementType, elementSubType) {
    const url = `/${REQUEST_MAPPING}/hashtagTypes/${elementType}/${elementSubType}`;
    return get(url);
  },

  /**
   * Hinzufügen von Musterdokument zu Mustervertrag
   *
   * @param {String} elementId
   * @param {String} subElementId
   *
   * @returns {Array<Object>}
   */
  addSubelementToMustervertrag(elementId, subElementId) {
    const url = `/${REQUEST_MAPPING}/element/${elementId}/add/${subElementId}`;
    return post(url, {});
  },

  /**
   * Löschen von Musterdokument aus Mustervertrag
   *
   * @param {String} elementId MustervertragId
   * @param {String} refPathId RefPathId des Musterdokumentes
   *
   * @returns {Array<Object>}
   */
  deleteSubelementInMustervertrag(elementId, refPathId) {
    const url = `/${REQUEST_MAPPING}/element/${elementId}/remove/${refPathId}`;
    return remove(url);
  },

  /**
   * set client element tags
   *
   * @param {String} type Type des Elements (MUSTERVERTRAG, MUSTERDOKUMENT)
   *
   * @returns array of elements
   */
  getSimpleListOfType(type) {
    const URL = `/${REQUEST_MAPPING}/element/${type}/simpleList`;
    return get(URL);
  },

  /**
   * get revision of element
   *
   * @param {String} elementId
   */
  getElementRevision(elementId) {
    const URL = `/${REQUEST_MAPPING}/element/${elementId}/latestRevision`;
    return get(URL);
  },

  /**
   * get choices for element
   *
   * @param {String} elementId
   */
  getChoices(elementId) {
    const URL = `/${REQUEST_MAPPING}/element/${elementId}/choices`;
    return get(URL);
  },

  /**
   * Get update list of element
   *
   * @param {String} elementId
   */
  getUpdatesOfElement(elementId) {
    const URL = `/${REQUEST_MAPPING}/element/MUSTERVERTRAG/updateList/${elementId}`;
    return get(URL);
  },
};
