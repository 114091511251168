import axios from 'axios';

import conf from '../../../conf';

const API_PREFIX = '/testReset';

export default {

  /**
   * reset files for e2e tests
  */
  resetFiles() {
    const URL = `${conf.api}${API_PREFIX}/execute`;

    return new Promise((resolve, reject) => {
      axios.get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};
