import Vue from 'vue';

import VueKeyCloak from '@dsb-norge/vue-keycloak-js';

import moment from 'moment';
import VueMomentJS from 'vue-momentjs';
import * as Sentry from '@sentry/vue';
import * as SentryBrowser from '@sentry/browser';

import { DesignSystemPlugin } from '@lemon/design-system';
import '@lemon/design-system/dist/style.css';

import './index.css';

import App from './App.vue';
import router from './router';
import store from './store';

import {
  useKeycloakToken,
  getKeycloakToken,
  setKeycloakToken,
} from './plugins/axios-keycloak';

import conf from '../conf';

// Import components from Design-System
import services from './services';
import KeycloakUser from './model/KeycloakUser';
import userGetters from './store/modules/user/types/getter-types';
import welcomeLog from './utils/welcomeLog';

Vue.use(VueMomentJS, moment);

Vue.config.productionTip = false;

window.log = ((msg) => console.log(msg));
window.apiUrl = conf.api;

window.EventBus = new Vue();

let env = 'production';
if (import.meta.env.MODE === 'development') {
  env = `${import.meta.env.VITE_SENTRY_ENVIRONMENT}`;
}

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    Vue,
    dsn:
      'https://b3354c07ae4a4a78ac65d0bd5ca781c7@sentry.spicetech.de/2',
    integrations: [],
    logErrors: true,
    environment: `${env}-frontend`,
    ignoreErrors: [
      'fb_xd_fragment',
      /^ResizeObserver loop limit exceeded$/,
      /^ResizeObserver loop completed with undelivered notifications.$/,
    ],
    beforeSend(event) {
      if (event.exception) {
        const sentryElement = document.getElementById('sentry-overflow');

        if (sentryElement) {
          sentryElement.classList.toggle('d-none');
        }

        let user = { firstname: '', lastname: '', email: '' };
        try {
          const storeUser = store.getters[userGetters.USER];
          if (storeUser.email !== undefined) {
            user = storeUser;
          }
        } catch (error) {
          console.log(error);
        }
        SentryBrowser.showReportDialog({
          eventId: event.event_id,
          labelSubmit: 'Senden',
          user: { name: `${user.firstname} ${user.lastname}`, email: user.email },
          title: 'Ein Fehler ist aufgetreten',
          subtitle: 'Bitte senden Sie uns eine Nachricht und laden Sie die Seite neu!!!',
          subtitle2: '',
          lang: 'de',
          labelClose: '',
        });
      }
      return event;
    },
  });
}

function localGetUserTags() {
  store.dispatch('user/get_user_tags');
}

Vue.use(VueKeyCloak, {
  config: {
    realm: 'Lemon-Legal-Tech-Plattform',
    url: 'https://login.bbh-solutions.de/auth',
    clientId: 'lemon-app',
  },
  init: {
    onLoad: 'login-required',
    checkLoginIframe: false,
  },
  logout: {},
  onReady: async (keycloak) => {
    document.title = 'Lemon';

    Vue.config.productionTip = false;

    setKeycloakToken(keycloak.token);
    const axios = useKeycloakToken();

    try {
      const response = await services.user.users.getUserDetails();
      const user = KeycloakUser.fromJSON(response.data);
      store.commit('user/set_user', user);
      localGetUserTags();
      Sentry.setUser({ id: user.id, email: user.email });
    } catch (error) {
      console.error(error);
    }
    Vue.prototype.$axios = axios;

    welcomeLog();

    const options = {};
    Vue.use(DesignSystemPlugin, options);

    new Vue({
      ...options,
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');

    setInterval(() => {
      keycloak
        .updateToken(60)
        .then(() => {
          function lastDigits(str, count = 11) {
            return str.slice(str.length - count);
          }
          if (keycloak.token !== getKeycloakToken()) {
            console.info(
              'Token refreshed',
              `from: ${lastDigits(getKeycloakToken())}`,
              `to: ${lastDigits(keycloak.token)}`,
            );
            setKeycloakToken(keycloak.token);
            Vue.prototype.$axios = useKeycloakToken();
          }
        })
        .catch(() => {
          console.error('Failed to refresh token');
        });
    }, 10000);
  },
  onInitError: () => { },
});
