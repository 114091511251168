<template>
  <div
    v-if="showBanner"
    class="bbh-banner"
  >
    <v-icon>$hammerWench</v-icon>
    {{ bannerText }}
  </div>
</template>

<script>
export default {
  name: 'Banner',
  data: () => ({
    bannerText: 'Administrations-Oberfläche',
  }),
  computed: {
    showBanner() {
      return this.$route?.meta.administration;
    },
  },
};
</script>

<style lang="scss">
.bbh-banner {
  background: var(--bbh-dark-red);
  width: 100%;
  height: 40px;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;

  gap: 20px;

  font-family: "ApexNew-Medium";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: var(--bbh-white);

  & .v-icon .v-icon__component{
    width: 20px;
    height: 20px;
    --bbh-icon-color: var(--bbh-white);
  }

}
</style>
