import { isJSON } from '@st/utils-js';
import { validate } from '../utils/uuid';

export default class MandantDokumentElementQuestionnaire {
  /**
   *
   * @param {Array} answers Array with answerIds
   * @param {JSON} placeholderAnswers keys are placeholderIds
   *  and value of JSON are values of placeholder
   * @param {JSON} choices keys are active and sectionMap
   * @param {JSON} placeholders keys are active and sectionMap
   */
  constructor(
    answers = [],
    placeholderAnswers = {},
    choices = {
      active: [],
      sectionMap: {},
    },
    placeholders = {
      active: [],
      sectionMap: {},
    },
  ) {
    this.answers = answers;
    this.placeholderAnswers = placeholderAnswers;
    this.choices = choices;
    this.placeholders = placeholders;
  }

  /**
   * Creates MandantDokumentElementQuestionnaire from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      answers = [],
      placeholderAnswers = {},
      choices = {
        active: [],
        sectionMap: {},
      },
      placeholders = {
        active: [],
        sectionMap: {},
      },
    } = value;

    return new MandantDokumentElementQuestionnaire(
      answers,
      placeholderAnswers,
      choices,
      placeholders,
    );
  }

  /**
   * @returns MandantDokumentElementQuestionnaire in JSON representation
   */
  toJSON() {
    return {
      answers: this.answers,
      placeholderAnswers: this.placeholderAnswers,
      choices: this.choices,
      placeholders: this.placeholders,
    };
  }

  get valid() {
    const validAnswers = !!this.answers
      && Array.isArray(this.answers)
      && this.answers.every((answerId) => validate(answerId));

    const placeholderAnswers = !!this.placeholderAnswers
      && isJSON(this.placeholderAnswers)
      && Object.keys(this.placeholderAnswers)
        .every((placeholderId) => validate(placeholderId))
      && Object.values(this.placeholderAnswers)
        .every((value) => typeof value === 'string' && !!value.trim());

    const validChoices = !!this.choices
      && isJSON(this.choices)
      && Object.keys(this.choices).every((key) => [
        'active', 'sectionMap', 'upstreamActive',
      ].includes(key))
      && Array.isArray(this.choices.active)
      && this.choices.active.every((choiceId) => validate(choiceId))
      && isJSON(this.choices.sectionMap)
      && Object.keys(this.choices.sectionMap)
        .every((choiceId) => validate(choiceId))
      && Object.values(this.choices.sectionMap)
        .every((v) => Array.isArray(v) && v.every((v1) => validate(v1)))
      && this.choices.active.length <= Object.keys(this.choices.sectionMap).length
      && Object.keys(this.choices.sectionMap)
        .every((choiceId) => this.choices.active.includes(choiceId));

    const validPlaceholders = !!this.placeholders
      && isJSON(this.placeholders)
      && Object.keys(this.placeholders).every((key) => [
        'active', 'sectionMap',
      ].includes(key))
      && Array.isArray(this.placeholders.active)
      && this.placeholders.active.every((placeholderId) => validate(placeholderId))
      && isJSON(this.placeholders.sectionMap)
      && Object.keys(this.placeholders.sectionMap)
        .every((choiceId) => validate(choiceId))
      && Object.values(this.placeholders.sectionMap)
        .every((v) => Array.isArray(v) && v.every((v1) => validate(v1)))
      && this.placeholders.active.length <= Object.keys(this.placeholders.sectionMap).length
      && Object.keys(this.placeholders.sectionMap)
        .every((choiceId) => this.placeholders.active.includes(choiceId));

    const valid = validAnswers
    && placeholderAnswers
    && validChoices
    && validPlaceholders;

    return valid;
  }
}
