import { isJSON } from '@st/utils-js';
import ElementAttrs from './ElementAttrs';
import Responsibilities from './Responsibilities';
import { validate } from '../utils/uuid';
import UserRolle from './enums/UserRolle';

const REQUIRED_ROLES = [
  UserRolle.RECHTSANWALT,
  UserRolle.PARTNER,
  UserRolle.ADMINPARTNER,
];

export default class MusterdokumentElementAttrs extends ElementAttrs {
  /**
   *
   * @param {String} name
   * @param {Array} hashtags Array of UUIDs
   * @param {Responsibilities} responsibilities
   * @param {Array} importComments (optional) if migration was running
   */
  constructor(
    name = '',
    hashtags = [],
    responsibilities = new Responsibilities(),
    importComments = [],
  ) {
    super(name, hashtags, responsibilities, REQUIRED_ROLES);
    this.importComments = importComments;
  }

  /**
   * Creates MusterdokumentElementAttrs from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      name = '',
      hashtags = [],
      responsibilities = new Responsibilities(),
      importComments = [],
    } = value;

    let res = responsibilities;
    if (!(res instanceof Responsibilities) && isJSON(res)) {
      res = Responsibilities.fromJSON(res);
    }

    return new MusterdokumentElementAttrs(
      name,
      hashtags,
      res,
      importComments,
    );
  }

  /**
   * @returns MusterdokumentElementAttrs in JSON representation
   */
  toJSON() {
    let responsibilities = {};
    if (this.responsibilities instanceof Responsibilities) {
      responsibilities = this.responsibilities.toJSON();
    } else {
      responsibilities = this.responsibilities;
    }

    return {
      name: this.name,
      hashtags: this.hashtags,
      responsibilities,
      importComments: this.importComments,
    };
  }

  get validResponsibilities() {
    return !!this.responsibilities
      && this.responsibilities instanceof Responsibilities
      && validate(this.responsibilities[UserRolle.RECHTSANWALT])
      && validate(this.responsibilities[UserRolle.ADMINPARTNER])
      && validate(this.responsibilities[UserRolle.PARTNER])
      && !this.responsibilities[UserRolle.PRODUKTKOORDINATOR]
      && !this.responsibilities[UserRolle.MANDANT]
      && !this.responsibilities[UserRolle.SACHBEARBEITER];
  }

  get valid() {
    const { validName, validHashtags, validResponsibilities } = this;

    const valid = validName
      && validHashtags
      && validResponsibilities;

    return valid;
  }
}
