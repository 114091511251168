import UserRolle from '../../../../model/enums/UserRolle';
import userGetters from '../types/getter-types';

export default {
  [userGetters.USER]: (state) => state.user,
  [userGetters.USER_ANREDE]: (state) => `${state.user.metadata?.TITEL ? `${state.user.metadata.TITEL} ` : ''}${state.user.firstname} ${state.user.lastname}`,
  [userGetters.USER_CLIENTS]: (state) => state.user.clients || [],
  [userGetters.USER_TAGS]: (state) => state.user.settings?.tags || [],
  [userGetters.USER_TABS]: (state) => state.user.settings?.tabs || [],
  [userGetters.USER_SETTINGS_VIDEO]: (state) => {
    const key = Object.keys(state.user.settings).find((k) => k.includes('help-video'));
    if (!key) return false;
    return state.user.settings[key]?.value || false;
  },
  [userGetters.USER_SETTINGS_PERLENSCHNUR]: (state) => (contractId, subElementId) => {
    const key = Object.keys(state.user.settings).find((k) => k.includes(contractId)
      && k.includes(subElementId) && k.includes('perlenschnur'));
    if (!key) return false;
    return state.user.settings[key] || false;
  },
  [userGetters.USER_SETTINGS_RABATT]: (state) => {
    const userPreferenceRoute = '_kollektionen_rabatt';
    const key = Object.keys(state.user.settings).find((k) => k === userPreferenceRoute);
    if (!key) return false;
    return state.user.settings[key] || false;
  },
  [userGetters.USER_HAS_ROLE]: (state) => (role) => state.user.roles?.map(
    (innerRole) => innerRole.name,
  ).includes(role) || false,
  [userGetters.USER_HAS_MIN_ONE_OF_ROLES]: (state) => (roles) => roles?.some(
    (role) => {
      if (!state.user.roles) {
        return false;
      }
      return state.user.roles.map((innerRole) => innerRole.name).includes(role);
    },
  ),
  [userGetters.USER_IS_FROM_BBH]: (state, getters) => getters[
    userGetters.USER_HAS_MIN_ONE_OF_ROLES
  ]([
    UserRolle.RECHTSANWALT,
    UserRolle.PARTNER,
    UserRolle.ADMINPARTNER,
    UserRolle.PRODUKTKOORDINATOR,
    UserRolle.SACHBEARBEITER,
    UserRolle.MANDANTENFREIGABE,
    UserRolle.PRODUKTFREIGABE,
  ]),
  [userGetters.CLIENT_USERS]: (state) => state.clientUsers,
  [userGetters.CURRENT_CLIENT]: (state) => {
    if (!state.user.clients) return {};
    return state.user.clients?.[0] || {};
  },
};
