import { validate } from '../utils/uuid';
import Responsibilities from './Responsibilities';

export default class ElementAttrs {
  /**
   *
   * @param {String} name
   * @param {Array} hashtags Array of UUIDs
   * @param {Responsibilities} responsibilities
   * @param {Array} requiredRoles
   */
  constructor(
    name = '',
    hashtags = [],
    responsibilities = new Responsibilities(),
    requiredRoles = [],
  ) {
    this.name = name;
    this.hashtags = hashtags;
    this.responsibilities = responsibilities;
    this.requiredRoles = requiredRoles;
  }

  get validName() {
    return !!this.name && typeof this.name === 'string' && !!this.name.trim();
  }

  get validHashtags() {
    return !!this.hashtags
      && Array.isArray(this.hashtags)
      && this.hashtags.length > 0
      && this.hashtags.every((hashtagId) => validate(hashtagId));
  }

  get validResponsibilities() {
    return !!this.responsibilities
      && this.responsibilities instanceof Responsibilities
      && this.responsibilities.valid
      && this.requiredRoles.every((role) => this.responsibilities.hasRole(role));
  }
}
