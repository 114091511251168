import style from './default-style';

export default {
  parseDOM: [
    {
      tag: 'italic',
      style: 'font-style=italic',
    },
  ],
  toDOM() {
    const element = document.createElement('italic');
    Object.assign(element.style, style, { fontStyle: 'italic' });
    return element;
  },
};
