import style from './default-style';

const name = 'highlight';
export default {
  name,
  attrs: {
    color: { default: 'inherit' },
  },
  parseDOM: [
    {
      tag: name,
      getAttrs: (dom) => ({ color: dom.style.backgroundColor }),
    },
  ],
  toDOM: (node) => {
    const element = document.createElement(name);
    const { color } = node.attrs;

    Object.assign(element.style, style, { backgroundColor: color });

    return element;
  },
};
