import Vue from 'vue';
import types from '../types/mutation-types';

export default {
  [types.SET_USER](state, user) {
    const userClients = state.user.clients;
    state.user = user;
    if (!state.user.clients || state.user.clients.length < 1) {
      state.user.clients = userClients;
    }
    if (!state.user.settings?.tabs || state.user.settings?.tabs?.length < 1) {
      state.user.settings.tabs = [ ...state.user.settings?.tags || [] ] || [];
    }
  },
  [types.SET_USER_CLIENTS](state, clients) {
    state.user.clients = clients;
  },
  [types.SET_CLIENT_USERS](state, { clientUsers }) {
    state.clientUsers = clientUsers;
  },
  [types.REMOVE_USER_FROM_CLIENT_USERS](state, { keycloakUserUuid }) {
    state.clientUsers = state.clientUsers.filter(
      (user) => user.keycloakUserUuid !== keycloakUserUuid,
    );
  },
  [types.SET_CLIENT_TO_POSITION_ZERO](state, { clientId }) {
    const index = state.user.clients.findIndex((client) => client.clientId === clientId);
    if (index >= 0) {
      const client = state.user.clients[index];
      state.user.clients.splice(index, 1);
      state.user.clients.unshift(client);
    }
  },

  [types.SET_USER_PREFERENCE](state, { key, value }) {
    Vue.set(state.user.settings, key, value);
  },

  [types.ADD_USER_ROLE](state, { role }) {
    state.user.roles.push(role);
    state.user.roles.splice();
  },
  [types.REMOVE_USER_ROLE](state, {
    keycloakUserUuid,
    additionalRole,
    keycloakRoleUuid,
  }) {
    if (state.user.id !== keycloakUserUuid) return;

    if (keycloakRoleUuid) {
      state.user.roles = state.user.roles.filter(
        (role) => role.keycloakClientRoleUuid === undefined
        || role.keycloakClientRoleUuid !== keycloakRoleUuid,
      );
    } else if (additionalRole) {
      state.user.roles = state.user.roles.filter(
        (role) => role.keycloakClientRoleUuid !== undefined || role.name !== additionalRole,
      );
    }
  },

  [types.UPDATE_USER](state, { key, value }) {
    if (!key || !value) return;

    state.user[key] = value;
  },

  [types.SET_USER_TAGS](state, { tags }) {
    state.user.settings.tags = tags;
  },
  [types.ADD_OR_UPDATE_USER_TAG](state, { tag }) {
    const index = state.user.settings.tags.findIndex((tmpTag) => tmpTag.id === tag.id);
    if (index >= 0) {
      state.user.settings.tags.splice(index, 1, tag);
    } else {
      state.user.settings.tags.push(tag);
    }
  },
  [types.DELETE_USER_TAG](state, { tagId }) {
    const index = state.user.settings.tags.findIndex((tmpTag) => tmpTag.id === tagId);
    if (index >= 0) {
      state.user.settings.tags.splice(index, 1);
    }
  },
  [types.UPDATE_CLIENT_USER](state, { clientUser }) {
    const indexOf = state.clientUsers.findIndex(
      (user) => user.id === clientUser.id,
    );

    state.clientUsers.splice(indexOf, 1, clientUser);

    if (state.user.id === clientUser.id) {
      state.user = clientUser;
    }
  },
  [types.UPDATE_CLIENT](state, { clientObj }) {
    const indexOf = state.user.clients.findIndex(
      (tmpClient) => tmpClient.clientId === clientObj.clientId,
    );

    state.user.clients.splice(indexOf, 1, clientObj);
  },
};
