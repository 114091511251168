export default {
  currentDocId: '',
  currentMasterContract: {},

  compareElement: {},

  element: {},
  allElements: [],
  phraseReference: {},
  phrase: null,
  revision: 0,

  clauseObj: {},
  selectedClause: {
    iterate: true,
  },
  choiceTrigger: 0,

  elementRevisions: [],

  triggerSavePatch: false,
};

export const component = 'editor/';
