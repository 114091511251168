function aktuelleRoute(route) {
  if (typeof route?.meta.breadCrumb === 'function') {
    const routeArray = route.meta.breadCrumb.call(this, route);
    if (!routeArray || !Array.isArray(routeArray)) return '';
    return routeArray[routeArray.length - 1].text;
  }
  const routeArray = route?.meta.breadCrumb;
  if (!routeArray || !Array.isArray(routeArray)) return '';
  return routeArray[routeArray.length - 1].text;
}

function routeIsHome(route) {
  return aktuelleRoute(route) === 'Home';
}
function routeIsEditor(route) {
  return aktuelleRoute(route) === 'KlauselEditor'
    || aktuelleRoute(route) === 'Interner Editor'
    || aktuelleRoute(route) === 'Editor';
}
function routeIsKollektionen(route) {
  return aktuelleRoute(route) === 'Vertragswelt';
}
function routeIsMeineDokumente(route) {
  return aktuelleRoute(route) === 'Meine Dokumente';
}
function routeIsFragebogen(route) {
  return aktuelleRoute(route) === 'Fragebogen';
}
function routeIsVerwaltungsansichten(route) {
  return aktuelleRoute(route) === 'Verwaltungsansichten';
}
function routeIsHashtags(route) {
  return aktuelleRoute(route) === 'Hashtags';
}
function routeIsEntscheidungen(route) {
  return aktuelleRoute(route) === 'Entscheidungen';
}
function routeIsBenutzereinstellungen(route) {
  return aktuelleRoute(route) === 'Benutzereinstellungen';
}
function routeIsKollektionenBearbeiten(route) {
  return aktuelleRoute(route) === 'Kollektionen bearbeiten';
}
function routeIsVertragsDetails(route) {
  return aktuelleRoute(route) === 'Vertrags-Details';
}
function routeIsVertragBearbeiten(route) {
  return aktuelleRoute(route) === 'Vertrag bearbeiten';
}
function routeIsMustervertraege(route) {
  return aktuelleRoute(route) === 'Musterverträge';
}
function routeIsMusterklauseln(route) {
  return aktuelleRoute(route) === 'Musterklauseln';
}
function routeIsMusterdokumente(route) {
  return aktuelleRoute(route) === 'Musterdokumente';
}
function routeIsTaskansicht(route) {
  return aktuelleRoute(route) === 'Taskansicht';
}
function routeIsFragebogenBearbeiten(route) {
  return aktuelleRoute(route) === 'Fragebogen bearbeiten';
}
function routeIsNeuerMandant(route) {
  return aktuelleRoute(route) === 'Neuer Mandant';
}
function routeIsMandantEditor(route) {
  return aktuelleRoute(route) === 'Editor';
}
function routeIsPaketverwaltung(route) {
  return aktuelleRoute(route) === 'Paketverwaltung';
}
function routeIsRechnungen(route) {
  return aktuelleRoute(route) === 'Rechnungen';
}

function routeIsMandantenUsersList(route) {
  return aktuelleRoute(route) === 'Liste aller Nutzer aller Mandanten';
}

function routeIsSecretView(route) {
  return aktuelleRoute(route) === 'SecretView';
}

function routeIsMeineRechnungen(route) {
  return aktuelleRoute(route) === 'Meine Rechnungen';
}

function routeIsMeineAbos(route) {
  return aktuelleRoute(route) === 'Meine Abos';
}

function routeIsServicezugang(route) {
  return aktuelleRoute(route) === 'Servicezugänge';
}

export {
  aktuelleRoute,
  routeIsHome,
  routeIsEditor,
  routeIsKollektionen,
  routeIsMeineDokumente,
  routeIsFragebogen,
  routeIsVerwaltungsansichten,
  routeIsHashtags,
  routeIsEntscheidungen,
  routeIsBenutzereinstellungen,
  routeIsKollektionenBearbeiten,
  routeIsVertragsDetails,
  routeIsVertragBearbeiten,
  routeIsMustervertraege,
  routeIsMusterklauseln,
  routeIsMusterdokumente,
  routeIsTaskansicht,
  routeIsFragebogenBearbeiten,
  routeIsNeuerMandant,
  routeIsMandantEditor,
  routeIsPaketverwaltung,
  routeIsRechnungen,
  routeIsMandantenUsersList,
  routeIsSecretView,
  routeIsMeineAbos,
  routeIsMeineRechnungen,
  routeIsServicezugang,
};
