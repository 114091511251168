export default class FreigabeStatus {
  static MODIFIABLE = 'modifiable';

  static NOT_APPROVED = 'nicht_freigegeben';

  static APPROVAL_REQUESTED = 'freigabe_angefordert';

  static APPROVED = 'freigeben';

  static isValidType(type) {
    const states = [
      this.MODIFIABLE,
      this.NOT_APPROVED,
      this.APPROVAL_REQUESTED,
      this.APPROVED,
    ];
    return states.includes(type);
  }

  static isModifiable(type) {
    return type === this.MODIFIABLE;
  }

  static isNotApproved(type) {
    return type === this.NOT_APPROVED;
  }

  static isApprovalRequested(type) {
    return type === this.APPROVAL_REQUESTED;
  }

  static isApproved(type) {
    return type === this.APPROVED;
  }
}
