import EditorElement from '@src/model/EditorElement';
import KlauselElement from '@src/model/KlauselElement';
import {
  moveItem, deepClone, isEqual, isEmptyOrNullish,
} from '@st/utils-js';
import jsonpath from 'jsonpath';
import moment from 'moment';
import Vue from 'vue';
import ElementReference from '../../../../model/ElementReference';
import Frage from '../../../../model/Frage';
import InternalReference from '../../../../model/InternalReference';
import MustervertragElement from '../../../../model/MustervertragElement';
import TextContentElement from '../../../../model/TextContentElement';
import ElementStatus from '../../../../model/enums/ElementStatus';
import ElementTyp from '../../../../model/enums/ElementTyp';
import PatchTag from '../../../../model/enums/PatchTag';
import ElementFactory from '../../../../utils/ElementFactory';
import { uuid } from '../../../../utils/uuid';
import editorMutations from '../types/mutation-types';

export default {
  // SET
  [editorMutations.SET_PHRASE_REFERENCE](
    state,
    { reference, compiledContent = state.element.compiledContent },
  ) {
    const relatedSectionInCompiledContent = compiledContent
      .find((section) => section.refPathId === state.phrase.section.refPathId);

    const jsonPathParam = '$..content[?(@.type==\'reference\' && @.attrs.id==\''
      + `${reference.reference.id}')].attrs`;

    const refsInCompiledContent = jsonpath.query(
      relatedSectionInCompiledContent,
      jsonPathParam,
    );

    if (refsInCompiledContent.length < 1) return;

    const refInCompiledContent = refsInCompiledContent[0];
    state.phraseReference = {
      reference: refInCompiledContent,
      section: reference.section,
    };
  },
  [editorMutations.SET_PHRASE](state, phrase) {
    state.phrase = phrase;
  },
  [editorMutations.UPDATE_PHRASE_REFERENCE](state, reference) {
    state.phraseReference = reference;
    if (!isEmptyOrNullish(state.phrase)) {
      const { phrase } = state.phrase;
      const newRef = reference.reference;
      const contentIndex = phrase.content.findIndex(
        (content) => content.attrs?.id === newRef.id,
      );
      if (contentIndex < 0) return;
      phrase.content[contentIndex].attrs = newRef;
    }
  },

  [editorMutations.SET_CLAUSE_OBJ](state, clauseObj) {
    state.clauseObj = clauseObj;
  },
  [editorMutations.SET_SELECTED_CLAUSE](state, clause) {
    state.selectedClause = clause;
  },

  [editorMutations.SET_ELEMENT](state, { element }) {
    state.element = element;
  },
  [editorMutations.SET_ELEMENT_CURRENT_DOC_CONTENT](state, {
    currentIndex,
    direction,
    musterdokumentId,
  }) {
    const { content } = state.element.elements[musterdokumentId];

    const movedContent = moveItem(content, currentIndex, currentIndex + direction);

    state.element.elements[musterdokumentId].content = movedContent;

    state.element.compiledContent = EditorElement.buildCompiledContent(
      musterdokumentId,
      state.element.elements,
      state.element.elementRevisions[musterdokumentId],
      state.element.similarities,
    );
  },
  [editorMutations.SET_CURRENT_DOC_ID](state, { currentDocId }) {
    state.currentDocId = currentDocId;
  },
  [editorMutations.SET_CURRENT_MASTER_CONTRACT_IN_EDITOR_ELEMENT](state, { masterContract }) {
    const mappedMasterContract = masterContract instanceof MustervertragElement
      ? masterContract : MustervertragElement.fromJSON(masterContract);
    const mappedMasterContractJson = mappedMasterContract.toJSON();

    const elementId = state.element.editorElement;
    const { content: newContent } = mappedMasterContractJson.attrs.questionnaire;

    const editorElement = state.element.elements[elementId];
    editorElement.attrs.questionnaire.content = newContent;
  },

  [editorMutations.SET_EDITOR_ELEMENT_STATE_TO_APPROVED](state, { editorElementId }) {
    state.element.elementRevisions[editorElementId] += 1;
    state.element.elements[editorElementId].state = ElementStatus.APPROVED;
  },

  [editorMutations.SET_ELEMENT_REVISIONS](state, { revisions }) {
    state.elementRevisions = revisions;
  },

  // UPDATE

  [editorMutations.UPDATE_CURRENT_DOC_ORDER](state, { newContent, musterdokumentFunc, type }) {
    const musterdokument = musterdokumentFunc(type);
    musterdokument.content = newContent;
  },

  [editorMutations.UPDATE_ELEMENT_REVISION](state, { elementId }) {
    if (!state.element.elementRevisions[elementId]) return;
    state.element.elementRevisions[elementId] += 1;
  },

  [editorMutations.UPDATE_ELEMENT_REVISIONS_ARRAY](state, { revisionNr }) {
    const newPatchEntry = {
      revisionNr,
      name: moment().format('DD.MM.YYYY, HH:mm:ss[Uhr]'),
    };
    state.elementRevisions.unshift(newPatchEntry);
  },

  [editorMutations.UPDATE_ELEMENT_CONTENT](state, { sectionId, elementId, content }) {
    const sectionIndex = state.element.elements[elementId].content
      .findIndex((s) => s.id === sectionId);
    const section = state.element.elements[elementId].content[sectionIndex];
    if (!isEqual(section.content, content)) {
      state.element.elements[elementId].content[sectionIndex].content = content;
      if (
        !(state.element.elements[elementId].content[sectionIndex] instanceof TextContentElement)
      ) {
        state.element.elements[elementId].content[sectionIndex]
          .textContent = TextContentElement.getTextContent(content);
      }
    }
  },

  [editorMutations.UPDATE_ELEMENT_SIMILARITIES](state, similarities) {
    const similarityKeys = similarities.map((similarity) => similarity.sectionId);
    const removedDuplicates = [ ...new Set(similarityKeys) ];

    removedDuplicates.forEach((sectionId) => {
      const sectionSimilarities = similarities.filter(
        (similarity) => similarity.sectionId === sectionId,
      );
      state.element.similarities[sectionId] = sectionSimilarities;
    });

    state.element.compiledContent = state.element.compiledContent
      .map((section) => {
        const sectionClone = deepClone(section);
        if (removedDuplicates.includes(section.refElement.id)) {
          sectionClone.similarities = state.element.similarities[section.refElement.id];
        }
        return sectionClone;
      });
  },

  [editorMutations.UPDATE_ELEMENT_ATTRS](state, { elementId, attrs }) {
    Object.assign(state.element.elements[elementId].attrs, attrs);
  },

  [editorMutations.UPDATE_ELEMENT_SECTION_ATTRS](state, { elementId, section, attrs }) {
    let tmpElementId = elementId;
    if (section.ancestors?.length > 0) {
      const ancestorRefPathId = section.ancestors.at(-1).refPathId;
      const ancestorElementId = section.ancestors.at(-2)?.id || state.currentDocId;
      const tmpContent = state.element.elements[ancestorElementId].content
        .find((s) => s.refPathId === ancestorRefPathId);
      if (!tmpContent) return;
      tmpElementId = tmpContent.id;
    }
    state.element.elements[tmpElementId].content
      .find((s) => s.id === section.refElement.id).attrs = attrs;
  },

  [editorMutations.UPDATE_DOC_NAME](state, { dokumentId, currentDocName }) {
    state.element.elements[dokumentId].attrs.name = currentDocName;
  },

  [editorMutations.ADD_INTERNAL_REFERENCE_TO_CURRENT_DOC](state) {
    const element = state.element.elements[state.currentDocId];

    if (!element.internalReference) {
      element.internalReference = {};
    }
  },

  [editorMutations.ADD_ELEMENT_REFERENCE_TO_ELEMENT](state) {
    const editorElementId = state.element.editorElement;
    const element = state.element.elements[editorElementId];

    if (!ElementTyp.isMustervertrag(element.type)) return;

    if (!element.attrs.elementReference) {
      element.attrs.elementReference = {};
    }
  },

  [editorMutations.UPDATE_INTERNAL_REFERENCE](state, { refToSave, srcRefPath }) {
    const element = state.element.elements[state.currentDocId];
    const refId = refToSave.id;

    if (refToSave.dstId?.length > 0) {
      if (!element.internalReference) {
        element.internalReference = {};
      }

      const { dstRefPath = [], dstId } = refToSave;

      const newVal = {
        refId,
        dstId,
        dstRefPath,
        srcRefPath,
      };

      const mappedReference = InternalReference.fromJSON(newVal);

      if (Array.isArray(element.internalReference[refId])) {
        const tmpRefObj = element.internalReference[refId]
          ?.find((ref) => ref.srcRefPath.join('-') === srcRefPath.join('-'));
        if (!isEmptyOrNullish(tmpRefObj)) {
          if (tmpRefObj.dstId !== dstId) Vue.set(tmpRefObj, 'dstId', dstId);
          if (tmpRefObj.dstRefPath.join('-') !== dstRefPath.join('-')) {
            Vue.set(tmpRefObj, 'dstRefPath', dstRefPath);
          }
        } else {
          element.internalReference[refId].push(mappedReference);
        }
      } else {
        Vue.set(element.internalReference, refId, [ mappedReference ]);
      }
    } else {
      const length = element.internalReference?.[refId]?.length;
      if (length > 0) {
        const tmpRefObj = element.internalReference[refId]
          ?.find((ref) => ref.srcRefPath.join('-') === srcRefPath.join('-'));

        if (!isEmptyOrNullish(tmpRefObj)) {
          if (length > 1) {
            const index = element.internalReference[refId].indexOf(tmpRefObj);

            if (index > -1) {
              element.internalReference[refId].splice(index, 1);
            }
          } else {
            Vue.delete(element.internalReference, refId);
          }
        }
      }
    }
  },

  [editorMutations.UPDATE_ELEMENT_REFERENCE](state, { refToSave, srcRefPath }) {
    const elementId = state.element.editorElement;
    const element = state.element.elements[elementId];
    const refId = refToSave.id;

    if (
      refToSave.dstId?.length > 0
      || refToSave.dstElementRefId?.length > 0
    ) {
      if (!element.attrs.elementReference) {
        element.attrs.elementReference = {};
      }

      const {
        dstId,
        dstElementRefId,
        srcElementRefId,
        dstRefPath,
      } = refToSave;

      const newVal = {
        dstId,
        dstElementRefId,
        srcElementRefId,
        dstRefPath,
        srcRefPath,
        id: refId,
      };

      const mappedReference = ElementReference.fromJSON(newVal);

      if (Array.isArray(element.attrs.elementReference[refId])) {
        const tmpRefObj = element.attrs.elementReference[refId]
          ?.find((ref) => ref.srcRefPath.join('-') === srcRefPath.join('-'));
        if (!isEmptyOrNullish(tmpRefObj)) {
          if (tmpRefObj.dstId !== dstId) tmpRefObj.dstId = dstId;
          if (tmpRefObj.dstElementRefId !== dstElementRefId) {
            Vue.set(tmpRefObj, 'dstElementRefId', dstElementRefId);
          }
          if (tmpRefObj.srcElementRefId !== srcElementRefId) {
            Vue.set(tmpRefObj, 'srcElementRefId', srcElementRefId);
          }
          if (tmpRefObj.dstRefPath.join('-') !== dstRefPath.join('-')) Vue.set(tmpRefObj, 'dstRefPath', dstRefPath);
          if (tmpRefObj.srcRefPath.join('-') !== srcRefPath.join('-')) Vue.set(tmpRefObj, 'srcRefPath', srcRefPath);
        } else {
          element.attrs.elementReference[refId].push(mappedReference);
        }
      } else {
        Vue.set(element.attrs.elementReference, refId, [ mappedReference ]);
      }
    } else {
      const length = element.attrs.elementReference?.[refId]?.length;
      if (length > 0) {
        const tmpRefObj = element.attrs.elementReference[refId]
          ?.find((ref) => ref.srcRefPath.join('-') === srcRefPath.join('-'));

        if (!isEmptyOrNullish(tmpRefObj)) {
          if (length > 1) {
            const index = element.attrs.elementReference[refId].indexOf(tmpRefObj);

            if (index > -1) {
              element.attrs.elementReference[refId].splice(index, 1);
            }
          } else {
            Vue.delete(element.attrs.elementReference, refId);
          }
        }
      }
    }
  },

  [editorMutations.UPDATE_CONTENT_ABSCHNITT_IN_KLAUSEL_UMWANDELN](state, {
    type,
    elementId,
    indexOfElement,
    subElementOrigin,
    contentLength,
    tmpClauseOrigin,
  }) {
    state.triggerSavePatch = true;

    state.element.elements[tmpClauseOrigin.id] = tmpClauseOrigin;

    if (ElementTyp.isUeberschrift(type)) {
      state.element.elements[elementId].content
        .splice(indexOfElement + 1, contentLength, subElementOrigin);
    } else {
      state.element.elements[elementId].content
        .splice(indexOfElement, 1, subElementOrigin);
    }

    state.element.compiledContent = EditorElement.buildCompiledContent(
      state.currentDocId,
      state.element.elements,
      state.element.elementRevisions[state.currentDocId],
      state.element.similarities,
    );
  },

  [editorMutations.UPDATE_CHOICE_IN_SECTION](state, {
    focusedSectionRefPathId,
    focusedSectionIdentificationType,
    preparedValue,
  }) {
    const { currentDocId } = state;
    const tmpCurrentElement = state.element.elements[currentDocId];

    const tmpSection = tmpCurrentElement.content.find(
      (content) => content[focusedSectionIdentificationType] === focusedSectionRefPathId,
    );

    state.triggerSavePatch = true;

    Vue.set(tmpSection, 'choice', preparedValue.choice);
    tmpSection.preFormulatedQuestion = preparedValue.preFormulatedQuestion;
    tmpSection.externalExplanations = preparedValue.externalExplanations;
    tmpSection.internalExplanations = preparedValue.internalExplanations;

    if (ElementTyp.isAbschnitt(tmpSection.type)) {
      const { clauseTransformation: sectionClauseTransformation } = tmpSection.attrs;
      sectionClauseTransformation.choice = preparedValue.choice;
      sectionClauseTransformation.preFormulatedQuestion = preparedValue.preFormulatedQuestion;
      sectionClauseTransformation.externalExplanations = preparedValue.externalExplanations;
      sectionClauseTransformation.internalExplanations = preparedValue.internalExplanations;
    }
  },

  [editorMutations.UPDATE_QUESTIONNAIRE_CONTENT_ORDER](state, {
    elementId,
    from,
    to,
  }) {
    const { content } = state.element.elements[elementId].attrs.questionnaire;

    const movedContent = moveItem(content, from, to);

    state.element.elements[elementId].attrs.questionnaire.content = movedContent;
  },

  [editorMutations.UPDATE_ELEMENTS_CONTENT_ORDER](state, {
    elementId,
    from,
    direction,
    currentIndexCompiledContent,
  }) {
    const { content } = state.element.elements[elementId];
    const { compiledContent } = state.element;

    const movedContent = moveItem(content, from, from + direction);
    state.element.elements[elementId].content = movedContent;
    const movedCompiledContent = moveItem(
      compiledContent,
      currentIndexCompiledContent,
      currentIndexCompiledContent + direction,
    );
    state.element.compiledContent = movedCompiledContent;
  },

  [editorMutations.UPDATE_QUESTIONNAIRE_CONTENT_QUESTION](state, {
    elementId,
    question,
    index,
  }) {
    const questionChanged = !isEqual(
      state.element.elements[elementId].attrs.questionnaire.content[index],
      question,
    );
    if (!questionChanged) return;

    state.element.elements[elementId].attrs.questionnaire.content.splice(
      index,
      1,
      question,
    );
  },
  [editorMutations.UPDATE_QUESTIONNAIRE_CONTENT](state, {
    elementId,
    newQuestionnaireContent,
  }) {
    state.element.elements[elementId].attrs.questionnaire.content = newQuestionnaireContent;
  },

  [editorMutations.UPDATE_QUESTIONNAIRE_CONTENT_ADD_QUESTION](state, {
    index,
    elementId,
    question,
  }) {
    const mappedNewQuestion = question instanceof Frage
      ? question
      : Frage.fromJSON(question);

    state.element.elements[elementId].attrs.questionnaire
      .content.splice(index, 0, mappedNewQuestion.toJSON());
  },
  [editorMutations.UPDATE_QUESTIONNAIRE_CONTENT_REMOVE_QUESTION](state, {
    index,
    elementId,
  }) {
    state.element.elements[elementId].attrs.questionnaire
      .content.splice(index, 1);
  },

  [editorMutations.UPDATE_COMPARE_ELEMENT](state, value) {
    if (Object.keys(value).length === 0) {
      state.compareElement = {};
      return;
    }

    const {
      id,
      elements,
      revision,
    } = value;
    const preparedElement = ElementFactory.elementFromJSON(id, elements, revision);

    preparedElement.patchTag = value.patchTag;

    state.compareElement = preparedElement;
  },

  // ADD
  /**
   *
   * @param {*} state
   * @param {KlauselElement} element as Object
   */
  [editorMutations.ADD_ELEMENT_TO_ELEMENT](
    state,
    { element, revision = 0, patchTag = PatchTag.AUTO_SAVE },
  ) {
    state.element.elementRevisions[element.id] = revision;
    state.element.elementPatchTags[element.id] = patchTag;
    state.element.elements[element.id] = element;
  },

  [editorMutations.ADD_SECTION_TO_CURRENT_DOC](state, {
    node,
    direction,
    currentSection,
  }) {
    const localCurrentDocId = state.currentDocId;
    const currentDocInElements = state.element.elements[localCurrentDocId];

    const lastAncestor = currentSection.ancestors?.at(0) || {};

    const indexOfCurrentSection = ElementTyp.isKlausel(lastAncestor.type)
      ? currentDocInElements.content
        .findIndex((content) => content.refPathId === lastAncestor.refPathId)
      : currentDocInElements.content
        .findIndex((content) => content.id === currentSection.refElement.id);

    let tmpNode = node;
    if (tmpNode.toJSON) tmpNode = tmpNode.toJSON();

    delete tmpNode.ancestors;
    delete tmpNode.refPathId;

    currentDocInElements.content.splice(indexOfCurrentSection + 1 + direction, 0, tmpNode);

    state.element.compiledContent = EditorElement.buildCompiledContent(
      state.currentDocId,
      state.element.elements,
      state.element.elementRevisions,
      state.element.similarities,
    );
  },

  [editorMutations.ADD_CLAUSE_TO_CURRENT_DOC](state, {
    node,
    element: clauseElement,
    currentSection,
    replaceScope = 1,
  }) {
    Object.keys(clauseElement.elements).forEach((elementId) => {
      if (!state.element.elements[elementId]) {
        const mappedClause = KlauselElement
          .fromJSON(clauseElement.elements[elementId], clauseElement.elements);
        const revision = clauseElement.elementRevisions[elementId];
        const patchTag = clauseElement.elementPatchTags[elementId];
        state.element.addElement(elementId, mappedClause, revision, patchTag);
      }
    });

    const localCurrentDocId = state.currentDocId;
    const currentDocInElements = state.element.elements[localCurrentDocId];
    const ancestor = currentSection.ancestors?.at(0) || {};

    const subelement = {
      preFormulatedQuestion: '',
      internalExplanations: '',
      externalExplanations: '',
      refPathId: ancestor.refPathId || uuid(),
      choice: ancestor.choice,
      type: ElementTyp.SUBELEMENT,
      id: node.id,
    };

    const indexOfCurrentSection = ElementTyp.isKlausel(ancestor.type)
      ? currentDocInElements.content
        .findIndex((content) => content.refPathId === ancestor.refPathId)
      : currentDocInElements.content
        .findIndex((content) => content.id === currentSection.refElement.id);

    state.triggerSavePatch = true;

    currentDocInElements.content.splice(indexOfCurrentSection, 1, subelement);

    const newContent = getRecursiveKlauselElements(
      state.element.elements[node.id],
      node.id,
      state.element,
      subelement.refPathId,
    );

    const indexInCompiledContent = getIndexInCompiledContent(
      currentSection,
      state.element.compiledContent,
    );

    if (indexInCompiledContent < 0) {
      console.error('section not found in compiled content');
      return;
    }

    state.element.compiledContent.splice(indexInCompiledContent, replaceScope, ...newContent);
  },

  // REMOVE

  [editorMutations.REMOVE_SECTION_FROM_CURRENT_DOC](state, {
    section,
  }) {
    const localCurrentDocId = state.currentDocId;
    const currentDocInElements = state.element.elements[localCurrentDocId];

    const lastAncestor = section.ancestors?.at(0) || {};

    const indexOfCurrentSection = ElementTyp.isKlausel(lastAncestor.type)
      ? currentDocInElements.content
        .findIndex((content) => content.refPathId === lastAncestor.refPathId)
      : currentDocInElements.content
        .findIndex((content) => content.id === section.refElement.id);

    currentDocInElements.content.splice(indexOfCurrentSection, 1);

    if (!isEmptyOrNullish(lastAncestor)) {
      const allContentIdsInElements = Object.values(state.element.elements)
        .map((e) => e.content)
        .flat()
        .map((c) => c.id);

      const sectionIdStillExists = allContentIdsInElements.includes(lastAncestor.id);

      if (!sectionIdStillExists) {
        delete state.element.elements[lastAncestor.id];
      }
    }

    state.element.compiledContent = EditorElement.buildCompiledContent(
      state.currentDocId,
      state.element.elements,
      state.element.elementRevisions[state.currentDocId],
      state.element.similarities,
    );
  },

  [editorMutations.REMOVE_UPDATE_FROM_UPDATE_OBJECT](state, {
    elementId,
  }) {
    delete state.element.elementUpdates[elementId];
  },

  // DEPRECATE

  // DELETE
  // PUT
  [editorMutations.INCREASE_CHOICE_TRIGGER](state) {
    state.choiceTrigger += 1;
  },

  [editorMutations.SET_TRIGGER_SAVE_PATCH](state, value) {
    state.triggerSavePatch = value;
  },

  // CLEAR
  [editorMutations.CLEAR_PHRASE_REFERENCE](state) {
    state.phraseReference = {};
  },
  [editorMutations.CLEAR_PHRASE](state) {
    state.phrase = {};
  },
};

// Helper functions

function getRecursiveKlauselElements(
  klausel,
  klauselId,
  element,
  refPathId = undefined,
  ancestors = [],
  result = [],
) {
  const localAncestors = ancestors.concat([
    {
      id: klauselId,
      refPathId,
      type: ElementTyp.KLAUSEL,
    },
  ]);

  let localResult = [ ...result ];

  klausel.content.forEach((s) => {
    if (ElementTyp.isSubelement(s.type)) {
      localResult = getRecursiveKlauselElements(
        element.elements[s.id],
        s.id,
        element,
        s.refPathId,
        localAncestors,
        localResult,
      );
    } else if (ElementTyp.isLeafType(s.type)) {
      localResult.push({
        refElement: s,
        refPathId: uuid(),
        elementRevision: element.elementRevisions[klauselId],
        ancestors: localAncestors,
        similarities: {},
      });
    }
  });
  return localResult;
}

function getIndexInCompiledContent(currentSection, compiledContent) {
  let relevantRefPathId;
  let refPathInAncestor = false;
  if (currentSection.ancestors?.length > 0) {
    relevantRefPathId = currentSection.ancestors?.at(0)?.refPathId;
    refPathInAncestor = true;
  } else {
    relevantRefPathId = currentSection.refPathId;
  }

  if (!relevantRefPathId) {
    return -1;
  }

  const refPathIdArray = compiledContent
    .map((content) => (refPathInAncestor ? content.ancestors?.[0]?.refPathId : content.refPathId));

  return refPathIdArray
    .findIndex((refPathId) => refPathId === relevantRefPathId);
}
