import { component as MODULE } from '../store/state';

const ACTIONS = {
  FETCH_CLIENT_ELEMENT: `${MODULE}fetch_client_element`,
  FETCH_CLIENT_ELEMENTS: `${MODULE}fetch_client_elements`,

  DEPRECATE_CLIENT_ELEMENT: `${MODULE}deprecate_client_element`,

  ADD_CLIENT_ELEMENT_NEW_ELEMENT: `${MODULE}add_client_element_new_element`,
  REMOVE_CLIENT_ELEMENT_SUBELEMENT: `${MODULE}remove_client_element_subelement`,

  PUT_CLIENT_ELEMENT_METADATA: `${MODULE}put_client_element_metadata`,
  PUT_CLIENT_ELEMENT_RELEASE_FLAG: `${MODULE}put_client_element_release_flag`,
  PUT_CLIENT_ELEMENT_TAGS: `${MODULE}put_client_element_tags`,

  GET_CLIENT_ELEMENT_NAME: `${MODULE}get_client_element_name`,
  GET_CLIENT_ELEMENT_UPDATES: `${MODULE}get_client_element_updates`,
  REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY: `${MODULE}remove_client_element_zwangsupdate_key`,

  GET_CLIENT_ELEMENT_ZWANGSUPDATE: `${MODULE}get_client_element_zwangsupdate`,
  SET_UPDATE_COMPLETED: `${MODULE}set_update_completed`,
};

export default ACTIONS;
