import { isJSON } from '@st/utils-js';
import Reference from './Reference';

export default class InternalReference extends Reference {
  /**
   *
   * @param {String} id UUID
   * @param {String} dstId UUID
   * @param {Array} dstRefPath Array of UUIDs
   * @param {Array} srcRefPath Array of UUIDs
   */
  constructor(
    id = null,
    dstId = null,
    dstRefPath = [],
    srcRefPath = [],
  ) {
    super(id, dstId, dstRefPath, srcRefPath);
  }

  /**
   * Creates InternalReference from JSON. If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      id = null,
      refId = null,
      dstId = null,
      dstRefPath = [],
      srcRefPath = [],
    } = value;

    return new InternalReference(id || refId, dstId, dstRefPath, srcRefPath);
  }

  /**
   * @returns InternalReference in JSON representation
   */
  toJSON() {
    return {
      id: this.id,
      dstId: this.dstId,
      dstRefPath: this.dstRefPath,
      srcRefPath: this.srcRefPath,
    };
  }

  get valid() {
    const {
      validRefId, validDstId, validDstRefPath, validSrcRefPath,
    } = this;

    const valid = validRefId
      && validDstId
      && validDstRefPath
      && validSrcRefPath;

    return valid;
  }
}
