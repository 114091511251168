import element from './element';
import hashtags from './hashtags';
import choices from './choices';
import contract from './contract';
import questionnaire from './questionnaire';
import search from './search';
import client from './client';
import internUpdate from './internUpdate';
import clientVertrag from './clientVertrag';
import relations from './relation';
import similarities from './similarities';
import mandantUpdate from './mandantUpdate';
import docx from './docx';
import revisions from './revisions';

export default {
  element,
  hashtags,
  choices,
  contract,
  questionnaire,
  search,
  client,
  internUpdate,
  clientVertrag,
  relations,
  similarities,
  mandantUpdate,
  docx,
  revisions,
};
