import user from '../modules/user/types/getter-types';
import viewContent from '../modules/viewContent/types/getter-types';
import subHeader from '../modules/subHeader/types/getter-types';
import editor from '../modules/editor/types/getter-types';
import mandantEditor from '../modules/mandantEditor/types/getter-types';
import sharedEditor from '../modules/sharedEditor/types/getter-types';
import aufgaben from '../modules/aufgaben/types/getter-types';
import mandanten from '../modules/mandanten/types/getter-types';
import shopAdmin from '../modules/shopAdmin/types/getter-types';
import shopClient from '../modules/shopClient/types/getter-types';
import shopInfo from '../modules/shopInfo/types/getter-types';

const TYPES = {
  user,
  viewContent,
  subHeader,
  editor,
  mandantEditor,
  sharedEditor,
  aufgaben,
  mandanten,
  shopAdmin,
  shopClient,
  shopInfo,
};

export default TYPES;
