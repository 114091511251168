import {
  get,
} from '../axios';

const API_PREFIX = '/shopInfo';

export default {
  /**
   * Receive packages that include a contract
   *
   * @param {string} vertragsId
   */
  fetchPaketeByVertrag(vertragsId) {
    const URL = `${API_PREFIX}/paketeByVertrag/${vertragsId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
