import getters from '../types/getter-types';

export default {
  [getters.ALL_HASHTAGS]: (state) => state.hashtags,
  [getters.FILTERED_HASHTAGS]: (state) => (filter) => state.hashtags.filter(
    (ht) => ht[filter.key] === filter.value,
  ),
  [getters.ALL_HASHTAG_TYPES]: (state) => state.hashtagTypes,
  [getters.ALL_CHOICES]: (state) => state.choices,
  [getters.ALL_MUSTERVERTRAEGE]: (state) => state.mustervertraege,

  [getters.HILFE_VIDEO]: (state) => state.hilfe,
};
