export default class EntscheidungStatus {
  static STANDARD = 'standard';

  static DEPRECATED = 'deprecated';

  static isValidState(state) {
    const states = [
      this.STANDARD, this.DEPRECATED,
    ];
    return states.includes(state);
  }

  static isStandard(state) {
    return state === this.STANDARD;
  }

  static isDeprecated(state) {
    return state === this.DEPRECATED;
  }
}
