/* eslint-disable default-param-last */
import { isJSON } from '@st/utils-js';
import HashtagStatus from './enums/HashtagStatus';
import HashtagTyp from './HashtagTyp';
import PersistObj from './PersistObj';

export default class Hashtag extends PersistObj {
  /**
   * @param {String} id if null it will be generated
   * @param {String} name
   * @param {HashtagTyp} type
   * @param {String} state value of HashtagStatus
   */
  constructor(
    id = null,
    name = '',
    type,
    state = HashtagStatus.NEW,
    alternativeSpelling = null,
  ) {
    super(id);
    this.name = name;
    this.type = type;
    this.state = state;
    this.alternativeSpelling = alternativeSpelling;
  }

  /**
   * Creates Hashtag from JSON. If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      id = null,
      hashtagId = null,
      name = '',
      type,
      state = HashtagStatus.NEW,
      alternativeSpelling = null,
    } = value;

    let localType = type;
    if (!(type instanceof HashtagTyp)) {
      localType = HashtagTyp.fromJSON(type);
    }

    const finalId = id || hashtagId;

    return new Hashtag(finalId, name, localType, state, alternativeSpelling);
  }

  /**
   * @returns Hashtag in JSON representation
   */
  toJSON() {
    return {
      id: this.id,
      name: this.name,
      type: this.type.toJSON(),
      state: this.state,
      alternativeSpelling: this.alternativeSpelling,
    };
  }

  get valid() {
    const { validId } = this;
    const validName = !!this.name && typeof this.name === 'string' && !!this.name.trim();
    const validState = HashtagStatus.isValidState(this.state);
    const validType = !!this.type && this.type instanceof HashtagTyp && this.type.valid;
    const validAlternativeSpelling = (this.alternativeSpelling === null
      || typeof this.alternativeSpelling === 'string');

    const valid = validName
    && validId
    && validState
    && validType
    && validAlternativeSpelling;

    return valid;
  }
}
