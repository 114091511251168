import { get, put } from '../axios';

export default {
  /**
   *
   * @param {String} elementId
   */
  fetch(elementId) {
    const url = `/contractgenerator/questionnaire/${elementId}`;
    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {JSON} questionnaire
   */
  save(questionnaire) {
    const url = '/contractgenerator/questionnaire';
    return new Promise((resolve, reject) => {
      put(url, questionnaire)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },
};
