import style from './default-style';

export default {
  parseDOM: [
    { tag: 'lineThrough' },
    { style: 'text-decoration=line-through' },
  ],
  toDOM() {
    const element = document.createElement('linethrough');
    Object.assign(element.style, style, { textDecoration: 'line-through' });
    return element;
  },
};
