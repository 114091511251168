import { isJSON } from '@st/utils-js';
import { uuid, validate } from '../utils/uuid';
import KommentarType from './enums/KommentarType';

export default class TextContentElementKommentar {
  /**
   *
   * @param {String} id UUID
   * @param {Number} from
   * @param {Number} to
   * @param {String} type value of KommentarType
   * @param {String} text
   */
  constructor(
    id = null,
    from = 0,
    to = 1,
    type = KommentarType.INTERNAL,
    text = '',
  ) {
    let localId = id;
    if (!validate(localId)) {
      localId = uuid();
    }

    this.id = localId;
    this.from = from;
    this.to = to;
    this.type = type;
    this.text = text;
  }

  /**
   * Creates TextContentElementKommentar from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      id = null,
      from = 0,
      to = 1,
      type = KommentarType.INTERNAL,
      text = '',
    } = value;

    return new TextContentElementKommentar(
      id,
      from,
      to,
      type,
      text,
    );
  }

  /**
   * @returns TextContentElementKommentar in JSON representation
   */
  toJSON() {
    return {
      id: this.id,
      from: this.from,
      to: this.to,
      type: this.type,
      text: this.text,
    };
  }

  get valid() {
    const validId = !!this.id && validate(this.id);
    const validFrom = typeof this.from === 'number' && this.from >= 0 && this.from < this.to;
    const validTo = typeof this.to === 'number' && this.to >= 0 && this.from < this.to;
    const validType = !!this.type
      && typeof this.type === 'string'
      && KommentarType.isValidState(this.type);
    const validText = !!this.text && typeof this.text === 'string' && !!this.text.trim();

    const valid = validId
      && validFrom
      && validTo
      && validType
      && validText;

    return valid;
  }
}
