import { isJSON } from '@st/utils-js';
import { validate } from '../utils/uuid';
import MandantDokumentElementSubelement from './MandantDokumentElementSubelement';

export default class MandantDokumentElementClientElement {
  /**
   *
   * @param {Array} order Array of sectionIds
   * @param {JSON} subElements
   *  JSON with sectionIds as keys and MandantDokumentElementSubelement as value
   */
  constructor(
    order = [],
    subElements = {},
  ) {
    this.order = order;
    this.subElements = subElements;
  }

  /**
   *
   * @param {String} id
   */
  removeSubelement(id) {
    if (!validate(id) || !this.order.includes(id)) return;
    const index = this.order.indexOf(id);
    this.order.splice(index, 1);
    delete this.subElements[id];
  }

  /**
   *
   * @param {MandantDokumentElementSubelement} subelement
   */
  addSubelement(subelement) {
    if (!(subelement instanceof MandantDokumentElementSubelement)) return;
    const { id } = subelement;

    this.subElements[id] = subelement;
    this.order.push(id);
  }

  /**
   * Creates MandantDokumentElementSubelements from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      order = [],
    } = value;

    const subelements = {};
    if (value.subElements) {
      Object.keys(value.subElements).forEach((subelementId) => {
        subelements[subelementId] = MandantDokumentElementSubelement
          .fromJSON(subelementId, value.subElements[subelementId]);
      });
    }

    return new MandantDokumentElementClientElement(
      order,
      subelements,
    );
  }

  /**
   * @returns MandantDokumentElementSubelements in JSON representation
   */
  toJSON() {
    const subelements = {};
    Object.keys(this.subElements).forEach((subelementId) => {
      if (this.subElements[subelementId] instanceof MandantDokumentElementSubelement) {
        subelements[subelementId] = this.subElements[subelementId].toJSON();
      } else {
        subelements[subelementId] = this.subElements[subelementId];
      }
    });

    return {
      order: this.order,
      subElements: subelements,
    };
  }

  get sections() {
    return this.order
      .filter((elementId) => !!this.subElements[elementId]
          && Object.keys(this.subElements[elementId]).includes('sections'))
      .map((elementId) => this.subElements[elementId].sections)
      .flat();
  }

  get dokumente() {
    return this.order
      .filter((elementId) => !!this.subElements[elementId])
      .map((elementId) => this.subElements[elementId])
      .flat();
  }

  get validOrder() {
    return Array.isArray(this.order)
      && this.order.length > 0
      && this.order.every((sectionId) => validate(sectionId));
  }

  get validSubelements() {
    return isJSON(this.subElements)
    && Object.keys(this.subElements).length > 0
    && Object.keys(this.subElements)
      .every((subelementId) => validate(subelementId))
    && Object.values(this.subElements)
      .every((subelement) => !!subelement
    && subelement instanceof MandantDokumentElementSubelement
    && subelement.valid);
  }

  get valid() {
    const valid = this.validOrder
      && this.validSubelements;

    return valid;
  }
}
