<template>
  <bbh-dialog
    ref="bbhDialog"
    :small="dialog.small"
    :open-modal="openModal"
    custom-content-class="hilfe-dialog"
    :loading="false"
    class="hilfe-dialog"
    @close="$emit('close')"
  >
    <template #opener>
      <div />
    </template>
    <template #header>
      <div class="help-dialog-header w-100">
        <span>
          Hilfe
        </span>
        <div class="h-100 d-flex items-center">
          <bbh-checkbox
            v-if="$route.matched"
            :value="userSettingsVideo"
            @update:value="updateSettingsVideo($event)"
          >
            <template #label>
              <span class="fs-10">
                Nicht mehr anzeigen
              </span>
            </template>
          </bbh-checkbox>
        </div>
      </div>
    </template>
    <template #text>
      <div
        v-if="videoIsVisible"
        class="w-100 d-flex flex-column gap-20"
      >
        <span>{{ hilfeVideo.videoName }}</span>
        <div class="w-100 d-flex items-center justify-center">
          <video
            ref="hilfe-video"
            width="100%"
            height="auto"
            controls
            class="d-block"
          >
            <source
              type="video/mp4"
              :src="videoSrc"
            >
            Der Browser unterstützt keine Video-Wiedergabe.
          </video>
        </div>
      </div>
      <div
        v-if="isLoading"
        class="d-grid"
      >
        <div
          class="w-100 h-max-content d-flex justify-center py-4"
        >
          <bbh-loading-spinner
            relative
          />
        </div>
      </div>
    </template>
    <template
      #actions
    >
      <bbh-button
        class="dialogButton dialogWarning"
        text="Schließen"
        outlined
        color="var(--bbh-accent-green)"
        @click="handleClicked('close');"
      />
    </template>
  </bbh-dialog>
</template>

<script>
import services from '@src/services';
import { mapGetters, mapMutations } from 'vuex';
import { logError } from '@src/utils/loggingFunctions';
import types from '../../store/types';
import getUserPreferenceRoute from '../../utils/getUserPreferenceRoute';

const userGetters = types.getters.user;
const userMutations = types.mutations.user;
const viewContentGetters = types.getters.viewContent;

export default {
  name: 'HilfeVideoDialog',
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    dialog: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      user: userGetters.USER,
      hilfeVideo: viewContentGetters.HILFE_VIDEO,
    }),

    userSettingsVideo() {
      if (!this.hilfeVideo.id) return false;
      if (!this.$route.matched) return false;

      const preparedURL = this.getUserPreferenceRoute();

      if (!preparedURL) return null;

      const encodedPath = encodeURI(preparedURL);

      return this.user.settings[encodedPath] || false;
    },

    videoSrc() {
      if (!this.hilfeVideo.id) return '';
      const apiURL = window.apiUrl;
      return `${apiURL}/hilfe/video/${this.hilfeVideo.id}`;
    },
    videoIsVisible() {
      return !this.isLoading && !!this.hilfeVideo.id;
    },
  },
  watch: {
    openModal(value) {
      if (!value) {
        const videoElement = this.$refs['hilfe-video'];
        if (videoElement) {
          videoElement.pause();
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      updateSettings: userMutations.SET_USER_PREFERENCE,
    }),
    handleClicked(value) {
      this.$emit('click:action', { value });
    },
    getUserPreferenceRoute() {
      try {
        const route = this.$route.matched.at(0).path;
        const url = window.location.href.split('#')[1];
        return getUserPreferenceRoute(url, route);
      } catch (error) {
        return null;
      }
    },
    updateSettingsVideo(value) {
      const preparedURL = this.getUserPreferenceRoute();

      const encodedPath = encodeURI(preparedURL);
      return services.user.users.putPathPreference(
        encodedPath,
        { value },
      )
        .then(() => {
          this.updateSettings({ key: encodedPath, value });
        })
        .catch((error) => {
          logError(error);
          if (error.response?.status === 403) {
            this.showSnackbarError('Sie haben keine Berechtigung, diese Aufgabe zu bearbeiten');
          } else {
            this.showSnackbarError(error.message);
          }
        });
    },
  },
};
</script>

<style lang='scss'>
.hilfe-dialog.v-dialog.v-dialog--active{
  --bbh-dialog-color: var(--bbh-accent-orange-red);
  --bbh-dialog-close-color: var(--bbh-accent-orange-red);

  & .dialogHeader #dialog-icon > .v-icon:not(.dialog-close-btn) .v-icon__component {
    --bbh-icon-color: var(--bbh-accent-orange-red);
  }

  &.bbh-dialog .dialogText {
    justify-content: center;
  }
}

.help-dialog-header {
  padding: 20px 30px 27px;
  font-family: "ApexNew-Book";
  font-size: 18px;
  line-height: 25px;
  color: var(--bbh-blue);

  width: 100%;

  display: flex;
  justify-content: space-between;
}
</style>
