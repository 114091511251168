<template>
  <bbh-header-btn-menu
    :menu-trigger="menuTrigger"
    :modal-width="287"
    @menu-trigger="handleMenuTrigger($event)"
  >
    <template #activator>
      <v-btn
        icon
        :ripple="false"
        class="header-menu-btn"
        @click="menuTrigger = true"
      >
        <v-icon>$questionCircle</v-icon>
      </v-btn>
    </template>
    <template #content>
      <div class="dialog-hilfe">
        <bbh-list
          :items="items"
          @click:item="onItemClicked($event)"
        />
      </div>
    </template>
  </bbh-header-btn-menu>
</template>

<script>
import windowFunctions from '@src/utils/windowFunctions';
import { mapGetters, mapMutations } from 'vuex';
import { uuid } from '@src/utils/uuid';
import types from '../../store/types';

const userGetters = types.getters.user;

const viewContentGetters = types.getters.viewContent;
const viewContentMutations = types.mutations.viewContent;

const WIKI_URL = 'https://app.bbh-solutions.de/public/staticDocument/VNP4Qk0sJYyFPv4aZ0MmHLj974ytPWjrgELu50BS20Lu176gt4';

export default {
  name: 'HilfeDialog',
  data: () => ({
    menuTrigger: false,

    hilfeDialogConfig: {
      header: 'Hilfe',
      buttons: [ { value: 'close', text: 'Schließen', outlined: true } ],
      small: false,
    },
  }),
  computed: {
    ...mapGetters({
      user: userGetters.USER,
      hilfeVideo: viewContentGetters.HILFE_VIDEO,
    }),
    items() {
      return [
        this.hilfeVideo.id ? {
          id: uuid(), text: 'Video', icon: '$videoPlayer',
        } : undefined,
        { id: uuid(), text: 'Wiki', icon: '$officeShelf' },
      ].filter((v) => !!v);
    },
  },
  methods: {
    ...mapMutations({
      showSnackbarError: viewContentMutations.SHOW_SNACKBAR_ERROR,
    }),

    onItemClicked(value) {
      switch (value.event.text) {
      case 'Video':
        this.$emit('click', value.event);
        break;
      case 'Wiki':
        windowFunctions.open(WIKI_URL, this.showSnackbarError);
        break;
      default:
        break;
      }
      this.handleMenuTrigger(false);
    },

    handleMenuTrigger(value) {
      this.menuTrigger = value;
    },
  },
};
</script>

<style lang="scss">
.v-btn.header-menu-btn {
  padding-top: 5px;

  width: 24px;
  height: 24px;

  & .v-icon__component {
    width: 24px;
    height: 24px;
    --bbh-icon-color: var(--bbh-black);
  }
}

.dialog-hilfe {
  margin: 10px 0;

  & .v-list-item,
  .v-list .v-list-item {
    grid-template-columns: 80px 1fr;
  }

  & .v-list-item .v-list-item__icon,
  .v-list .v-list-item .v-list-item__icon {
    margin: 0;
    justify-self: center;
  }
}
</style>
