import style from './default-style';

export default {
  name: 'bold',
  attrs: {
    id: { default: null },
  },
  parseDOM: [
    {
      tag: 'bold',
      style: 'font-family=ApexNew-Medium',
    },
  ],
  toDOM() {
    const element = document.createElement('placeholder');
    Object.assign(element.style, style, { fontFamily: 'ApexNew-Medium', backgroundColor: 'yellow' });

    return element;
  },
};
