function getCellAttrs(dom, extraAttrs) {
  const widthAttr = dom.getAttribute('data-colwidth');
  const widths = widthAttr && /^\d+(,\d+)*$/.test(widthAttr) ? widthAttr.split(',').map((s) => Number(s)) : null;
  const colspan = Number(dom.getAttribute('colspan') || 1);
  const result = {
    colspan,
    rowspan: Number(dom.getAttribute('rowspan') || 1),
    colwidth: widths && widths.length === colspan ? widths : null,
  };
  Object.keys(extraAttrs).forEach((prop) => {
    const getter = extraAttrs[prop].getFromDOM;
    const value = getter && getter(dom);
    if (value != null) result[prop] = value;
  });
  return result;
}

function setCellAttrs(node, extraAttrs) {
  const attrs = {};
  if (node.attrs.colspan !== 1) attrs.colspan = node.attrs.colspan;
  if (node.attrs.rowspan !== 1) attrs.rowspan = node.attrs.rowspan;
  if (node.attrs.colwidth) { attrs['data-colwidth'] = node.attrs.colwidth.join(','); }
  Object.keys(extraAttrs).forEach((prop) => {
    const setter = extraAttrs[prop].setDOMAttr;
    if (setter) setter(node.attrs[prop], attrs);
  });
  return attrs;
}

export default {
  table: {
    group: 'block',
    content: 'table_row+',
    tableRole: 'table',
    attrs: {
      id: { default: null },
    },
    isolating: true,
    toDOM: () => [
      'table', [
        'tbody', 0,
      ],
    ],
    parseDOM: [ { tag: 'table' } ],
  },
  table_row: {
    content: '(table_cell | table_header)*',
    attrs: {
      id: { default: null },
    },
    tableRole: 'row',
    parseDOM: [ { tag: 'tr' } ],
    toDOM: () => [
      'tr', 0,
    ],
  },
  table_cell: {
    content: '(paragraph | table)+',
    attrs: {
      colspan: { default: 1 },
      rowspan: { default: 1 },
      colwidth: { default: null },
      id: { default: null },
    },
    tableRole: 'cell',
    isolating: true,
    parseDOM: [ { tag: 'td', getAttrs: (dom) => getCellAttrs(dom, {}) } ],
    toDOM: (node) => [
      'td', setCellAttrs(node, {}), 0,
    ],
  },
  table_header: {
    content: '(paragraph | table)+',
    attrs: {
      colspan: { default: 1 },
      rowspan: { default: 1 },
      colwidth: { default: null },
      id: { default: null },
    },
    tableRole: 'header_cell',
    isolating: true,
    parseDOM: [ { tag: 'th', getAttrs: (dom) => getCellAttrs(dom, {}) } ],
    toDOM: (node) => [
      'th', setCellAttrs(node, {}), 0,
    ],
  },
};
