import config from './config';
import { get, patch } from '../axios';

const { API_PREFIX } = config;

export default {
  /**
   * get element revisions to last save state with wanted patch tags
   *
   * @param {String} elementId
   * @param {Array} wantedPatchTags Array of wanted patchTag which are of the class PatchTag
   * @returns
   */
  fetchRevisions(elementId, wantedPatchTags = []) {
    let url = `${API_PREFIX}/element/${elementId}/revisions/`;
    if (wantedPatchTags.length > 0) {
      url += wantedPatchTags.join(',');
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   * Element auf revision zurücksetzen
   *
   * @param {String} elementId
   * @param {Number} revision
   */
  revertToRevision(elementId, revision) {
    const url = `${API_PREFIX}/element/${elementId}/goBackToRevision/${revision}`;

    return new Promise((resolve, reject) => {
      patch(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },
};
