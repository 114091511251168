// import ElementTyp from '../../model/enums/ElementTyp';
import { get } from '../axios';

const API_PREFIX = 'solr';

export default {
  fetch(elementId) {
    const URL = `${API_PREFIX}/similarities/${elementId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};
