import bold from './bold';
import italic from './italic';
import linethrough from './linethrough';
import underline from './underline';
import highlight from './highlight';
import placeholder from './placeholder';
import marker from './marker';

export default {
  bold,
  italic,
  linethrough,
  underline,
  highlight,
  placeholder,
  marker,
};
