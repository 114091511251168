import { isJSON } from '@st/utils-js';
import Element from './Element';
import ElementStatus from './enums/ElementStatus';
import ElementTyp from './enums/ElementTyp';
import KlauselElementAttrs from './KlauselElementAttrs';
import SubelementContentElement from './SubelementContentElement';
import TextContentElement from './TextContentElement';

function getLevelKey(level) {
  return `level${(level)}`;
}

export default class KlauselElement extends Element {
  /**
   *
   * @param {String} id UUID
   * @param {String} state value of ElementStatus
   * @param {KlauselElementAttrs} attrs
   * @param {Array} content Array of SubelementContentElements or TextContentElements
   * @param {Number} revision Revision of KlauseElement
   */
  constructor(
    id = null,
    state = ElementStatus.MODIFIABLE,
    attrs = new KlauselElementAttrs(),
    content = [],
    revision = 0,
    similarities = {},
  ) {
    const mappedContent = KlauselElement.buildContent(content);
    super(id, ElementTyp.KLAUSEL, state, attrs, revision, mappedContent);
    this.similarities = similarities;
  }

  /**
   * Creates KlauselElement from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @param {JSON} elements
   * @returns null if invalid
   */
  static fromJSON(value, elements = {}, withSubelements = false) {
    if (value instanceof KlauselElement) return value;
    if (!isJSON(value)) return null;

    const {
      id = null,
      state = ElementStatus.MODIFIABLE,
      revision = 0,
      content = [],
    } = value;

    let localAttrs = new KlauselElementAttrs();
    if (value.attrs instanceof KlauselElementAttrs) {
      localAttrs = value.attrs;
    } else if (isJSON(value.attrs)) {
      localAttrs = KlauselElementAttrs.fromJSON(value.attrs);
    }

    const localContent = [];

    content.forEach((subelement) => {
      let tmpSubElement = subelement;

      const elemIsSubelement = ElementTyp.isSubelement(tmpSubElement.type);
      const elemExists = elemIsSubelement && elements[tmpSubElement.id];

      if (elemIsSubelement && !elemExists) {
        tmpSubElement = (new TextContentElement()).toJSON();

        tmpSubElement.content = [
          {
            type: 'paragraph',
            content: [
              {
                marks: [ { type: 'bold' } ],
                text: 'Die Klausel wurde gelöscht.',
                type: 'text',
              },
            ],
          },
        ];

        tmpSubElement.attrs.alertType = 'deleted';
      }

      if (elemIsSubelement && elemExists) {
        if (!withSubelements) {
          const sections = KlauselElement.buildContent(elements[subelement.id].content);
          localContent.push(...sections);
        } else {
          localContent.push(SubelementContentElement.fromJSON(tmpSubElement));
        }
      } else {
        localContent.push(tmpSubElement);
      }
    });

    return new KlauselElement(
      id,
      state,
      localAttrs,
      localContent,
      revision,
    );
  }

  /**
   * @returns KlauselElement in JSON representation
   */
  toJSON() {
    let attrs = null;
    if (this.attrs instanceof KlauselElementAttrs) {
      attrs = this.attrs.toJSON();
    } else {
      attrs = this.attrs;
    }

    const content = this.content.map((item) => {
      if (item instanceof SubelementContentElement
        || item instanceof TextContentElement
      ) {
        return item.toJSON();
      }
      return item;
    });

    return {
      id: this.id,
      state: this.state,
      type: this.type,
      attrs,
      content,
      revision: this.revision,
    };
  }

  static buildContent(content) {
    return content.map((subelement) => {
      const isTextContentElement = subelement instanceof TextContentElement;
      const isSubelementContentElement = subelement instanceof SubelementContentElement;

      if (isTextContentElement || isSubelementContentElement) {
        return subelement;
      }

      const subElementIsTypeSubelement = ElementTyp.isSubelement(subelement.type);
      if (subElementIsTypeSubelement) {
        return SubelementContentElement.fromJSON(subelement);
      }
      const subElementIsTypeAbschnitt = ElementTyp.isAbschnitt(subelement.type);
      const subElementIsTypeUeberschrift = ElementTyp.isUeberschrift(subelement.type);
      if (
        subElementIsTypeAbschnitt
        || subElementIsTypeUeberschrift
      ) {
        return TextContentElement.fromJSON(subelement);
      }

      return undefined;
    })
      .filter((c) => !!c);
  }

  get sections() {
    return this.content.map((c) => ({ ...c, sectionId: c.id }));
  }

  get order() {
    return this.content.map((c) => c.id);
  }

  setContent(content) {
    this.content = content;
  }

  get labels() {
    const localCounter = {};
    let maxLevel;
    let currentAnchor = 0;
    let labelVisible = 1;

    const labels = {};

    this.sections.forEach((section) => {
      let { level } = section.attrs;

      const { type } = section;

      const showEntry = level !== 0;

      if (ElementTyp.isKlausel(type)) {
        if (level > 0) {
          currentAnchor = level;
          labelVisible = 1;
        } else labelVisible = 0;

        if (
          localCounter[getLevelKey(level)] === null
          || localCounter[getLevelKey(level)] === undefined
        ) {
          Array.from(
            {
              length: level + 1,
            },
            (v, k) => {
              if (
                localCounter[getLevelKey(k)] === null
                || localCounter[getLevelKey(k)] === undefined
              ) localCounter[getLevelKey(k)] = 1;
              return null;
            },
          );
          if (level > 0) maxLevel = level;
        } else {
          localCounter[getLevelKey(level)] += 1;

          Array.from(
            {
              length: maxLevel + 1,
            },
            (v, k) => {
              if (k > level) {
                delete localCounter[getLevelKey(k)];
              }
              return null;
            },
          );
        }
      } else {
        switch (type) {
        case ElementTyp.ABSCHNITT:
          level = currentAnchor ? level + currentAnchor : level;
          if (labelVisible && showEntry) {
            if (
              localCounter[getLevelKey(level)] === null
                  || localCounter[getLevelKey(level)] === undefined
            ) {
              Array.from(
                {
                  length: level + 1,
                },
                (v, k) => {
                  if (
                    localCounter[getLevelKey(k)] === null
                        || localCounter[getLevelKey(k)] === undefined
                  ) localCounter[getLevelKey(k)] = 1;
                  return null;
                },
              );
              maxLevel = level;
            } else {
              localCounter[getLevelKey(level)] += 1;

              Array.from(
                {
                  length: maxLevel + 1,
                },
                (v, k) => {
                  if (k > level) {
                    delete localCounter[getLevelKey(k)];
                  }
                  return null;
                },
              );
            }
          }
          break;
        default:
          break;
        }
      }

      delete localCounter.level0;

      const ordered = {};

      Object.keys(localCounter)
        .sort()
        .reduce((obj, key) => {
          ordered[key] = localCounter[key];
          return obj;
        }, {});

      const label = labelVisible && showEntry > 0
        ? JSON.stringify(Object.values(ordered).slice(0, level))
          .replace(/(\[|\])/g, '')
          .replaceAll(',', '.')
        : '';

      if (label.length > 0) {
        labels[section.refPathId] = label;
      }
    });

    return labels;
  }

  get validType() {
    return !!this.type
      && typeof this.type === 'string'
      && ElementTyp.isKlausel(this.type);
  }

  get validState() {
    return !!this.state
      && typeof this.state === 'string'
      && ElementStatus.isValidState(this.state);
  }

  get validAttrs() {
    return !!this.attrs
      && this.attrs instanceof KlauselElementAttrs
      && this.attrs.valid;
  }

  get validContent() {
    return !!this.content
      && Array.isArray(this.content)
      && this.content.length > 0
      && this.content.every((subelement) => {
        const isTextContentElement = subelement instanceof TextContentElement;
        const isSubelementContentElement = subelement instanceof SubelementContentElement;

        return (isTextContentElement || isSubelementContentElement) && subelement.valid;
      });
  }

  get validRevision() {
    return typeof this.revision === 'number' && this.revision >= 0;
  }

  get valid() {
    const { validId } = this;

    const valid = validId
      && this.validType
      && this.validState
      && this.validAttrs
      && this.validRevision
      && this.validContent;

    return valid;
  }
}
