import { deepClone } from '@st/utils-js';
import { authWrapper } from '@src/services/axios';
import MandantDokumentElement from '../../../../model/MandantDokumentElement';
import MandantDokumentElementClientElement from '../../../../model/MandantDokumentElementClientElement';
import services from '../../../../services';
import Patcher from '../../../../utils/Patcher';
import types from '../../../types';

const mandantEditorActions = types.actions.mandantEditor;
const mandantEditorMutations = types.mutations.mandantEditor;

const viewContentMutations = types.mutations.viewContent;

export default {
  [mandantEditorActions.FETCH_CLIENT_ELEMENTS]({ commit }, clientId) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.getClientElementList(clientId)
        .then((result) => {
          commit(mandantEditorMutations.SET_CLIENT_ELEMENTS, {
            clientElements: result?.data?.results,
          });
          resolve(result);
        })
        .catch((error) => reject(error));
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.FETCH_CLIENT_ELEMENT]({ commit }, { elementId, revision, subElementId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.getClientElement(elementId, revision)
        .then((response) => {
          const tmpClientElement = MandantDokumentElement.fromJSON(response.data);

          if (!!subElementId && !tmpClientElement.clientElement.subElements[subElementId]) {
            const error = new Error('Dokument im Vertrag nicht gefunden');
            error.response = {
              status: 404,
            };
            reject(error);
          }

          commit(mandantEditorMutations.SET_CLIENT_ELEMENT, tmpClientElement);
          resolve(tmpClientElement);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            'Vertrag/Dokument ist nicht valide',
          );
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.PUT_CLIENT_ELEMENT_METADATA]({ commit }, {
    clientElementId,
    clientElementName,
    clientElementNotes,
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.putClientElementMetadata({
        clientElementId,
        clientElementName,
        clientElementNotes,
      })
        .then(() => {
          commit(mandantEditorMutations.SET_CLIENT_ELEMENT_NAME, {
            clientElementId,
            clientElementName,
          });
          commit(mandantEditorMutations.SET_CLIENT_ELEMENT_NOTES, {
            clientElementId,
            clientElementNotes,
          });
          resolve(clientElementName);
          this.isLoading = false;
        })
        .catch((error) => reject(error));
    });

    return authWrapper({ commit }, prm);
  },

  /**
   * Erstellt ein neues Dokument und es wird ein Patch an die Middleware verschickt.
   * @param {*} param0
   * @param {MandantDokumentElementClientElement} clientElement
   */
  [mandantEditorActions.ADD_CLIENT_ELEMENT_NEW_ELEMENT](
    { commit },
    {
      clientElementId,
      revision,
      clientElement,
      newSubelement,
    },
  ) {
    if (!(clientElement instanceof MandantDokumentElementClientElement)) return null;

    const currentClientElementJSON = deepClone(clientElement.toJSON());
    const currentClientElement = MandantDokumentElementClientElement
      .fromJSON(currentClientElementJSON);

    currentClientElement.addSubelement(newSubelement);

    const clientElementJSON = clientElement.toJSON();

    const patches = Patcher.createPatches(clientElementJSON, currentClientElement.toJSON());

    const prm = new Promise((resolve, reject) => {
      services.editor.client.patchClientElement({
        clientElementId,
        patches,
        revision,
      }).then(() => {
        resolve(newSubelement);
      }).catch((error) => {
        const msg = 'Dokument konnte nicht hinzugefügt werden';
        commit(viewContentMutations.SHOW_SNACKBAR_ERROR, msg);
        reject(error);
      });
    });

    return authWrapper({ commit }, prm);
  },

  /**
   * Löscht ein Dokument und es wird ein Patch an die Middleware verschickt.
   * @param {*} param0
   * @param {MandantDokumentElementClientElement} clientElement
   */
  [mandantEditorActions.REMOVE_CLIENT_ELEMENT_SUBELEMENT](
    { commit },
    {
      clientElementId,
      revision,
      clientElement,
      subelementId,
    },
  ) {
    const prm = new Promise((resolve, reject) => {
      if (!(clientElement instanceof MandantDokumentElementClientElement)) {
        resolve(false);
        return;
      }

      const currentClientElementJSON = deepClone(clientElement.toJSON());
      const currentClientElement = MandantDokumentElementClientElement
        .fromJSON(currentClientElementJSON);

      currentClientElement.removeSubelement(subelementId);

      const clientElementJSON = clientElement.toJSON();

      const patches = Patcher.createPatches(clientElementJSON, currentClientElement.toJSON());

      services.editor.client.patchClientElement({
        clientElementId,
        patches,
        revision,
      }).then(() => {
        resolve(true);
      }).catch((error) => {
        const msg = 'Element konnte nicht gelöscht werden';
        commit(viewContentMutations.SHOW_SNACKBAR_ERROR, msg);
        reject(error);
      });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.DEPRECATE_CLIENT_ELEMENT]({ commit }, { clientElementId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.deleteClientElement(clientElementId)
        .then((result) => {
          commit(mandantEditorMutations.DEPRECATE_CLIENT_ELEMENT, { clientElementId });
          resolve(result);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.PUT_CLIENT_ELEMENT_RELEASE_FLAG]({ commit }, {
    clientElementId,
    freigabeStatus,
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.putClientElementReleaseFlag(clientElementId, freigabeStatus)
        .then((result) => {
          commit(
            mandantEditorMutations.SET_CLIENT_ELEMENT_RELEASE_FLAG,
            { clientElementId, freigabeStatus },
          );
          resolve(result);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.PUT_CLIENT_ELEMENT_TAGS]({ commit }, { clientElementId, tagsObj }) {
    const tagIds = tagsObj.tags.map((tag) => tag.id);
    const prm = new Promise((resolve, reject) => {
      services.editor.client.putClientElementTags(clientElementId, { tags: tagIds })
        .then((result) => {
          commit(
            mandantEditorMutations.SET_CLIENT_ELEMENT_TAGS,
            { clientElementId, tags: tagsObj.tags },
          );
          resolve(result);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [mandantEditorActions.GET_CLIENT_ELEMENT_UPDATES]({ commit }, { clientElementId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.getClientElementUpdates(clientElementId)
        .then((result) => {
          commit(mandantEditorMutations.SET_CLIENT_ELEMENT_UPDATES, {
            updates: result.data.updates,
          });
          resolve(result);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  // Updates
  [mandantEditorActions.GET_CLIENT_ELEMENT_ZWANGSUPDATE]({ commit }, { clientElementId }) {
    return new Promise((resolve, reject) => {
      services.client.update.getZwangsupdate(clientElementId)
        .then((result) => {
          const zwangsupdate = result.data;
          commit(mandantEditorMutations.SET_CLIENT_ELEMENT_ZWANGSUPDATE, zwangsupdate);
          resolve(result);
        })
        .catch((error) => reject(new Error(error.message)));
    });
  },
  [mandantEditorActions.SET_UPDATE_COMPLETED]({ commit }, { clientId, clientElementId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.mandantUpdate.setUpdateCompleted(clientId, clientElementId)
        .then((result) => {
          commit(mandantEditorMutations.SET_UPDATE_COMPLETED, { clientElementId });
          commit(mandantEditorMutations.UPDATE_MANDANT_REVISION);
          resolve(result);
        }).catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },
  [mandantEditorActions.REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY](
    { state, commit },
    { clientId, clientElementId, sectionId },
  ) {
    if (!state.clientElementZwangsupdate[sectionId]) return null;

    const prm = new Promise((resolve, reject) => {
      services.editor.mandantUpdate.deleteZwangsupdateSection(clientId, clientElementId, sectionId)
        .then((result) => {
          commit(
            mandantEditorMutations.REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY,
            sectionId,
          );
          resolve(result);
        }).catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },
};
