import getters from '../types/getter-types';

export default {
  [getters.SUB_HEADER_ACTION_BAR]: (state) => state.subHeaderActionBar,

  [getters.NEW_MUSTERELEMENT]: (state) => state.newMusterelement,

  [getters.INDIVIDUELLER_VERTRAG]: (state) => state.individuellerVertrag,

  [getters.PAKETVERWALTUNG]: (state) => state.paketverwaltung,

  [getters.FRAGEBOGEN_BEARBEITEN]: (state) => state.fragebogenBearbeiten,
  [getters.FRAGEBOGEN]: (state) => state.fragebogen,
};
