import { isJSON } from '@st/utils-js';
import ElementAttrs from './ElementAttrs';
import { validate } from '../utils/uuid';
import ElementReference from './ElementReference';
import Fragebogen from './Fragebogen';
import Responsibilities from './Responsibilities';
import UserRolle from './enums/UserRolle';

const REQUIRED_ROLES = [
  UserRolle.RECHTSANWALT,
  UserRolle.ADMINPARTNER,
  UserRolle.PARTNER,
];

export default class MustervertragElementAttrs extends ElementAttrs {
  /**
   *
   * @param {String} name
   * @param {Array} hashtags Array of UUIDs
   * @param {Responsibilities} responsibilities
   * @param {Fragebogen} questionnaire
   * @param {JSON} elementReference Keys are UUIDs of Reference and the value an ElementReference
   */
  constructor(
    name = '',
    hashtags = [],
    responsibilities = new Responsibilities(),
    questionnaire = new Fragebogen(),
    elementReference = {},
  ) {
    super(name, hashtags, responsibilities, REQUIRED_ROLES);
    this.questionnaire = questionnaire;
    this.elementReference = elementReference;
  }

  /**
   * Creates MustervertragElementAttrs from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      name = '',
      hashtags = [],
      responsibilities,
      elementReference = {},
      questionnaire = new Fragebogen(),
    } = value;

    let q = questionnaire;
    if (!(q instanceof Fragebogen) && isJSON(q)) {
      q = Fragebogen.fromJSON(q);
    }

    const refs = elementReference;
    if (isJSON(refs) && Object.keys(refs).length > 0) {
      Object.keys(refs).forEach((refId) => {
        refs[refId] = refs[refId].map((ref) => ElementReference.fromJSON(ref));
      });
    }

    let res = responsibilities;
    if (!(res instanceof Responsibilities) && isJSON(res)) {
      res = Responsibilities.fromJSON(res);
    }

    return new MustervertragElementAttrs(
      name,
      hashtags,
      res,
      q,
      elementReference,
    );
  }

  /**
   * @returns MustervertragElementAttrs in JSON representation
   */
  toJSON() {
    const elementReference = {};
    Object.keys(this.elementReference).forEach((elementReferenceId) => {
      let tmpElementReference = this.elementReference[elementReferenceId];
      if (tmpElementReference instanceof ElementReference) {
        tmpElementReference = tmpElementReference.toJSON();
        elementReference[tmpElementReference.id] = null;
        delete tmpElementReference.id;
        elementReference[tmpElementReference.id] = tmpElementReference;
      } else {
        elementReference[elementReferenceId] = tmpElementReference;
      }
    });

    let questionnaire = {};
    if (this.questionnaire instanceof Fragebogen) {
      questionnaire = this.questionnaire.toJSON();
    } else {
      questionnaire = this.questionnaire;
    }

    let responsibilities = {};
    if (this.responsibilities instanceof Responsibilities) {
      responsibilities = this.responsibilities.toJSON();
    } else {
      responsibilities = this.responsibilities;
    }

    return {
      name: this.name,
      hashtags: this.hashtags,
      responsibilities,
      questionnaire,
      elementReference,
    };
  }

  get validQuestionnaire() {
    let validQuestionnaire = false;
    if (this.questionnaire instanceof Fragebogen) {
      validQuestionnaire = this.questionnaire.valid;
    }
    return validQuestionnaire;
  }

  get validElementReference() {
    return isJSON(this.elementReference)
    && Object.keys(this.elementReference)
      .every((referenceId) => validate(referenceId))
    && Object.values(this.elementReference).flat()
      .every(
        (reference) => reference instanceof ElementReference && reference.valid,
      );
  }

  get valid() {
    const { validName, validHashtags, validResponsibilities } = this;

    const valid = validName
      && validHashtags
      && validResponsibilities
      && this.validQuestionnaire
      && this.validElementReference;

    return valid;
  }
}
