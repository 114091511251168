import { isEmpty } from '@st/utils-js';
import ElementTyp from '../../../../model/enums/ElementTyp';
import sectionRefPath from '../../../../utils/sectionRefPath';
import types from '../../../types';

const sharedEditorGetters = types.getters.sharedEditor;
const editorGetters = types.getters.editor;

export default {
  [sharedEditorGetters.COMMENT]: (state) => state.comment,
  [sharedEditorGetters.REFERENCE]: (state) => state.reference,
  [sharedEditorGetters.FOCUSED_SECTION]: (state) => state.focusedSection || {},
  [sharedEditorGetters.SECTION_RERENDER_MAP]: (state) => state.sectionRerenderMap,
  [sharedEditorGetters.UPDATE_VALUE_COUNTER]: (state) => state.updateValueCounter,
  [sharedEditorGetters.RESET_TO_REVISION_COUNTER]: (state) => state.resetToRevisionCounter,
  [sharedEditorGetters.ELEMENT_TAB_TRIGGER]: (state) => state.elementTabTrigger,

  [sharedEditorGetters.COMPARE_MODE]: (state) => state.compareMode,

  [sharedEditorGetters.FREIGABE_STATUS]: (state) => state.freigabe,
  [sharedEditorGetters.FREIGABEPROZESS_CHECKLIST]: (state) => state.checklist,
  [sharedEditorGetters.FREIGABEPROZESS_CHECKLIST_PROZESS]: (state, getters, rootState) => {
    const checked = getters[sharedEditorGetters.FREIGABEPROZESS_CHECKLIST_SECTION_CHECKED];

    const { compiledContent } = rootState.editor.element;
    if (!compiledContent) return [];

    const { currentDocId } = rootState.editor;
    const currentDoc = rootState.editor.element.elements[currentDocId];
    const currentDocIsKlausel = ElementTyp.isKlausel(currentDoc.type);

    const numbering = getters[editorGetters.NUMBERING];
    const labels = numbering(currentDocIsKlausel);

    return compiledContent
      .map((element) => {
        const label = labels ? labels[element.refPathId] : '';

        return {
          refPathId: element.refPathId,
          id: element.refElement.id,
          ancestors: element.ancestors,
          label,
          value: checked(element),
        };
      })
      .filter((section) => !!section.label);
  },
  [sharedEditorGetters.FREIGABEPROZESS_CHECKLIST_CHECKED]:
    (state, _, rootState) => ({ key, revision }) => {
      const keySplitted = key.split('_');

      const { element: editorElement } = rootState.editor;

      const checklistRevision = state.checklist[key]
        ? state.checklist[key].newestRevision
        : null;

      if (isEmpty(editorElement)) {
        if (typeof revision !== 'number'
          || typeof checklistRevision !== 'number') {
          return false;
        }
        return revision === checklistRevision;
      }

      const elementId = editorElement.editorElement;
      const element = editorElement.elements[elementId];

      const elementType = element.type;

      if (ElementTyp.isMustervertrag(elementType)) {
        if (state.checklist[key]) {
          const allSubelementIds = Object.keys(editorElement.elements);
          const subelementId = keySplitted.reverse().find((k) => allSubelementIds.includes(k));

          const elementRevision = revision
            || rootState.editor.element.elementRevisions[subelementId];
          return checklistRevision === elementRevision;
        }
        return false;
      }

      if (ElementTyp.isDokument(elementType)
        || ElementTyp.isMusterdokument(elementType)
        || ElementTyp.isKlausel(elementType)) {
        if (state.checklist[key]) {
          const elementRevision = revision
            || rootState.editor.element.elementRevisions[elementId];
          return checklistRevision === elementRevision;
        }
        return false;
      }
      return false;
    },
  [sharedEditorGetters.FREIGABEPROZESS_CHECKLIST_SECTION_CHECKED]: (_, getters) => (section) => {
    const key = sectionRefPath(section);
    return getters[sharedEditorGetters.FREIGABEPROZESS_CHECKLIST_CHECKED]({ key });
  },
};
