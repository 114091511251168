import { authWrapper } from '@src/services/axios';
import services from '../../../../services';
import types from '../../../types';
import actions from '../types/action-types';

const aufgabenMutations = types.mutations.aufgaben;

export default {
  [actions.GET_FREIGABE_NEUER_MANDANT]({ commit }, { aufgabeBearbeiterId }) {
    const prm = new Promise((resolve, reject) => {
      services.workflow.aufgaben.getFreigabeNeuerMandant(
        aufgabeBearbeiterId,
      )
        .then((result) => {
          commit(aufgabenMutations.SET_USER, result.data);
          resolve(result);
        })
        .catch((error) => reject(new Error(error.message)));
    });

    return authWrapper({ commit }, prm);
  },
};
