import config from './config';
import { get } from '../axios';

const { API_PREFIX } = config;

export default {
  /**
   * Fetches current version of the application
   * @returns
   */
  fetch() {
    const url = `${API_PREFIX}/version`;
    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },
};
