export default class FrageTyp {
  static QUESTION = 'question';

  static QUESTION_PLATZHALTER = 'questionPlatzhalter';

  static isValidType(type) {
    const states = [
      this.QUESTION, this.QUESTION_PLATZHALTER,
    ];
    return states.includes(type);
  }

  static isQuestion(type) {
    return type === this.QUESTION;
  }

  static isQuestionPlatzhalter(type) {
    return type === this.QUESTION_PLATZHALTER;
  }
}
