/* eslint-disable max-len */
import Vue from 'vue';
import VueRouter from 'vue-router';
import requireRoles from './guard';
import checkForHilfeVideo from './hilfe';
import secretGuard from './secretGuard';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  routes,
});

router.beforeEach((to, from, next) => {
  secretGuard(to, from, next);
  requireRoles(to, from, next);
  checkForHilfeVideo(to, from, next);
});

export default router;
