export default {
  hashtags: [],
  hashtagTypes: {},
  choices: [],
  questions: [],
  rolles: [],
  elements: [],
  mustervertraege: [],

  snackbar: {},

  hilfe: {
    id: null,
    videoName: '',
  },

  resetFragebogenCounter: 0,
};

export const component = 'viewContent/';
