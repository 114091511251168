import { component as MODULE } from '../store/state';

const MUTATIONS = {
  SET_ELEMENT: `${MODULE}set_element`,
  SET_ELEMENT_CURRENT_DOC_CONTENT: `${MODULE}set_element_current_doc_content`,
  SET_PHRASE_REFERENCE: `${MODULE}set_phrase_reference`,
  SET_PHRASE: `${MODULE}set_phrase`,
  SET_CLAUSE_OBJ: `${MODULE}set_clause_obj`,
  SET_SELECTED_CLAUSE: `${MODULE}set_selected_clause`,
  SET_CURRENT_DOC_ID: `${MODULE}set_current_doc_id`,
  SET_CURRENT_MASTER_CONTRACT_IN_EDITOR_ELEMENT:
    `${MODULE}set_current_master_contract_in_editor_element`,
  SET_EDITOR_ELEMENT_STATE_TO_APPROVED: `${MODULE}set_editor_element_state_to_approved`,
  SET_ELEMENT_REVISIONS: `${MODULE}set_element_revisions`,

  UPDATE_ELEMENT_CONTENT: `${MODULE}update_element_content`,
  UPDATE_ELEMENT_ATTRS: `${MODULE}update_element_attrs`,
  UPDATE_ELEMENT_SIMILARITIES: `${MODULE}update_element_similarities`,
  UPDATE_ELEMENT_SECTION_ATTRS: `${MODULE}update_element_section_attrs`,
  UPDATE_ELEMENT_REVISION: `${MODULE}update_element_revision`,
  UPDATE_ELEMENT_REVISIONS_ARRAY: `${MODULE}update_element_revisions_array`,
  UPDATE_EDITOR_ELEMENT_REVISION: `${MODULE}update_editor_element_revision`,
  UPDATE_DOC_NAME: `${MODULE}update_doc_name`,
  UPDATE_CURRENT_DOC_ORDER: `${MODULE}update_current_doc_order`,
  UPDATE_PHRASE_REFERENCE: `${MODULE}update_phrase_reference`,
  UPDATE_INTERNAL_REFERENCE: `${MODULE}update_internal_reference`,
  UPDATE_ELEMENT_REFERENCE: `${MODULE}update_element_reference`,
  UPDATE_CONTENT_ABSCHNITT_IN_KLAUSEL_UMWANDELN:
    `${MODULE}UPDATE_CONTENT_ABSCHNITT_IN_KLAUSEL_UMWANDELN`,
  UPDATE_ELEMENTS_CONTENT_ORDER: `${MODULE}update_elements_content_order`,

  UPDATE_CHOICE_IN_SECTION: `${MODULE}update_choice_in_section`,

  ADD_INTERNAL_REFERENCE_TO_CURRENT_DOC: `${MODULE}add_internal_reference_to_current_doc`,
  ADD_ELEMENT_REFERENCE_TO_ELEMENT: `${MODULE}add_element_reference_to_element`,

  // FragebogenBearbeiten
  UPDATE_QUESTIONNAIRE_CONTENT: `${MODULE}update_questionnaire_content`,
  UPDATE_QUESTIONNAIRE_CONTENT_ORDER: `${MODULE}update_questionnaire_content_order`,
  UPDATE_QUESTIONNAIRE_CONTENT_QUESTION: `${MODULE}update_questionnaire_content_question`,
  UPDATE_QUESTIONNAIRE_CONTENT_ADD_QUESTION: `${MODULE}update_questionnaire_content_add_question`,
  UPDATE_QUESTIONNAIRE_CONTENT_REMOVE_QUESTION:
    `${MODULE}update_questionnaire_content_remove_question`,

  UPDATE_COMPARE_ELEMENT: `${MODULE}udpate_compare_element`,

  ADD_CURRENT_DOC_ORDER_ENTRY: `${MODULE}add_current_doc_order_entry`,
  ADD_ELEMENT_TO_ELEMENT: `${MODULE}add_element_to_element`,
  ADD_SECTION_TO_CURRENT_DOC: `${MODULE}add_section_to_current_doc`,
  ADD_CLAUSE_TO_CURRENT_DOC: `${MODULE}add_clause_to_current_doc`,

  CLEAR_PHRASE: `${MODULE}clear_phrase`,
  CLEAR_PHRASE_REFERENCE: `${MODULE}clear_phrase_reference`,

  REMOVE_SECTION_FROM_CURRENT_DOC: `${MODULE}remove_section_from_current_doc`,
  REMOVE_UPDATE_FROM_UPDATE_OBJECT: `${MODULE}remove_update_from_update_object`,

  INCREASE_CHOICE_TRIGGER: `${MODULE}increase_choice_trigger`,

  SET_TRIGGER_SAVE_PATCH: `${MODULE}set_trigger_save_patch`,
};

export default MUTATIONS;
