import { component as MODULE } from '../store/state';

const GETTERS = {
  USER: `${MODULE}user`,
  USER_ANREDE: `${MODULE}user_anrede`,
  USER_CLIENTS: `${MODULE}user_clients`,
  USER_TAGS: `${MODULE}user_tags`,
  USER_TABS: `${MODULE}user_tabs`,
  USER_SETTINGS_VIDEO: `${MODULE}user_settings_video`,
  USER_SETTINGS_PERLENSCHNUR: `${MODULE}user_settings_perlenschnur`,
  USER_SETTINGS_RABATT: `${MODULE}user_settings_rabatt`,
  USER_HAS_ROLE: `${MODULE}user_has_role`,
  USER_HAS_MIN_ONE_OF_ROLES: `${MODULE}user_has_min_one_of_roles`,
  USER_IS_FROM_BBH: `${MODULE}user_is_from_bbh`,
  CLIENT_USERS: `${MODULE}client_users`,
  CURRENT_CLIENT: `${MODULE}current_client`,
};

export default GETTERS;
