import { authWrapper } from '@src/services/axios';
import ElementFactory from '@src/utils/ElementFactory';
import { isEmptyOrNullish } from '@st/utils-js';
import moment from 'moment';
import actions from '../types/action-types';

import EditorElement from '../../../../model/EditorElement';
import ElementStatus from '../../../../model/enums/ElementStatus';
import ElementTyp from '../../../../model/enums/ElementTyp';
import services from '../../../../services';
import types from '../../../types';

const viewContentMutations = types.mutations.viewContent;
const editorMutations = types.mutations.editor;

export default {
  [actions.FETCH_RELATIONS]({ commit }, { elements }) {
    const filteredElements = Object.keys(elements).reduce((accu, key) => {
      const element = elements[key];
      if (element.type === ElementTyp.KLAUSEL) {
        accu[key] = element;
      }
      return accu;
    }, {});

    const prm = new Promise((resolve, reject) => {
      services.editor.relations
        .fetchAllRelationsByElements(filteredElements)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            'Beziehungen konnten nicht geladen werden.',
          );
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.FETCH_ELEMENT]({ commit, dispatch }, { elementId, musterdokumentId = null }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.contract.getElement(elementId)
        .then(async (response) => {
          let relations = {};

          if (fetchedElementIsTypeKlausel(response.data)) {
            try {
              relations = await dispatch(actions.FETCH_RELATIONS, {
                elements: response.data.elements,
              });
            } catch (error) {
              console.log(error);
            }
          }

          const idForSimilarities = musterdokumentId || elementId;

          const similaritiesResponse = await services.editor.element.fetchSimilarities(
            idForSimilarities,
          );

          const similarities = similaritiesResponse?.data?.result.reduce((acc, cv) => {
            if (!acc[cv.sectionId]) {
              acc[cv.sectionId] = [];
            }
            acc[cv.sectionId].push(cv);
            return acc;
          }, {});

          checkIfDocIsInContract(response.data, elementId, musterdokumentId);

          const tmpEditorElement = EditorElement.fromJSON({
            ...response.data,
            relations,
            similarities,
          }, musterdokumentId || elementId);

          const tmpEntry = ElementFactory.elementFromJSON(
            tmpEditorElement.editorElement,
            tmpEditorElement.elements,
            tmpEditorElement.elementRevisions[tmpEditorElement.editorElement],
            tmpEditorElement.similarities,
          );

          let tmpResponse = tmpEntry;
          if (!!musterdokumentId && ElementTyp.isMustervertrag(tmpResponse.type)) {
            const subelementFound = tmpEntry.content.some((doc) => doc.id === musterdokumentId);
            if (subelementFound) {
              tmpResponse = ElementFactory.elementFromJSON(
                musterdokumentId,
                tmpEditorElement.elements,
                tmpEditorElement.elementRevisions[musterdokumentId],
                tmpEditorElement.similarities,
              );
            } else {
              const error = new Error('Dokument ist nicht im Vertrag');
              error.response = {
                status: 404,
              };
              reject(error);
              return;
            }
          }
          commit(editorMutations.SET_CURRENT_DOC_ID, { currentDocId: tmpResponse.id });
          commit(editorMutations.SET_ELEMENT, { element: tmpEditorElement });

          resolve({ element: tmpResponse, editorElement: tmpEditorElement });
        })
        .catch((error) => {
          if (error.message) {
            commit(
              `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
              error.message,
            );
            reject(error);
            return;
          }
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            'Vertrag / Dokument ist nicht valide',
          );
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },
  [actions.FETCH_MUSTERVERTRAG]({ commit }, { elementId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.contract.getElement(elementId)
        .then(async (response) => {
          const tmpEditorElement = EditorElement.fromJSON({
            ...response.data,
          }, elementId);

          const tmpEntry = ElementFactory.elementFromJSON(
            tmpEditorElement.editorElement,
            tmpEditorElement.elements,
            tmpEditorElement.elementRevisions[tmpEditorElement.editorElement],
            tmpEditorElement.similarities,
          );

          if (!ElementTyp.isMustervertrag(tmpEntry.type)) {
            commit(
              `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
              'Das Element ist kein Mustervertrag',
            );
            return;
          }
          commit(editorMutations.SET_ELEMENT, { element: tmpEditorElement });

          resolve({ element: tmpEntry, editorElement: tmpEditorElement });
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            'Der Vertrag ist nicht valide',
          );
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },
  [actions.FETCH_ELEMENT_AND_RETURN]({ commit }, { elementId, musterdokumentId = null }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.contract.getElement(elementId)
        .then(async (response) => {
          const tmpEditorElement = EditorElement.fromJSON(
            { ...response.data },
            musterdokumentId || elementId,
          );
          const tmpEntry = ElementFactory.elementFromJSON(
            tmpEditorElement.editorElement,
            tmpEditorElement.elements,
            tmpEditorElement.elementRevisions[tmpEditorElement.editorElement],
            tmpEditorElement.similarities,
          );

          let tmpResponse = tmpEntry;
          if (!!musterdokumentId && ElementTyp.isMustervertrag(tmpResponse.type)) {
            const subelementFound = tmpEntry.content.some((doc) => doc.id === musterdokumentId);
            if (subelementFound) {
              tmpResponse = ElementFactory.elementFromJSON(
                musterdokumentId,
                tmpEditorElement.elements,
                tmpEditorElement.elementRevisions[musterdokumentId],
                tmpEditorElement.similarities,
              );
            } else {
              commit(
                `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
                'Dokument ist nicht im Vertrag',
              );
            }
          }
          resolve({ element: tmpResponse, editorElement: tmpEditorElement });
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            'Vertrag ist nicht valide',
          );
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.UPDATE_COMPARE_ELEMENT]({ commit }, elementId) {
    const prm = new Promise((resolve, reject) => {
      services.editor.element.fetch(elementId)
        .then((response) => {
          const id = response.data.editorElement;
          commit(editorMutations.UPDATE_COMPARE_ELEMENT, {
            ...response.data.elements[id],
            id,
            elements: {
              ...response.data.elements,
            },
            revision: response.data.elementRevisions[id],
            patchTag: response.data.elementPatchTags[id],
          });
          resolve(response.data);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.PATCH_CREATE_DESCENDANT]({ commit }, { elementId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.internUpdate.createDescendant(elementId)
        .then((result) => {
          const newElement = result.data;
          if (ElementTyp.isMustervertrag(newElement.type)) {
            const mappedContract = mapNewContract(newElement);
            commit(viewContentMutations.ADD_MUSTERVERTRAG, { contract: mappedContract });
            resolve(mappedContract);
          } else {
            const { editorElement: id, elements, elementRevisions } = newElement;
            const { type } = elements[id];
            resolve({
              type, id, element: elements[id], elementRevisions,
            });
          }
          commit(viewContentMutations.SHOW_SNACKBAR_SUCCESS, 'Nachfolger wird geöffnet');
        })
        .catch((error) => {
          if (error.response?.status === 412 && !isEmptyOrNullish(error.response.data)) {
            const newElement = error.response.data;
            if (ElementTyp.isMustervertrag(newElement.type)) {
              const mappedContract = mapNewContract(newElement);
              resolve(mappedContract);
            } else {
              const { editorElement: id, elements, elementRevisions } = newElement;
              const { type } = elements[id];
              resolve({
                type, id, element: elements[id], elementRevisions,
              });
            }
            commit(viewContentMutations.SHOW_SNACKBAR_ERROR, 'Nachfolger existiert bereits und wird geöffnet');
            return;
          }
          if (error.response?.status === 409) {
            commit(viewContentMutations.SHOW_SNACKBAR_ERROR, 'Element ist noch nicht freigegeben');
            reject(error);
            return;
          }

          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.PATCH_REPLACE_WITH_NEWEST_APPROVED_VERSION]({ commit }, { elementId }) {
    const approvalState = this.state.editor.element.elements[elementId].state;
    if (!ElementStatus.isApproved(approvalState)) {
      commit(viewContentMutations.SHOW_SNACKBAR_ERROR, 'Element ist noch nicht freigegeben');
      return Promise.resolve();
    }

    const prm = new Promise((resolve, reject) => {
      services.editor.internUpdate.replaceWithNewestApprovedVersion(elementId)
        .then((result) => {
          const newElement = result.data;
          if (ElementTyp.isMustervertrag(newElement.type)) {
            const mappedContract = mapNewContract(newElement);
            commit(viewContentMutations.ADD_MUSTERVERTRAG, { contract: mappedContract });
            resolve(mappedContract);
          } else {
            const { editorElement: id, elements, elementRevisions } = newElement;
            const { type } = elements[id];
            resolve({
              type, id, element: elements[id], elementRevisions,
            });
          }
          commit(viewContentMutations.SHOW_SNACKBAR_SUCCESS, 'Nachfolger wird geöffnet');
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            commit(viewContentMutations.SHOW_SNACKBAR_ERROR, 'Das aktuelle Element ist bereits die neueste freigegebene Version');
            resolve(null);
            return;
          }

          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.PATCH_CREATE_SUB_ELEMENT_DESCENDANT]({ commit, state }, {
    elementId, subElementRefPathId, activeUser,
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.internUpdate.createSubElementDescendant(elementId, subElementRefPathId)
        .then((result) => {
          const newElement = result.data;

          commit(viewContentMutations.REPLACE_SUB_ELEMENT_WITH_DESCENDANT, {
            parentElementId: elementId,
            subElementRefPathId,
            newElement,
            activeUser,
          });
          commit(editorMutations.UPDATE_ELEMENT_REVISIONS_ARRAY, {
            revisionNr: state.elementRevisions.at(0).revisionNr + 1,
          });

          resolve(newElement);
          commit(viewContentMutations.SHOW_SNACKBAR_SUCCESS, 'Nachfolger wurde erstellt');
        })
        .catch((error) => {
          if (error.response?.status === 412 && !isEmptyOrNullish(error.response.data)) {
            const newElement = error.response.data;
            const { editorElement: id, elements } = newElement;
            const { type } = elements[id];

            commit(viewContentMutations.REPLACE_SUB_ELEMENT_WITH_DESCENDANT, {
              parentElementId: elementId,
              subElementRefPathId,
              newElement,
              activeUser,
            });
            commit(editorMutations.UPDATE_ELEMENT_REVISIONS_ARRAY, {
              revisionNr: state.elementRevisions.at(0).revisionNr + 1,
            });

            resolve({ type, id });
            commit(viewContentMutations.SHOW_SNACKBAR_ERROR, 'Nachfolger existiert bereits');
            return;
          }
          if (error.response?.status === 409) {
            commit(viewContentMutations.SHOW_SNACKBAR_ERROR, 'Element ist noch nicht freigegeben');
            reject(error);
            return;
          }

          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.CHECK_IF_INTERNAL_REFERENCE_EXISTS]({ state, commit }) {
    const elementId = state.currentDocId;
    const element = state.element.elements[elementId];

    if (!element.internalReference && !ElementStatus.isApproved(element.state)) {
      commit(editorMutations.ADD_INTERNAL_REFERENCE_TO_CURRENT_DOC);
      return false;
    }
    return true;
  },

  [actions.CHECK_IF_ELEMENT_REFERENCE_EXISTS]({ state, commit }) {
    const editorElementId = state.element.editorElement;
    const element = state.element.elements[editorElementId];

    if (!ElementTyp.isMustervertrag(element.type)) return null;

    if (!element.attrs.elementReference && !ElementStatus.isApproved(element.state)) {
      commit(editorMutations.ADD_ELEMENT_REFERENCE_TO_ELEMENT);
      return false;
    }
    return true;
  },

  [actions.FETCH_REVISIONS]({ commit }, { elementId, wantedPatchTags }) {
    function getRevisionName(milliseconds) {
      return moment(milliseconds, 'x').format('DD.MM.YYYY, HH:mm:ss[Uhr]');
    }

    const prm = new Promise((resolve, reject) => {
      services.editor.revisions.fetchRevisions(elementId, wantedPatchTags)
        .then((response) => {
          const localRevisions = response.data.result
            .reverse()
            .map((revision) => ({
              name: getRevisionName(revision.created),
              revisionNr: revision.revision,
            }));

          commit(editorMutations.SET_ELEMENT_REVISIONS, { revisions: localRevisions });
          resolve(response.data);
        })
        .catch((error) => {
          commit(viewContentMutations.SHOW_SNACKBAR_SUCCESS, 'Fehler beim Laden der Revisionen');

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.DELETE_ELEMENT]({ commit }, { elementId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.element.delete(elementId)
        .then((response) => {
          if (response === null) {
            resolve(null);
            return;
          }
          commit(viewContentMutations.SHOW_SNACKBAR_SUCCESS, 'Element wurde gelöscht');
          resolve(response);
        })
        .catch((error) => {
          if (error.response?.status === 409) {
            commit(viewContentMutations.SHOW_SNACKBAR_ERROR, 'Element ist noch in Verwendung');
            resolve(false);
            return;
          }
          if (error.response?.status === 404) {
            commit(viewContentMutations.SHOW_SNACKBAR_ERROR, 'Das Element existiert nicht (mehr)');
            resolve(false);
            return;
          }
          if (error.response?.status === 403) {
            commit(viewContentMutations.SHOW_SNACKBAR_ERROR, 'Das Element wurde bereits freigegeben');
            resolve(false);
            return;
          }
          commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },
};

// Helper

export function mapNewContract(newContract) {
  const mappedContract = { ...newContract };
  mappedContract.responsibilities = newContract.responsibilities?.reduce((obj, cv) => {
    const tmpObj = { ...obj };
    const key = Object.keys(cv)[0];
    const value = cv[key];
    tmpObj[key] = value;
    return tmpObj;
  }, {});

  return mappedContract;
}

export function fetchedElementIsTypeKlausel(editorElement) {
  const id = editorElement.editorElement;
  return ElementTyp.isKlausel(editorElement.elements[id].type);
}

export function checkIfDocIsInContract(data, contractId, docId) {
  const elemId = data.editorElement;
  if (elemId === contractId && !docId) {
    const mainElement = data.elements[elemId];
    if (ElementTyp.isMustervertrag(mainElement.type)) {
      const error = new Error('Ein Vertrag kann nicht im Editor geöffnet werden');
      error.response = {
        status: 415,
      };
      throw error;
    } else return true;
  }

  const mainElement = data.elements[elemId];
  if (!ElementTyp.isMustervertrag(mainElement.type)) {
    const error = new Error('Das Element ist kein Vertrag');
    error.response = {
      status: 400,
    };
    throw error;
  }

  if (!mainElement.content.some((doc) => doc.id === docId)) {
    if (!data.elements[docId]) {
      const error = new Error('Das Dokument ist nicht im Vertrag');
      error.response = {
        status: 404,
      };
      throw error;
    }
  }

  const docElement = data.elements[docId];

  if (!ElementTyp.isMusterdokument(docElement.type)) {
    const error = new Error('Das Element ist kein Dokument im Vertrag');
    error.response = {
      status: 404,
    };
    throw error;
  }

  return true;
}
