import { Node, DOMSerializer } from 'prosemirror-model';
import { isJSON } from '@st/utils-js';
import { validate } from '../utils/uuid';
import Schema from '../components/prosemirror/schemas/TextContentSchema';

export default class MandantDokumentElementSubelementSections {
  /**
   * @param {Array} order Array of sectionIds
   * @param {JSON} contents Library of section Objects
   * @param {JSON} originMap Mapping of sectionIds and the refPath in Musterdokument
   */
  constructor(
    order = [],
    contents = {},
    originMap = {},
  ) {
    this.order = order;
    this.contents = contents;
    this.originMap = originMap;
  }

  /**
   *
   * @param {Number} positionIndex
   * @param {String} id
   * @param {JSON} section
   * @param {JSON} origin
   * @returns
   */
  addSection(positionIndex, id, section, origin) {
    const validPositionIndex = positionIndex !== undefined
      && typeof positionIndex === 'number'
      && positionIndex >= 0
      && positionIndex <= this.order.length;

    const validId = validate(id);

    const validSection = isJSON(section)
      && isJSON(section.attrs)
      && Array.isArray(section.content)
      && typeof section.type === 'string';

    if (!validPositionIndex
      || !validId
      || !validSection) return;

    const sectionNode = {
      attrs: section.attrs,
      comments: section.comments,
      content: section.content,
      type: section.attrs.type,
    };

    this.contents[id] = sectionNode;
    this.order.splice(positionIndex, 0, id);
    if (isJSON(origin)) {
      this.originMap[id] = origin;
    }
  }

  /**
   *
   * @param {Number} index
   * @param {String} id
   */
  removeSection(id) {
    if (!validate(id)) return;
    if (!this.order.includes(id)) return;

    delete this.contents[id];
    const index = this.order.indexOf(id);
    this.order.splice(index, 1);
    delete this.originMap[id];
  }

  /**
   * Creates MandantDokumentElementSubelementSections from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      order = [],
      contents = {},
      originMap = {},
    } = value;

    return new MandantDokumentElementSubelementSections(
      order,
      contents,
      originMap,
    );
  }

  /**
   * @returns MandantDokumentElementSubelementSections in JSON representation
   */
  toJSON() {
    return {
      order: this.order,
      contents: this.contents,
      originMap: this.originMap,
    };
  }

  get sections() {
    const orderExists = Object.keys(this).includes('order');
    const contentsExists = Object.keys(this).includes('contents');
    if (!orderExists || !contentsExists) return [];

    const originMapExists = Object.keys(this).includes('originMap');

    return this.order
      .filter((sectionId) => !!this.contents[sectionId])
      .map((sectionId) => {
        const section = this.contents[sectionId];

        let origin = {};
        if (originMapExists) {
          origin = this.originMap[sectionId];
        }

        const attrs = { ...section.attrs, id: sectionId };

        const preparedSection = { ...section };

        if (Array.isArray(attrs.comments) && Array.isArray(preparedSection.comments)) {
          attrs.comments = attrs.comments
            .concat(preparedSection.comments)
            .filter((comment, index, comments) => comments
              .map((c) => `${c.from}${c.to}${c.type}`)
              .indexOf(`${comment.from}${comment.to}${comment.type}`)
            === index);
        } else if (!Array.isArray(attrs.comments) && Array.isArray(preparedSection.comments)) {
          attrs.comments = preparedSection.comments;
        }

        preparedSection.attrs = attrs;

        return {
          ...preparedSection,
          origin,
          sectionId,
        };
      });
  }

  get textContent() {
    const textContentObj = {};

    // eslint-disable-next-line consistent-return
    Object.keys(this.contents).forEach((id) => {
      const serializer = DOMSerializer.fromSchema(Schema);
      let textContent = '';

      const { content } = this.contents[id];

      for (let i = 0; i < content.length; i += 1) {
        const subelement = content[i];

        const SUBELEMENT_NODE = Node.fromJSON(Schema, subelement);

        if (subelement.type === 'table') {
          let tableTextContent = '';
          SUBELEMENT_NODE.descendants((child) => {
            if (child.type.name === 'table_cell' || child.type.name === 'table_header') {
              tableTextContent += `${child.textContent} `;
            }
          });
          textContent += `${tableTextContent}\n`;
        } else {
          const DOM_NODES = serializer.serializeNode(SUBELEMENT_NODE);
          textContent += `${DOM_NODES.textContent}\n`;
        }
      }

      textContentObj[id] = textContent.trim();
    });

    return textContentObj;
  }

  get validOrder() {
    return Array.isArray(this.order)
      && this.order.length > 0
      && this.order.every((sectionId) => validate(sectionId));
  }

  get validContents() {
    return isJSON(this.contents)
      && Object.keys(this.contents).length > 0
      && Object.keys(this.contents)
        .every((sectionId) => validate(sectionId))
      && Object.values(this.contents)
        .every((section) => isJSON(section) && Object.keys(section).length > 0);
  }

  get validOriginMap() {
    return isJSON(this.originMap)
    && Object.keys(this.originMap).every((sectionId) => validate(sectionId))
    && Object.values(this.originMap)
      .every((section) => isJSON(section)
        && [
          'refPath', 'modified',
        ].every((key) => Object.keys(section).includes(key))
        && typeof section.modified === 'boolean'
        && section.refPath
        && Array.isArray(section.refPath)
        && section.refPath.every((id) => validate(id)));
  }

  get valid() {
    const valid = this.validOrder
      && this.validContents
      && this.validOriginMap;

    return valid;
  }
}
