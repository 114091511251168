export default class FreigabeChecklistElement {
  constructor(
    value = {},
  ) {
    this.value = value;
  }

  update(oldRevision, newRevision) {
    if (this.value[oldRevision]) {
      this.value[newRevision] = this.value[oldRevision];
    }
  }

  updateNewestRevision(newRevision) {
    if (this.newestRevision) {
      this.update(this.newestRevision, newRevision);
    }
  }

  addChecked(revision) {
    const date = new Date();
    this.value[revision] = date.getTime();
  }

  removeChecked(revision) {
    if (!this.value[revision]) return;
    delete this.value[revision];
  }

  hasRevision(revision) {
    return Object.keys(this.value).includes(JSON.stringify(revision));
  }

  get newestRevision() {
    return Math.max(...Object.keys(this.value));
  }

  static fromJSON(value) {
    return new FreigabeChecklistElement(value);
  }

  toJSON() {
    return { ...this.value };
  }

  validValue() {
    return Object.keys(this.value)
      .every((revision) => !Number.isNaN(Number(revision))
    && Object.values(this.value)
      .every((value) => typeof value === 'number'));
  }
}
