import store from '../store';
import types from '../store/types';

const userGetters = types.getters.user;

export default function guard(to, from, next) {
  let requiredRoles;
  const needsAuth = to.matched.reverse().some((record) => {
    if (record.meta?.requiresAuth?.length > 0) {
      requiredRoles = record.meta.requiresAuth;
      return true;
    }
    return false;
  });
  if (needsAuth) {
    const userHasRequiredRole = store.getters[userGetters.USER_HAS_MIN_ONE_OF_ROLES](requiredRoles);
    if (!userHasRequiredRole) {
      if (from.name === null) {
        if (to.name?.length > 0) {
          next(
            { name: 'Home' },
          );
        }
      } else if (from.name?.length > 0) {
        next(
          { name: from.name },
        );
      } else {
        next(
          { name: 'Home' },
        );
      }
    } else next();
  } else next();
}
