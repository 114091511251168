import { isJSON } from '@st/utils-js';
import { validate } from '../utils/uuid';
import ElementAttrs from './ElementAttrs';
import AbschnittKlauselTransformationCheckliste from './AbschnittKlauselTransformationCheckliste';
import Responsibilities from './Responsibilities';
import UserRolle from './enums/UserRolle';

const REQUIRED_ROLES = [
  UserRolle.PARTNER,
  UserRolle.ADMINPARTNER,
  UserRolle.RECHTSANWALT,
];
export default class KlauselElementAttrs extends ElementAttrs {
  /**
   *
   * @param {String} name
   * @param {Array} hashtags Array of UUIDs
   * @param {Responsibilities} responsibilities
   * @param {AbschnittKlauselTransformationCheckliste
   *  | UeberschriftKlauselTransformationCheckliste} clauseTransformation
   */
  constructor(
    name = '',
    hashtags = [],
    responsibilities = new Responsibilities(),
    clauseTransformation = new AbschnittKlauselTransformationCheckliste(),
  ) {
    super(name, hashtags, responsibilities, REQUIRED_ROLES);
    this.clauseTransformation = clauseTransformation;
  }

  /**
   * Creates KlauselElementAttrs from JSON.
   * If keys are missing then it will use the default values.
   * value need the key 'type' to generate the right clauseTransformation
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const { clauseTransformation } = value;
    let localClauseTransformation = null;
    if (isJSON(clauseTransformation)) {
      localClauseTransformation = AbschnittKlauselTransformationCheckliste
        .fromJSON(clauseTransformation);
    } else {
      localClauseTransformation = new AbschnittKlauselTransformationCheckliste();
    }

    const {
      name = '',
      hashtags = [],
      responsibilities = new Responsibilities(),
    } = value;

    let res = responsibilities;
    if (!(res instanceof Responsibilities) && isJSON(res)) {
      res = Responsibilities.fromJSON(res);
    }

    return new KlauselElementAttrs(name, hashtags, res, localClauseTransformation);
  }

  /**
   * @returns KlauselElementAttrs in JSON representation
   */
  toJSON() {
    let responsibilities = {};
    if (this.responsibilities instanceof Responsibilities) {
      responsibilities = this.responsibilities.toJSON();
    } else {
      responsibilities = this.responsibilities;
    }

    return {
      name: this.name,
      hashtags: this.hashtags,
      responsibilities,
      clauseTransformation: this.clauseTransformation.toJSON(),
    };
  }

  get validClauseTransformation() {
    return !!this.clauseTransformation
      && this.clauseTransformation instanceof AbschnittKlauselTransformationCheckliste
      && this.clauseTransformation.valid;
  }

  get validResponsibilities() {
    return !!this.responsibilities
      && this.responsibilities instanceof Responsibilities
      && validate(this.responsibilities[UserRolle.RECHTSANWALT])
      && validate(this.responsibilities[UserRolle.ADMINPARTNER])
      && validate(this.responsibilities[UserRolle.PARTNER])
      && !this.responsibilities[UserRolle.PRODUKTKOORDINATOR]
      && !this.responsibilities[UserRolle.MANDANT]
      && !this.responsibilities[UserRolle.SACHBEARBEITER];
  }

  get valid() {
    const { validName, validHashtags, validResponsibilities } = this;

    const valid = validName
      && validHashtags
      && validResponsibilities
      && this.validClauseTransformation;

    return valid;
  }
}
