import { isEmpty, isJSON } from '@st/utils-js';
import FreigabeStatus from './enums/FreigabeStatus';
import { validate } from '../utils/uuid';
import KeycloakUser from './KeycloakUser';
import UpdateType from './enums/UpdateType';

export default class MandantDokumentVertrag {
  /**
     * @param {String} clientElementId
     * @param {String} name
     * @param {String} notizen
     * @param {FreigabeStatus} freigabeStatus
     * @param {Number} created
     * @param {Boolean} modified
     * @param {String} vertragId
     * @param {Number} vertragRevision
     * @param {String} elementId
     * @param {String} keycloakUserUuid
     * @param {Boolean} updateAbgeschlossen
     * @param {Boolean} textUpdate
     * @param {Boolean} update
     * @param {String} updateType
     * @param {Number} revision
     * @param {Array} tags
     * @param {Number} tagCount
     * @param {String} tagString
     * @param {Object} bbhResponsibility
     * @param {Array} externResponsibilities
     * @param {Array} latestPatchDetails
     * @param {Array} subElements
     * @param {Array} clientElementNachfolger
     * @param {Array} clientElementVorgaenger
   */
  constructor(
    clientElementId = null,
    name = '',
    notizen = '',
    freigabeStatus = FreigabeStatus.MODIFIABLE,
    created = 0,
    modified = null,
    vertragId = null,
    vertragRevision = null,
    elementId = null,
    keycloakUserUuid = null,
    updateAbgeschlossen = null,
    textUpdate = null,
    update = false,
    updateType = null,
    revision = 0,
    tags = [],
    tagCount = 0,
    tagString = '',
    bbhResponsibility = new KeycloakUser(),
    externResponsibilities = [],
    latestPatchDetails = [],
    subElements = [],
    clientElementNachfolger = [],
    clientElementVorgaenger = [],
  ) {
    this.clientElementId = clientElementId;
    this.name = name;
    this.notizen = notizen;
    this.freigabeStatus = freigabeStatus;
    this.created = created;
    this.modified = modified;
    this.vertragId = vertragId;
    this.vertragRevision = vertragRevision;
    this.elementId = elementId;
    this.keycloakUserUuid = keycloakUserUuid;
    this.updateAbgeschlossen = updateAbgeschlossen;
    this.textUpdate = textUpdate;
    this.update = update;
    this.updateType = updateType;
    this.revision = revision;
    this.tags = tags;
    this.tagCount = tagCount;
    this.tagString = tagString;
    this.bbhResponsibility = bbhResponsibility;
    this.externResponsibilities = externResponsibilities;
    this.latestPatchDetails = latestPatchDetails;
    this.subElements = subElements;
    this.clientElementNachfolger = clientElementNachfolger;
    this.clientElementVorgaenger = clientElementVorgaenger;
  }

  /**
   * Creates MandantDokumentVertrag from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    let freigabeStatus = FreigabeStatus.MODIFIABLE;
    if (FreigabeStatus.isValidType(value.freigabeStatus)) {
      freigabeStatus = value.freigabeStatus;
    }

    let bbhResponsibility = new KeycloakUser();
    if (value.bbhResponsibility instanceof KeycloakUser) {
      bbhResponsibility = value.bbhResponsibility;
    } else if (isJSON(value.bbhResponsibility)) {
      bbhResponsibility = KeycloakUser.fromJSON(value.bbhResponsibility);
    }

    let externResponsibilities = [];
    if (Array.isArray(value.externResponsibilities)) {
      externResponsibilities = value.externResponsibilities.map((externResponsibility) => {
        if (externResponsibility instanceof KeycloakUser) {
          return externResponsibility;
        }
        return KeycloakUser.fromJSON(externResponsibility);
      });
    }

    const {
      clientElementId = null,
      name = '',
      notizen = '',
      created = 0,
      modified = null,
      vertragId = null,
      vertragRevision = null,
      elementId = null,
      keycloakUserUuid = null,
      updateAbgeschlossen = null,
      textUpdate = null,
      update = false,
      updateType = null,
      revision = 0,
      tags = [],
      tagCount = 0,
      tagString = '',
      latestPatchDetails = [],
      subElements = [],
      clientElementNachfolger = [],
      clientElementVorgaenger = [],
    } = value;

    return new MandantDokumentVertrag(
      clientElementId,
      name,
      notizen,
      freigabeStatus,
      created,
      modified,
      vertragId,
      vertragRevision,
      elementId,
      keycloakUserUuid,
      updateAbgeschlossen,
      textUpdate,
      update,
      updateType,
      revision,
      tags,
      tagCount,
      tagString,
      bbhResponsibility,
      externResponsibilities,
      latestPatchDetails,
      subElements,
      clientElementNachfolger,
      clientElementVorgaenger,
    );
  }

  /**
   * @returns MandantDokumentVertrag in JSON representation
   */
  toJSON() {
    return {
      clientElementId: this.clientElementId,
      name: this.name,
      notizen: this.notizen,
      freigabeStatus: this.freigabeStatus,
      created: this.created,
      modified: this.modified,
      vertragId: this.vertragId,
      vertragRevision: this.vertragRevision,
      elementId: this.elementId,
      keycloakUserUuid: this.keycloakUserUuid,
      updateAbgeschlossen: this.updateAbgeschlossen,
      textUpdate: this.textUpdate,
      update: this.update,
      updateType: this.updateType,
      revision: this.revision,
      tags: this.tags,
      tagCount: this.tagCount,
      tagString: this.tagString,
      bbhResponsibility: this.bbhResponsibility instanceof KeycloakUser
        ? this.bbhResponsibility.toJSON()
        : this.bbhResponsibility,
      externResponsibilities: this.externResponsibilities,
      latestPatchDetails: this.latestPatchDetails,
      subElements: this.subElements,
      clientElementNachfolger: this.clientElementNachfolger,
      clientElementVorgaenger: this.clientElementVorgaenger,
    };
  }

  get validClientElementId() {
    return !!this.clientElementId
      && typeof this.clientElementId === 'string'
      && validate(this.clientElementId);
  }

  get validName() {
    return typeof this.name === 'string'
      && !isEmpty(this.name);
  }

  get validNotizen() {
    return typeof this.notizen === 'string';
  }

  get validFreigabeStatus() {
    return FreigabeStatus.isValidType(this.freigabeStatus);
  }

  get validCreated() {
    return typeof this.created === 'number'
      && this.created >= 0;
  }

  get validModified() {
    return this.modified === null
    || (typeof this.modified === 'number'
      && this.modified >= 0);
  }

  get validVertragId() {
    return this.vertragId === null
      || (!!this.vertragId
      && typeof this.vertragId === 'string'
      && validate(this.vertragId));
  }

  get validVertragRevision() {
    return this.vertragRevision === null
      || (typeof this.vertragRevision === 'number'
      && this.vertragRevision >= 0);
  }

  get validElementId() {
    return this.elementId === null
      || (!!this.elementId
      && typeof this.elementId === 'string'
      && validate(this.elementId));
  }

  get validKeycloakUserUuid() {
    return this.keycloakUserUuid === null
      || (!!this.keycloakUserUuid
        && typeof this.keycloakUserUuid === 'string'
        && validate(this.keycloakUserUuid));
  }

  get validUpdateAbgeschlossen() {
    return this.updateAbgeschlossen === null
      || typeof this.updateAbgeschlossen === 'boolean';
  }

  get validTextUpdate() {
    return this.textUpdate === null
      || [
        0, 1, true, false,
      ].includes(this.textUpdate);
  }

  get validUpdate() {
    return typeof this.update === 'boolean';
  }

  get validUpdateType() {
    return UpdateType.isValidType(this.updateType) || this.updateType === null;
  }

  get validRevision() {
    return typeof this.revision === 'number'
      && this.revision >= 0;
  }

  get validTags() {
    return Array.isArray(this.tags);
  }

  get validTagCount() {
    return typeof this.tagCount === 'number'
      && this.tagCount >= 0;
  }

  get validTagString() {
    return typeof this.tagString === 'string';
  }

  get validBbhResponsibility() {
    return this.bbhResponsibility instanceof KeycloakUser
        && this.bbhResponsibility.validEmpty;
  }

  get validExternResponsibilities() {
    return Array.isArray(this.externResponsibilities)
      && this.externResponsibilities.every(
        (externResponsibility) => externResponsibility instanceof KeycloakUser
          && externResponsibility.valid,
      );
  }

  get validLatestPatchDetails() {
    return Array.isArray(this.latestPatchDetails);
  }

  get validSubElements() {
    return Array.isArray(this.subElements);
  }

  get validClientElementNachfolger() {
    return Array.isArray(this.clientElementNachfolger);
  }

  get validClientElementVorgaenger() {
    return Array.isArray(this.clientElementVorgaenger);
  }

  /**
   * @returns true if all values are valid
   * @returns false if one or more values are invalid
   * @returns undefined if not all values are set
   */
  get valid() {
    return this.validClientElementId
      && this.validName
      && this.validNotizen
      && this.validFreigabeStatus
      && this.validCreated
      && this.validModified
      && this.validVertragId
      && this.validVertragRevision
      && this.validElementId
      && this.validKeycloakUserUuid
      && this.validUpdateAbgeschlossen
      && this.validTextUpdate
      && this.validRevision
      && this.validUpdate
      && this.validUpdateType
      && this.validTags
      && this.validTagCount
      && this.validTagString
      && this.validBbhResponsibility
      && this.validExternResponsibilities
      && this.validLatestPatchDetails
      && this.validSubElements
      && this.validClientElementNachfolger
      && this.validClientElementVorgaenger;
  }
}
