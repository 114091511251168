<template>
  <div
    id="snackbar-wrapper"
    name="snackbars"
  >
    <bbh-snackbar
      :trigger-snackbar="show"
      :type="type"
      :timeout="timeout"
      @close:snackbar="show = false"
    >
      {{ text }}
    </bbh-snackbar>
  </div>
</template>

<script>
import types from '../../store/types';

const viewContentMutations = types.mutations.viewContent;

export default {
  name: 'Snackbar',
  data: () => ({
    show: false,
    type: 'info',
    text: '',
    timeout: '-1',
  }),
  created() {
    this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === viewContentMutations.SHOW_SNACKBAR
        || mutation.type === viewContentMutations.SHOW_SNACKBAR_SUCCESS
        || mutation.type === viewContentMutations.SHOW_SNACKBAR_INFO
        || mutation.type === viewContentMutations.SHOW_SNACKBAR_ERROR
        || mutation.type === viewContentMutations.SHOW_SNACKBAR_WARNING
      ) {
        this.text = state.viewContent.snackbar.text;
        this.type = state.viewContent.snackbar.type;
        this.timeout = state.viewContent.snackbar.timeout;
        this.show = true;
      }
    });
  },
};
</script>

<style>
#snackbar-wrapper {
  height: 0
}
</style>
