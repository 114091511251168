import config from './config';
import ElementType from '../../model/enums/ElementTyp';
import { get } from '../axios';

const { API_PREFIX } = config;

export default {
  fetchAllRelationsByElements(elements) {
    return new Promise((resolve, reject) => {
      const relationPromises = Object.keys(elements)
        .map((id) => {
          const { type } = elements[id];
          return this.fetch(type, id);
        });

      Promise.all(relationPromises)
        .then((relationResponses) => {
          const relations = {};
          relationResponses.forEach((r) => {
            const eid = r.config.url.split('/').reverse()[1];
            relations[eid] = r.data.results;
          });
          resolve(relations);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetch(type, elementId) {
    const URL = `${API_PREFIX}/relation/${type}/${elementId}/`;

    return new Promise((resolve, reject) => {
      if (!type || !elementId) {
        reject(new Error('input is not set'));
      }
      if (
        ![
          ElementType.KLAUSEL,
          ElementType.MUSTERDOKUMENT,
          ElementType.BAUSTEIN,
        ].includes(type)
      ) {
        reject(new Error('elementType is not valid'));
      }

      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * suche Komplexe Klauseln
   *
   * @param {string} elementId
   */
  fetchComplexClauses(elementId) {
    const URL = `${API_PREFIX}/relation/${elementId}/sucheKomplexeKlausel`;

    return get(URL);
  },

};
