export default class UserRolle {
  static RECHTSANWALT = 'RECHTSANWALT';

  static PARTNER = 'PARTNER';

  static ADMINPARTNER = 'ADMIN_PARTNER';

  static PRODUKTKOORDINATOR = 'PRODUKTKOORDINATOR';

  static MANDANT = 'MANDANT';

  static SACHBEARBEITER = 'SACHBEARBEITER';

  static MANDANTENFREIGABE = 'MANDANTENFREIGABE';

  static PRODUKTFREIGABE = 'PRODUKTFREIGABE';

  static ROLES = [
    this.RECHTSANWALT,
    this.PARTNER,
    this.ADMINPARTNER,
    this.PRODUKTKOORDINATOR,
    this.MANDANT,
    this.SACHBEARBEITER,
    this.MANDANTENFREIGABE,
    this.PRODUKTFREIGABE,
  ];

  static isRechtsanwalt(role) {
    return role === this.RECHTSANWALT;
  }

  static isPartner(role) {
    return role === this.PARTNER;
  }

  static isAdminpartner(role) {
    return role === this.ADMINPARTNER;
  }

  static isProduktkoordinator(role) {
    return role === this.PRODUKTKOORDINATOR;
  }

  static isMandant(role) {
    return role === this.MANDANT;
  }

  static isSachbearbeiter(role) {
    return role === this.SACHBEARBEITER;
  }

  static isMandantenfreigabe(role) {
    return role === this.MANDANTENFREIGABE;
  }

  static isProduktfreigabe(role) {
    return role === this.PRODUKTFREIGABE;
  }

  static isValid(state) {
    return this.ROLES.includes(state);
  }
}
