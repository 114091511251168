import config from './config';

import { get, patch, remove } from '../axios';

const { API_PREFIX } = config;

export default {
  /**
   *
   * @param {String} elementId
   * @param {Number} revision
   *
   * @returns Promise
   */
  fetch(elementId, revision = -1) {
    let url = `${API_PREFIX}/element/${elementId}`;
    if (revision > -1) {
      url = `${API_PREFIX}/element/${elementId}/revision/${revision}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {String} elementId
   * @returns
   */
  delete(elementId) {
    const URL = `${API_PREFIX}/element/${elementId}`;

    return new Promise((resolve, reject) => {
      remove(URL)
        .then((result) => resolve(result))
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @param {PatchTag} patchTag
   * @param {String} elementId
   * @param {Number} revision
   * @param {Array} newPatch
   * @param {String} predecessorObj should be filled if the element has a
   * predecessor. Needed keys: predecessorElementId, type and state;
   *
   * @returns Promise
   */
  update({
    patchTag,
    elementId,
    taskId = undefined,
    revision,
    type = null,
    state,
    patches,
    predecessorElementId = undefined,
    srcElementId = undefined,
  }) {
    // TODO überarbeiten
    let URL = null;
    if (taskId === undefined || taskId === '') {
      URL = `${API_PREFIX}/element/${patchTag}/${elementId}/${revision}`;
    } else {
      URL = `${API_PREFIX}/element/${patchTag}/${elementId}/${revision}/${taskId}`;
    }

    const payload = {
      patch: patches,
    };

    if (type && revision === 0) {
      payload.type = type;
    }

    if (state && revision === 0) {
      payload.state = state;
    }

    if (predecessorElementId) {
      payload.predecessorElementId = predecessorElementId;
    }

    if (srcElementId) {
      payload.srcElementId = srcElementId;
    }

    return new Promise((resolve, reject) => {
      patch(URL, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * Set the elementId to the wanted revision. The revision count will still increase.
   * @param {String} elementId
   * @param {Number} toRevision
   * @param {String} taskId only if in approval workflow
   * @returns generated element
   */
  goBackToRevision(elementId, toRevision, taskId = undefined) {
    let url = null;
    if (taskId === undefined || taskId === '') {
      url = `${API_PREFIX}/element/${elementId}/goBackToRevision/${toRevision}`;
    } else {
      url = `${API_PREFIX}/element/${elementId}/goBackToRevision/${toRevision}/${taskId}`;
    }

    return new Promise((resolve, reject) => {
      patch(url, {})
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {String} sectionId
   * @returns
   */
  fetchSimilarities(sectionId) {
    const URL = `/solr/similarities/${sectionId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   * fetch mustervertragInfo for the given elementId
   *
   * @param {String} elementId
   */
  fetchMustervertragInfo(elementId) {
    const URL = `${API_PREFIX}/element/${elementId}/mustervertragInfo`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },
};
