import { isJSON } from '@st/utils-js';
import { validate } from '../utils/uuid';
import Reference from './Reference';

export default class ElementReference extends Reference {
  /**
   *
   * @param {String} id UUID
   * @param {String} dstId UUID
   * @param {Array} dstRefPath Array of UUIDs
   * @param {Array} srcRefPath Array of UUIDs
   * @param {String} dstElementRefId UUID
   * @param {String} srcElementRefId UUID
   */
  constructor(
    id = null,
    dstId = null,
    dstRefPath = [],
    srcRefPath = [],
    dstElementRefId = null,
    srcElementRefId = null,
  ) {
    super(id, dstId, dstRefPath, srcRefPath);
    this.dstElementRefId = dstElementRefId;
    this.srcElementRefId = srcElementRefId;
  }

  /**
   * Creates ElementReference from JSON. If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      id = null,
      dstId = null,
      dstRefPath = [],
      srcRefPath = [],
      dstElementRefId = null,
      srcElementRefId = null,
    } = value;

    return new ElementReference(
      id,
      dstId,
      dstRefPath,
      srcRefPath,
      dstElementRefId,
      srcElementRefId,
    );
  }

  /**
   * @returns ElementReference in JSON representation
   */
  toJSON() {
    return {
      id: this.id,
      dstId: this.dstId,
      dstRefPath: this.dstRefPath,
      srcRefPath: this.srcRefPath,
      dstElementRefId: this.dstElementRefId,
      srcElementRefId: this.srcElementRefId,
    };
  }

  get validDstElementRefId() {
    return !!this.dstElementRefId
      && typeof this.dstElementRefId === 'string'
      && validate(this.dstElementRefId);
  }

  get validSrcElementRefId() {
    return !!this.srcElementRefId
      && typeof this.srcElementRefId === 'string'
      && validate(this.srcElementRefId);
  }

  get valid() {
    const {
      validRefId, validDstId, validDstRefPath, validSrcRefPath,
    } = this;

    const valid = validRefId
      && validDstId
      && validDstRefPath
      && validSrcRefPath
      && this.validDstElementRefId
      && this.validSrcElementRefId;

    return valid;
  }
}
