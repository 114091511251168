export default class ElementStatus {
  static MODIFIABLE = 'modifiable';

  static MIGRATION = 'migration';

  static APPROVAL_WORKFLOW = 'approval_workflow';

  static APPROVED = 'approved';

  static ALL_STATES = [
    this.MIGRATION,
    this.MODIFIABLE,
    this.APPROVAL_WORKFLOW,
    this.APPROVED,
  ];

  static WRITABLE_STATES = [
    this.MODIFIABLE, this.MIGRATION,
  ];

  static isValidState(state) {
    return this.ALL_STATES.includes(state);
  }

  static isWritable(state) {
    return this.WRITABLE_STATES.includes(state);
  }

  static isMutable(state) {
    return !this.WRITABLE_STATES.includes(state);
  }

  static isModifiable(state) {
    return state === this.MODIFIABLE;
  }

  static isMigration(state) {
    return state === this.MIGRATION;
  }

  static isApprovalWorkflow(state) {
    return state === this.APPROVAL_WORKFLOW;
  }

  static isApproved(state) {
    return state === this.APPROVED
    || this.isApprovalWorkflow(state);
  }
}
