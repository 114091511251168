import preferences from './preferences';
import users from './users';
import hilfe from './hilfe';
import servicezugaenge from './servicezugaenge';

export default {
  users,
  preferences,
  hilfe,
  servicezugaenge,
};
