import {
  get, post, patch, put, remove,
} from '../axios';

const REQUEST_MAPPING = '/client';

export default {
  /**
   *
   * @param {String} clientId
   * @param {Number} revision
   * @param {String} vertragId
   */
  getVertragQuestionnaire(clientId, revision, vertragId) {
    const url = `${REQUEST_MAPPING}/${clientId}/vertrag/${vertragId}/${revision}/questionnaire`;
    return get(url);
  },

  /**
   * Fragebogen Update speichern
   *
   * @param {String} clientId
   * @param {String} alteClientElementId
   */
  postQuestionnaireUpdate(clientId, alteClientElementId) {
    const url = `${REQUEST_MAPPING}/${clientId}/vertrag/`
      + `questionnaireUpdate/${alteClientElementId}`;
    return post(url, {});
  },

  /**
   *
   * @param {String} clientId
   * @param {String} vertragId
   * @param {String} description
   * @param {String} clientElementId (optional)
   */
  createClientElement(clientId, vertragId, description, clientElementId) {
    const clientElementParam = clientElementId ? `/${clientElementId}` : '';
    const url = `${REQUEST_MAPPING}/${clientId}/vertrag/${vertragId}/create${clientElementParam}`;
    return post(url, description);
  },

  /**
   * get client element name
   *
   * @param {String} clientElementId
   */
  getClientElementName(clientElementId) {
    const url = `${REQUEST_MAPPING}/clientElement/${clientElementId}/name`;
    return get(url);
  },

  /**
   *
   * @param {String} clientId
   */
  getClientElementList(clientId) {
    const isDev = import.meta.env.MODE === 'development'
      ? '?isDev=true'
      : '';

    const url = `${REQUEST_MAPPING}/${clientId}/clientElementList${isDev}`;
    return get(url);
  },

  /**
   *
   * @param {String} clientElementId
   */
  getClientElement(clientElementId, revision = -1) {
    let url = `${REQUEST_MAPPING}/clientElement/${clientElementId}`;
    if (typeof revision === 'number' && revision > -1) {
      url = `${REQUEST_MAPPING}/clientElement/${clientElementId}/${revision}`;
    }
    return new Promise((resolve, reject) => {
      get(url)
        .then((result) => resolve(result))
        .catch((error) => {
          if (error.response?.status === 403) {
            const newError = new Error();
            newError.response = {
              status: 403.1,
            };
            reject(newError);
          }

          reject(error);
        });
    });
  },

  /**
   *
   * @param {String} clientElementId
   * @param {String} subElementId
   */
  deleteClientElementSubElement(clientElementId, subElementId) {
    const url = `${REQUEST_MAPPING}/clientElement/${clientElementId}/${subElementId}`;
    return remove(url);
  },

  /**
   *
   * @param {String} clientElementId
   * @param {Array} patches
   * @param {Number} revision
   * @param {String} name (optional)
   */
  patchClientElement({
    clientElementId, patches, revision, name = null,
  }) {
    const url = `${REQUEST_MAPPING}/clientElement/${clientElementId}/${revision}`;
    return patch(url, {
      clientElementPatch: patches,
      name,
    });
  },

  /**
   * Updaten des Vertragnamens und der Notizen
   * @param {String} clientElementId
   * @param {String} clientElementName
   * @param {String} clientElementNotes
   */
  putClientElementMetadata({ clientElementId, clientElementName, clientElementNotes }) {
    const url = `${REQUEST_MAPPING}/clientElement/${clientElementId}/rename`;
    return put(url, {
      name: clientElementName,
      notizen: clientElementNotes,
    });
  },

  /**
   * Get simple List of all ClientElements
   *
   * @param {String} clientId
   */
  getSimpleList(clientId) {
    const url = `${REQUEST_MAPPING}/${clientId}/simpleList`;
    return get(url);
  },

  /**
   *
   * @param {String} clientElementId
   */
  deleteClientElement(clientElementId) {
    const url = `${REQUEST_MAPPING}/clientElement/${clientElementId}`;
    return remove(url);
  },

  /**
   *  Get all clients
   *
   */
  getAllClients() {
    const url = `${REQUEST_MAPPING}`;
    return get(url);
  },

  /**
   *  Get all users by clientId
   *
   * @param {String} clientId
   */
  getUsers(clientId) {
    const url = `${REQUEST_MAPPING}/${clientId}/users`;
    return get(url);
  },

  /**
   * Get all bbh Users
   */
  getAllBbhUsers() {
    const url = `${REQUEST_MAPPING}/bbh/users`;
    return get(url);
  },

  /**
   *
   * @param {String} clientElementId
   * @param {String} responsibility
   * @param {String} keycloakUserUuid
   */
  putClientElementResponsibility(clientElementId, responsibility, keycloakUserUuid) {
    const url = `${REQUEST_MAPPING}/clientElement/${clientElementId}/`
    + `responsibility/${responsibility}/${keycloakUserUuid}`;
    return put(url);
  },

  /**
   *
   * @param {String} clientElementId
   * @param {String} keycloakUserUuid
   */
  deleteClientElementResponsibility(clientElementId, keycloakUserUuid) {
    const url = `${REQUEST_MAPPING}/clientElement/${clientElementId}/`
    + `responsibility/${keycloakUserUuid}`;
    return remove(url);
  },

  /**
   * Get root collection
   *
   * @param {String} clientId
   */
  getRootKollektion(clientId) {
    const url = `${REQUEST_MAPPING}/${clientId}/kollektion`;
    return get(url);
  },

  /**
   *
   * @param {String} clientId
   * @param {String} kollektionId
   * @param {String} status
   */
  getKollektion(clientId, kollektionId, status) {
    const url = `${REQUEST_MAPPING}/${clientId}/kollektion/${kollektionId}/${status}`;
    return get(url);
  },

  /**
   * Reihenfolge der Kollektionen speichern oder eine Kollektion löschen,
   * indem diese aus der Reihenfolge herausgelassen wird
   *
   * @param {String} kollektionId
   * @param {Object} kollektionOrderObj
   */
  putKollektionOrder(kollektionId, kollektionOrderObj) {
    const url = `${REQUEST_MAPPING}/kollektion/${kollektionId}/order`;
    return put(url, kollektionOrderObj);
  },

  /**
   * Reihenfolge der Verträge einer Überschrift updaten
   *
   * @param {String} kollektionId
   * @param {Object} body { order: [{ "vertragId" : "uuid", vertragRevision: number }]}
   */
  putKollektionVertragOrder(kollektionId, body) {
    const url = `${REQUEST_MAPPING}/kollektionVertragOrder/${kollektionId}`;
    return put(url, body);
  },

  /**
   * Bestehende und bearbeitete Kollektion speichern
   *
   * @param {Object} kollektionObj
   */
  patchKollektion(kollektionObj) {
    const url = `${REQUEST_MAPPING}/kollektion/${kollektionObj.id}`;
    return patch(url, kollektionObj);
  },

  /**
   * Neue Kollektion anlegen
   *
   * @param {String} parentHeadingId
   * @param {Object} kollektionObj
   */
  postKollektion(parentHeadingId, kollektionObj) {
    const url = `${REQUEST_MAPPING}/kollektion/${parentHeadingId}/${kollektionObj.id}`;
    return post(url, kollektionObj);
  },

  /**
   * Kollektion löschen
   *
   * @param {String} kollektionId
   */
  deleteKollektion(kollektionId) {
    const url = `${REQUEST_MAPPING}/kollektion/${kollektionId}`;
    return remove(url);
  },

  /**
   * Bearbeiteten Vertrag speichern oder neu anlegen
   *
   * @param {Object} contractObject
   */
  putContract(contractObject) {
    const url = `${REQUEST_MAPPING}/vertrag/${contractObject.id}/${contractObject.revision}`;
    return put(url, contractObject);
  },

  /**
   *
   * @returns clients of user
   */
  fetchUserClients() {
    const URL = `${REQUEST_MAPPING}/all`;
    return get(URL);
  },

  /**
   *
   * @param {Object} clientObj
   */
  putClient(clientObj) {
    const URL = `${REQUEST_MAPPING}/${clientObj.clientId}`;
    return put(URL, clientObj);
  },

  /**
   *
   * @param {String} clientId
   */
  putActiveClient(clientId) {
    const URL = `${REQUEST_MAPPING}/${clientId}/active`;
    return put(URL, {});
  },

  /**
   * Alle Kollektionen als flache Liste
   * (optional die KindKollektionen einer kollektionsId rausgefiltert)
   *
   * @param {String} kollektionId (optional)
   * @param {Boolean} withImage (optional)
   * @returns all collections which are no heading
   */
  fetchCollectionsWhichAreNoHeading(kollektionId, withImage = false) {
    const kollektionIdParam = kollektionId ? `/filter/${kollektionId}/${withImage}`
      : `/${withImage}`;

    const URL = `${REQUEST_MAPPING}/kollektion/allflatDetail${kollektionIdParam}`;
    return get(URL);
  },

  /**
   * Move collection to another parent collection
   *
   * @param {String} collectionId
   * @param {String} newParentCollectionId
   */
  moveCollection(collectionId, newParentCollectionId) {
    const URL = `${REQUEST_MAPPING}/kollektion/${collectionId}/move/${newParentCollectionId}`;
    return put(URL);
  },

  /**
   * Returns true if the collection has approved contracts
   *
   * @param {String} collectionId
   * @returns {Boolean}
   */
  collectionHasApprovedContracts(collectionId) {
    const URL = `${REQUEST_MAPPING}/kollektion/${collectionId}/freigegebeneVertraege`;
    return get(URL);
  },

  /**
   * Get upstream decisions from contract
   *
   * @param {String} elementId
   *
   * @returns all collections which are no heading
   */
  getElementDetail(elementId) {
    const URL = `${REQUEST_MAPPING}/element/${elementId}/detail`;
    return get(URL);
  },

  /**
   *
   * @param {String} clientId
   * @param {String} vertragId
   * @param {String} vertragRevision
   * @param {String} baseRevision
   * @param {Object} patches
   * @param {String} clientElementId optional
   */
  patchVertragFragebogenZwischenspeichern({
    clientId, vertragId, vertragRevision, baseRevision, patches, clientElementId,
  }) {
    const clientElementIdParam = clientElementId ? `/${clientElementId}` : '';
    const URL = `${REQUEST_MAPPING}/${clientId}/vertrag/${vertragId}/${vertragRevision}/`
      + `fragebogenZwischenspeichern/${baseRevision}${clientElementIdParam}`;
    return patch(URL, { patch: patches });
  },

  /**
   * @param {String} clientId
   * @param {String} vertragId
   * @param {String} revision
   * @param {String} clientElementId (optional)
   *
   * @returns current revision of questionnaire
   */
  getVertragFragebogenZwischenspeichern(clientId, vertragId, revision = 0, clientElementId = '') {
    const clientElementIdParam = clientElementId ? `/${clientElementId}` : '';
    const URL = `${REQUEST_MAPPING}/${clientId}/vertrag/${vertragId}/${revision}`
      + `/fragebogenZwischenspeichern${clientElementIdParam}`;
    return get(URL);
  },

  /**
   * Request for Release
   *
   * @param {String} clientElementId
   * @param {String} freigabeStatus
   *
   * @returns status of request
   */
  putClientElementReleaseFlag(clientElementId, freigabeStatus) {
    const URL = `${REQUEST_MAPPING}/clientElement/${clientElementId}/`
    + `freigabeStatus/${freigabeStatus}`;
    return put(URL);
  },

  /**
   * get FreigabeStatus of clientElement
   *
   * @param {String} clientElementId
   */
  getClientElementReleaseFlag(clientElementId) {
    const URL = `${REQUEST_MAPPING}/clientElement/${clientElementId}/freigabeStatus`;
    return get(URL);
  },

  /**
   * set client element tags
   *
   * @param {String} clientElementId
   * @param {Object} tagsObj
   *
   * @returns status of request
   */
  putClientElementTags(clientElementId, tagsObj) {
    const URL = `${REQUEST_MAPPING}/clientElement/${clientElementId}/clientTag`;
    return put(URL, tagsObj);
  },

  /**
   * Erzeuge Nachfolger von einem ClientElement (MandantenVertrag unter MeineDokumente)
   *
   * @param {String} clientId
   * @param {String} clientElementId
   */
  postClientElementNachfolger(clientId, clientElementId, clientBody = {}) {
    const URL = `${REQUEST_MAPPING}/${clientId}/${clientElementId}/erzeugeNachfolger`;
    return post(URL, clientBody);
  },

  /**
   * Get clientElement updates
   *
   * @param {String} clientElementId
   */
  getClientElementUpdates(clientElementId) {
    const URL = `${REQUEST_MAPPING}/clientElement/${clientElementId}/updates`;
    return get(URL);
  },

  /**
   * check client element if client uses the approval workflow
   *
   * @param {String} clientElementId
   * @returns {Boolean}
   */
  checkClientElementIfClientUsesTheApprovalWorkflow(clientElementId) {
    const URL = `${REQUEST_MAPPING}/clientElement/${clientElementId}/freigabeProzess`;
    return get(URL);
  },

  /**
   * check client element has updates for updateprozess
   *
   * @param {String} clientId
   * @param {String} clientElementId
   * @returns {Boolean}
   */
  checkWetherContractHasUpdate(clientId, clientElementId) {
    const url = `${REQUEST_MAPPING}/${clientId}/${clientElementId}/originalCheck`;
    return get(url);
  },

  /**
   * Fragebogen zurücksetzen
   *
   * @param {String} clientId
   * @param {String} vertragId
   * @param {String} vertragRevision
   * @param {String} baseRevision
   * @param {String} clientElementId optional
   */
  resetFragebogen(clientId, vertragId, vertragRevision, baseRevision, clientElementId = null) {
    const clientElementIdParam = clientElementId ? `/${clientElementId}` : '';
    const URL = `${REQUEST_MAPPING}/${clientId}/vertrag/${vertragId}/${vertragRevision}/`
      + `fragebogenZwischenspeichern/${baseRevision}${clientElementIdParam}`;

    return remove(URL);
  },

  /**
   * die neuen und veränderten Fragen bei einem Fragebogen
   *
   * @param {String} clientId
   * @param {String} vertragId
   * @param {String} vertragRevision
   */
  getFragebogenWasIstNeu(clientId, vertragId, vertragRevision) {
    const URL = `${REQUEST_MAPPING}/${clientId}/fragebogenWasIstNeu/vertragId/${vertragId}`
      + `/vertragRevision/${vertragRevision}`;
    return get(URL);
  },

  /**
   * Mandantenvertrag erstellen
   *
   * @param {String} name
   * @param {Object} subElements (optional)
   * @param {Array} order (optional)
   */
  postMandantenVertrag(name, subElements = {}, order = []) {
    const URL = `${REQUEST_MAPPING}/individuellesClientElement`;
    const body = {
      name,
      clientElement: {
        order,
        subElements,
      },
    };
    return post(URL, body);
  },

  /**
   * @GET
   * get Ansprechparter zum Vertrag
   *
   * @param {String} vertragId
   * @param {String} revision
   */
  getAnsprechpartnerVertrag(vertragId, revision) {
    const URL = `${REQUEST_MAPPING}/vertrag/${vertragId}/${revision}/ansprechpartner`;
    return get(URL);
  },

  /**
   * @GET
   * get revisions of element
   *
   * @param {String} clientId
   * @param {String} clientElementId
   */
  getClientElementRevisions(clientId, clientElementId) {
    const URL = `${REQUEST_MAPPING}/${clientId}/${clientElementId}/revisions`;

    return get(URL);
  },

  /**
   * @POST
   * go back to revision of element
   *
   * @param {String} clientElementId
   * @param {String} revisionNr
   */
  goBackToClientElementRevision(clientElementId, revisionNr) {
    const secretKey = import.meta.env.VITE_SECRET_KEY || '';
    if (!secretKey) throw new Error('Secret key is not defined! (VITE_SECRET_KEY)');
    const URL = `/${secretKey}/clientElementGoBackToRevision/`
      + `${clientElementId}/${revisionNr}`;

    return get(URL);
  },

  /**
   * get stammdaten for mandant
   *
   * @param {String} clientId
   * @returns
   */
  getStammdaten(clientId) {
    const URL = `${REQUEST_MAPPING}/${clientId}/stammdaten`;
    return get(URL);
  },

  /**
   * put stammdaten for mandant
   *
   * @param {String} clientId
   * @param {Object} stammdaten
   * @returns
   * @memberof ClientService
   */
  putStammdaten(clientId, stammdaten) {
    const URL = `${REQUEST_MAPPING}/${clientId}/stammdaten`;
    return put(URL, stammdaten);
  },

  /**
   * get sparten for mandant
   *
   * @param {String} clientId
   * @returns
   */
  getSparten(clientId) {
    const URL = `${REQUEST_MAPPING}/${clientId}/sparten`;
    return get(URL);
  },

  /**
   * put sparten for mandant
   *
   * @param {String} clientId
   * @param {Object} sparten
   * @returns
   * @memberof ClientService
   */
  putSparten(clientId, sparten) {
    const URL = `${REQUEST_MAPPING}/${clientId}/sparten`;
    return put(URL, sparten);
  },

  /**
   * get servicezugang
   *
   * @param {String} clientId (optional)
   * @returns
   */
  getServicezugang(clientId) {
    const clientIdParam = clientId ? `/${clientId}` : '';

    const URL = `${REQUEST_MAPPING}/servicezugang${clientIdParam}`;
    return get(URL);
  },

  /**
   * put servicezugang
   *
   * @param {Object} body
   *
   * @returns
   */
  putServicezugang(body) {
    const URL = `${REQUEST_MAPPING}/servicezugang`;
    return put(URL, body);
  },

  /**
   * delete servicezugang
   *
   * @param {String} clientId
   * @param {String} keycloakUserUuid
   *
   * @returns
   */
  deleteServicezugang(clientId, keycloakUserUuid) {
    const URL = `${REQUEST_MAPPING}/servicezugang/${clientId}/${keycloakUserUuid}`;
    return remove(URL);
  },

  /**
   * get servicezugangAnfragen
   *
   * @returns
   */
  getServicezugangAnfragen() {
    const URL = `${REQUEST_MAPPING}/servicezugangAnfragen`;
    return get(URL);
  },

  /**
   * get servicezugangAnfragen
   *
   * @param {String} id
   *
   * @returns
   */
  deleteServicezugangAnfragen(id) {
    const URL = `${REQUEST_MAPPING}/servicezugangAnfragen/${id}`;
    return remove(URL);
  },

  /**
   * duplicate client element
   *
   * @param {String} clientElementId
   * @returns {Promise}
   */
  duplicateClientElement(clientElementId) {
    const URL = `${REQUEST_MAPPING}/clientElement/${clientElementId}/duplicate`;
    return post(URL);
  },
};
