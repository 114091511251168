export default class MandantenDokumentSubelementTyp {
  static DOKUMENT = 'DOKUMENT';

  static BAUSTEIN = 'BAUSTEIN';

  static isType(type) {
    const types = [
      this.DOKUMENT, this.BAUSTEIN,
    ];
    return types.includes(type);
  }

  static isDokument(type) {
    return type === this.DOKUMENT;
  }

  static isBaustein(type) {
    return type === this.BAUSTEIN;
  }
}
