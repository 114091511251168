import style from './default-style';

export default {
  name: 'bold',
  parseDOM: [
    {
      tag: 'bold',
      style: 'font-family=ApexNew-Medium',
    },
  ],
  toDOM() {
    const element = document.createElement('bold');

    Object.assign(element.style, style, { fontFamily: 'ApexNew-Medium' });
    return element;
  },
};
