import { isJSON } from '@st/utils-js';
import AbschnittKlauselTransformationCheckliste from './AbschnittKlauselTransformationCheckliste';
import ElementTyp from './enums/ElementTyp';
import TextContentElementKommentar from './TextContentElementKommentar';
import UeberschriftKlauselTransformationCheckliste from './UeberschriftKlauselTransformationCheckliste';

export default class TextContentElementAttrs {
  /**
   *
   * @param {String} type value of ElementTyp
   * @param {Number} level
   * @param {Array} comments Array of TextContentElementKommentar
   * @param {AbschnittKlauselTransformationCheckliste
   *  | UeberschriftKlauselTransformationCheckliste} clauseTransformation
   *  AbschnittKlauselTransformationCheckliste or UeberschriftKlauselTransformationCheckliste
   * @param {Number} subComponentLevelShifting
   */
  constructor(
    type = ElementTyp.ABSCHNITT,
    level = 0,
    comments = [],
    clauseTransformation = new AbschnittKlauselTransformationCheckliste(),
    subComponentLevelShifting = 0,
  ) {
    this.level = level;
    this.comments = comments;
    if (ElementTyp.isAbschnitt(type)) {
      if (clauseTransformation instanceof AbschnittKlauselTransformationCheckliste) {
        this.clauseTransformation = clauseTransformation;
      } else {
        this.clauseTransformation = AbschnittKlauselTransformationCheckliste
          .fromJSON(clauseTransformation);
        if (!this.clauseTransformation) {
          this.clauseTransformation = new AbschnittKlauselTransformationCheckliste();
        }
      }
    } else if (ElementTyp.isUeberschrift(type)) {
      if (clauseTransformation instanceof UeberschriftKlauselTransformationCheckliste) {
        this.clauseTransformation = clauseTransformation;
      } else {
        this.clauseTransformation = UeberschriftKlauselTransformationCheckliste
          .fromJSON(clauseTransformation);
        if (!this.clauseTransformation) {
          this.clauseTransformation = new UeberschriftKlauselTransformationCheckliste();
        }
      }
    }
    this.subComponentLevelShifting = subComponentLevelShifting;
  }

  /**
   * Creates TextContentElementAttrs from JSON.
   * If keys are missing then it will use the default values.
   * value need the key 'type' to generate the right clauseTransformation
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const { clauseTransformation } = value;
    let localClauseTransformation = ElementTyp.ABSCHNITT;
    if (ElementTyp.isAbschnitt(value.type)) {
      localClauseTransformation = AbschnittKlauselTransformationCheckliste
        .fromJSON(clauseTransformation);
    } else if (ElementTyp.isUeberschrift(value.type)) {
      localClauseTransformation = UeberschriftKlauselTransformationCheckliste
        .fromJSON(clauseTransformation);
    } else {
      localClauseTransformation = new AbschnittKlauselTransformationCheckliste();
    }

    const {
      type = ElementTyp.ABSCHNITT,
      level = 0,
      comments = [],
      subComponentLevelShifting = 0,
    } = value;

    const localComments = comments.map(
      (comment) => (comment instanceof TextContentElementKommentar
        ? comment : TextContentElementKommentar.fromJSON(comment)),
    );

    return new TextContentElementAttrs(
      type,
      level,
      localComments,
      localClauseTransformation,
      subComponentLevelShifting,
    );
  }

  /**
   * @returns TextContentElementAttrs in JSON representation
   */
  toJSON() {
    let clauseTransformation = {};
    if (
      this.clauseTransformation instanceof AbschnittKlauselTransformationCheckliste
      || this.clauseTransformation instanceof UeberschriftKlauselTransformationCheckliste
    ) {
      clauseTransformation = this.clauseTransformation.toJSON();
    } else {
      clauseTransformation = this.clauseTransformation;
    }

    return {
      level: this.level,
      comments: this.comments.map((comment) => {
        if (comment instanceof TextContentElementKommentar) {
          return comment.toJSON();
        }
        return comment;
      }),
      clauseTransformation,
      subComponentLevelShifting: this.subComponentLevelShifting,
    };
  }

  get validLevel() {
    return typeof this.level === 'number' && this.level >= 0;
  }

  get validComments() {
    return !!this.comments
      && Array.isArray(this.comments)
      && this.comments.every((comment) => comment instanceof TextContentElementKommentar
        && comment.valid);
  }

  get validClauseTransformation() {
    return !!this.clauseTransformation
      && (this.clauseTransformation instanceof AbschnittKlauselTransformationCheckliste
        || this.clauseTransformation instanceof UeberschriftKlauselTransformationCheckliste)
      && this.clauseTransformation.valid;
  }

  get validSubComponentLevelShifting() {
    return typeof this.subComponentLevelShifting === 'number'
      && this.subComponentLevelShifting >= 0;
  }

  get valid() {
    const valid = this.validLevel
      && this.validComments
      && this.validClauseTransformation
      && this.validSubComponentLevelShifting;

    return valid;
  }
}
