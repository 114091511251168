import { isJSON } from '@st/utils-js';
import { validate } from '../utils/uuid';
import MandantDokumentElementQuestionnaire from './MandantDokumentElementQuestionnaire';
import MandantDokumentElementClientElement from './MandantDokumentElementClientElement';

export default class MandantDokumentElement {
  /**
   * @param {String} clientElementId
   * @param {Number} revision
   * @param {MandantDokumentElementQuestionnaire} questionnaire
   * @param {MandantDokumentElementClientElement} clientElement
   * @param {Object} clientElementUpdates
   * @param {Object} clientElementUpdateSections
   * @param {String} updateBeschreibung
   * @param {Object} clientElementOriginMapUpdates
   */
  constructor(
    clientElementId = null,
    revision = 0,
    questionnaire = new MandantDokumentElementQuestionnaire(),
    clientElement = new MandantDokumentElementClientElement(),
    clientElementUpdates = {},
    clientElementUpdateSections = {},
    updateBeschreibung = '',
    clientElementOriginMapUpdates = {},
  ) {
    this.clientElementId = clientElementId;
    this.revision = revision;
    this.questionnaire = questionnaire;
    this.clientElement = clientElement;
    this.clientElementUpdates = clientElementUpdates;
    this.clientElementUpdateSections = clientElementUpdateSections;
    this.clientElementOriginMapUpdates = clientElementOriginMapUpdates;
    this.updateBeschreibung = updateBeschreibung;
  }

  /**
   * Creates MandantDokumentElement from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    let questionnaire = new MandantDokumentElementQuestionnaire();
    if (isJSON(value.questionnaire)) {
      questionnaire = MandantDokumentElementQuestionnaire.fromJSON(value.questionnaire);
    }

    let clientElement = new MandantDokumentElementClientElement();
    if (isJSON(value.clientElement)) {
      clientElement = MandantDokumentElementClientElement.fromJSON(value.clientElement);
    }

    const {
      clientElementId = null,
      id = null,
      revision = 0,
      clientElementUpdates = {},
      clientElementUpdateSections = {},
      clientElementOriginMapUpdates = {},
      updateBeschreibung = '',
    } = value;

    return new MandantDokumentElement(
      clientElementId || id,
      revision,
      questionnaire,
      clientElement,
      clientElementUpdates,
      clientElementUpdateSections,
      updateBeschreibung,
      clientElementOriginMapUpdates,
    );
  }

  /**
   * @returns MandantDokumentElement in JSON representation
   */
  toJSON() {
    return {
      clientElementId: this.clientElementId,
      revision: this.revision,
      questionnaire: this.questionnaire.toJSON(),
      clientElement: this.clientElement.toJSON(),
      clientElementUpdates: this.clientElementUpdates,
      clientElementUpdateSections: this.clientElementUpdateSections,
      updateBeschreibung: this.updateBeschreibung,
      clientElementOriginMapUpdates: this.clientElementOriginMapUpdates,
    };
  }

  get validClientElementUpdates() {
    return isJSON(this.clientElementUpdates);
  }

  get validClientElementUpdateSections() {
    return isJSON(this.clientElementUpdateSections);
  }

  get validClientElementOriginMapUpdates() {
    return isJSON(this.clientElementOriginMapUpdates);
  }

  get validUpdateBeschreibung() {
    return this.updateBeschreibung
    && (
      Object.keys(this.clientElementUpdates).length < 1
      || this.updateBeschreibung.length > 0
    );
  }

  get valid() {
    const validclientElementId = !!this.clientElementId
      && typeof this.clientElementId === 'string'
      && validate(this.clientElementId);

    const validRevision = typeof this.revision === 'number';

    const validQuestionnaire = !!this.questionnaire
      && this.questionnaire instanceof MandantDokumentElementQuestionnaire
      && this.questionnaire.valid;

    const validClientElement = !!this.clientElement
      && this.clientElement instanceof MandantDokumentElementClientElement
      && this.clientElement.valid;

    const valid = validclientElementId
      && validRevision
      && validQuestionnaire
      && validClientElement
      && this.validClientElementUpdates
      && this.validClientElementUpdateSections
      && this.validClientElementOriginMapUpdates;

    return valid;
  }
}
