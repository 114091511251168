export default class PatchTag {
  static AUTO_SAVE = 'auto_save';

  static SAVE = 'save';

  static APPROVAL_WORKFLOW = 'approval_workflow';

  static isTag(tag) {
    const patches = [
      this.AUTO_SAVE, this.SAVE, this.APPROVAL_WORKFLOW,
    ];
    return patches.includes(tag);
  }

  static isAutoSave(tag) {
    return tag === this.AUTO_SAVE;
  }

  static isSave(tag) {
    return tag === this.SAVE;
  }

  static isApprovalWorkflow(tag) {
    return tag === this.APPROVAL_WORKFLOW;
  }
}
