import { get, post, put } from '../axios';

const REQUEST_MAPPING = 'internUpdate';

export default {
  /** Erstellen einer neuen Version eines Elements
   * @param {String} elementId
   *
   * @return 200, falls erfolgreich, 412, falls Nachfolger bereits existiert + elementId
   * des Nachfolgers und 409, falls nicht freigegeben
   */
  createDescendant(elementId) {
    const url = `/${REQUEST_MAPPING}/${elementId}/erzeugeNachfolger`;

    return post(url);
  },

  /**
   * replace element with newest approved version
   *
   * @param {String} elementId
   *
   * @return 200, if successful, 404 if not found
   */
  replaceWithNewestApprovedVersion(elementId) {
    const url = `/${REQUEST_MAPPING}/${elementId}/ersetzeMitNeuestemNachfolger`;

    return post(url);
  },

  /** Erstellen einer neuen Version eines SubElements
   * @param {String} elementId
   * @param {String} subElementRefPathId
   *
   * @return 200 und elementId des neuen Elements
   */
  createSubElementDescendant(elementId, subElementRefPathId) {
    const url = `/${REQUEST_MAPPING}/${elementId}/erzeugeSubElementNachfolger/`
      + `${subElementRefPathId}`;

    return post(url);
  },

  /**
   * put Element Disclaimer
   *
   * @param {String} elementId
   * @param {Object} body
   */
  putElementDisclaimer(elementId, body) {
    const url = `/${REQUEST_MAPPING}/${elementId}/disclaimer`;

    return put(url, body);
  },

  /**
   * get alle Versionen von elementId
   *
   * @param {String} elementId
   */
  getVersions(elementId) {
    const url = `/${REQUEST_MAPPING}/alleVersionenVon/${elementId}`;

    return new Promise((resolve, reject) => {
      get(url)
        .then((response) => resolve(response))
        .catch((error) => {
          if (error.response.status === 404) {
            resolve({
              data: {
                versions: [],
              },
            });
          }
          reject(error);
        });
    });
  },
};
