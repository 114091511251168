import {
  get, post, put,
} from '../axios';

const API_PREFIX = '/shopClient';

export default {

  /**
   * Receive packages that include a contract
   *
   * @param {string} clientId
   * @param {string} vertragsId
   */
  fetchPaketeByVertrag(clientId, vertragsId) {
    const URL = `${API_PREFIX}/${clientId}/paketeByVertrag/${vertragsId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * buy a package
   *
   * @param {string} clientId
   * @param {string} paketId
   */
  buyPaket(clientId, paketId) {
    const URL = `${API_PREFIX}/${clientId}/paketAbonnieren/${paketId}`;

    return new Promise((resolve, reject) => {
      post(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * cancel a package
   *
   * @param {string} clientId
   * @param {string} aboId
   */
  cancelPaket(clientId, aboId) {
    const URL = `${API_PREFIX}/${clientId}/paketKuendigen/${aboId}`;

    return new Promise((resolve, reject) => {
      post(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get invoices of client
   *
   * @param {string} clientId
   */
  getInvoices(clientId) {
    const URL = `${API_PREFIX}/${clientId}/rechnungen`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get abos of client
   *
   * @param {string} clientId
   */
  getAbos(clientId) {
    const URL = `${API_PREFIX}/${clientId}/abos`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get abosDetails of client
   *
   * @param {string} clientId
   * @param {string} aboId
   */
  getAboDetails(clientId, aboId) {
    const URL = `${API_PREFIX}/${clientId}/abo/${aboId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  /**
   * post KuendigenAbos
   *
   * @param {string} clientId
   * @param {string} paketId
   */
  postKuendigenAbos(clientId, aboId) {
    const URL = `${API_PREFIX}/${clientId}/paketKuendigen/${aboId}`;

    return new Promise((resolve, reject) => {
      post(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * put abo Anmerkungen zur Abrechnung
   *
   * @param {string} clientId
   * @param {string} aboId
   * @param {string} paketId
   * @param {json} body
   *
   * @returns {Promise}
   */
  putAboAnmerkungenZurAbrechnung(clientId, aboId, paketId, body) {
    const URL = `${API_PREFIX}/${clientId}/abo/${aboId}/${paketId}`;

    return new Promise((resolve, reject) => {
      put(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
