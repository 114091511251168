import FreigabeChecklistElement from './FreigabeChecklistElement';

export default class FreigabeChecklist {
  constructor(
    value = null,
    revision = 0,
  ) {
    if (!value) return;
    Object.entries(value)
      .forEach(([
        key, v,
      ]) => {
        let obj = v;
        if (!(v instanceof FreigabeChecklistElement)) {
          obj = FreigabeChecklistElement.fromJSON(obj);
        }
        this[key] = obj;
      });
    this.revision = revision;
  }

  addElement(key, element = new FreigabeChecklistElement()) {
    if (!(element instanceof FreigabeChecklistElement)) return;
    if (typeof key !== 'string') return;
    if (Object.keys(this).includes(key)) return;

    this[key] = element;
  }

  addChecked(key, revision) {
    if (!this[key]) this.addElement(key);

    this[key].addChecked(revision);
    this.addRevisionCount();
  }

  // eslint-disable-next-line default-param-last
  updateRevisions(exceptKeys = [], oldRevision, newRevision) {
    Object.keys(this)
      .filter((key) => typeof this[key] === 'object')
      .filter((key) => !exceptKeys.includes(key))
      .forEach((key) => {
        this[key].update(oldRevision, newRevision);
      });
    this.addRevisionCount();
  }

  toggleChecked(key, revision) {
    if (!this[key]) this.addElement(key);

    if (this[key].hasRevision(revision)) {
      this[key].removeChecked(revision);
    } else {
      this[key].addChecked(revision);
    }
    this.addRevisionCount();
  }

  addRevisionCount() {
    this.revision += 1;
  }

  static fromJSON(value, revision = 0) {
    const obj = Object.entries(value)
      .reduce((prev, [
        key, v,
      ]) => {
        const copy = prev;
        copy[key] = FreigabeChecklistElement.fromJSON(v);
        return copy;
      }, {});

    return new FreigabeChecklist(obj, revision);
  }

  toJSON() {
    return Object.entries(this)
      .filter((value) => value[1] instanceof FreigabeChecklistElement)
      .reduce((prev, [
        key, value,
      ]) => {
        const copy = prev;
        copy[key] = value.toJSON();
        return copy;
      }, {});
  }

  get validChecklist() {
    return Object.values(this)
      .filter((value) => value instanceof FreigabeChecklistElement)
      .every((element) => element.validValue);
  }
}
