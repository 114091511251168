import { isJSON } from '@st/utils-js';
import PersistObj from './PersistObj';
import Tag from './Tag';

export default class KeycloakUser extends PersistObj {
  /**
   *
   * @param {String} keycloakUserUuid
   * @param {String} firstname
   * @param {String} lastname
   * @param {Array} roles optional
   * @param {Array} clients optional
   * @param {Array} email
   * @param {String} TITEL optional
   * @param {String} ANREDE optional
   * @param {String} bild, optional
   * @param {Array} interessensgebiete, optional
   * @param {String} akronym, optional
   * @param {String} phone, optional
   * @param {String} role, optional
   * @param {String} mainAreasOfActivity, optional
   * @param {Array} tags, optional
   * @param {Array} tabs, optional
   * @param {String} mobilnummer, optional
   * @param {String} faxnummer, optional
   */
  constructor(
    keycloakUserUuid = null,
    firstname = '',
    lastname = '',
    roles = [],
    clients = [],
    email = '',
    TITEL = '',
    ANREDE = '',
    bild = '',
    interessensgebiete = [],
    akronym = '',
    phone = '',
    role = '',
    mainAreasOfActivity = '',
    tags = [],
    tabs = [],
    mobilnummer = '',
    faxnummer = '',
  ) {
    super(keycloakUserUuid);
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.roles = roles;
    this.clients = clients;
    this.metadata = {
      TITEL,
      ANREDE,
    };
    this.settings = {
      tags,
      tabs,
    };
    this.bild = bild;
    this.crmInfo = {
      interessensgebiete,
      akronym,
      phone,
      role,
      mainAreasOfActivity,
      mobilnummer,
      faxnummer,
    };
  }

  /**
   * Creates Antwort from JSON. If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const localValue = {
      ...value,
      metadata: { ...value.metadata },
      crmInfo: { ...value.crmInfo },
      settings: { ...value.settings },
    };

    const localTags = localValue.settings.tags || [];
    const localTabs = localValue.settings.tabs || [];

    const {
      keycloakUserUuid,
      id,
      firstname,
      lastname,
      roles = [],
      clients = [],
      email,
      metadata: {
        TITEL = null,
        ANREDE = null,
      } = {},
      bild = null,
      crmInfo: {
        interessensgebiete = [],
        akronym = null,
        phone = null,
        role = null,
        mainAreasOfActivity = null,
        mobilnummer = null,
        faxnummer = null,
      },
      settings: {
        tags = localTags,
        tabs = localTabs,
      },
    } = localValue;

    const tmpId = keycloakUserUuid || id;

    return new KeycloakUser(
      tmpId,
      firstname,
      lastname,
      roles,
      clients,
      email,
      TITEL,
      ANREDE,
      bild,
      interessensgebiete,
      akronym,
      phone,
      role,
      mainAreasOfActivity,
      tags.map((tag) => {
        if (isJSON(tag) && !(tag instanceof Tag)) {
          return Tag.fromJSON(tag);
        }
        return tag;
      }),
      tabs.map((tab) => {
        if (isJSON(tab) && !(tab instanceof Tag)) {
          return Tag.fromJSON(tab);
        }
        return tab;
      }),
      mobilnummer,
      faxnummer,
    );
  }

  /**
   * @returns KeycloakUser in JSON representation
   */
  toJSON() {
    const tags = this.settings.tags.map((tag) => {
      if (tag instanceof Tag) {
        return tag.toJSON();
      }
      return tag;
    });
    const tabs = this.settings.tabs.map((tab) => {
      if (tab instanceof Tag) {
        return tab.toJSON();
      }
      return tab;
    });

    return {
      keycloakUserUuid: this.id,
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      roles: this.roles,
      clients: this.clients,
      metadata: {
        TITEL: this.metadata.TITEL,
        ANREDE: this.metadata.ANREDE,
      },
      settings: {
        tags,
        tabs,
      },
      bild: this.bild,
      crmInfo: {
        interessensgebiete: this.crmInfo.interessensgebiete,
        akronym: this.crmInfo.akronym,
        phone: this.crmInfo.phone,
        role: this.crmInfo.role,
        mainAreasOfActivity: this.crmInfo.mainAreasOfActivity,
        mobilnummer: this.mobilnummer,
        faxnummer: this.faxnummer,
      },
    };
  }

  get userInitials() {
    return `${this.firstname?.toUpperCase().charAt(0)}${this.lastname?.toUpperCase().charAt(0)}`;
  }

  get name() {
    const titleString = this.metadata.TITEL ? `${this.metadata.TITEL} ` : '';
    return `${titleString + this.firstname} ${this.lastname}`;
  }

  get validFirstname() {
    return !!this.firstname
    && typeof this.firstname === 'string'
    && !!this.firstname.trim();
  }

  get validLastname() {
    return !!this.lastname
    && typeof this.lastname === 'string'
    && !!this.lastname.trim();
  }

  get validEmail() {
    return !!this.email
    && typeof this.email === 'string'
    && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
    && !!this.email.trim();
  }

  get validFirstnameEmpty() {
    return typeof this.firstname === 'string';
  }

  get validLastnameEmpty() {
    return typeof this.lastname === 'string';
  }

  get validEmailEmpty() {
    return typeof this.email === 'string';
  }

  get hasRequiredKeys() {
    return this.validId
      && this.validFirstname
      && this.validLastname
      && this.validEmail;
  }

  get validTags() {
    return Array.isArray(this.settings.tags)
    && this.settings.tags.every((tag) => tag instanceof Tag);
  }

  get validTabs() {
    return Array.isArray(this.settings.tabs)
    && this.settings.tabs.every((tab) => tab instanceof Tag);
  }

  get validRoles() {
    return Array.isArray(this.roles)
    && this.roles.every((role) => typeof role.name === 'string' && role.name?.length > 0);
  }

  get validClients() {
    return Array.isArray(this.clients);
  }

  get validBild() {
    return this.bild === null || this.bild === undefined || typeof this.bild === 'string';
  }

  get validInteressensgebiete() {
    return this.crmInfo.interessensgebiete === null
      || this.crmInfo.interessensgebiete === undefined
      || Array.isArray(this.crmInfo.interessensgebiete);
  }

  get validAkronym() {
    return this.crmInfo.akronym === null
      || this.crmInfo.akronym === undefined
      || typeof this.crmInfo.akronym === 'string';
  }

  get validPhone() {
    return this.crmInfo.phone === null
      || this.crmInfo.phone === undefined
      || typeof this.crmInfo.phone === 'string';
  }

  get validMobilnummer() {
    return this.crmInfo.mobilnummer === null
      || this.crmInfo.mobilnummer === undefined
      || typeof this.crmInfo.mobilnummer === 'string';
  }

  get validFaxnummer() {
    return this.crmInfo.faxnummer === null
      || this.crmInfo.faxnummer === undefined
      || typeof this.crmInfo.faxnummer === 'string';
  }

  get validTitel() {
    return this.metadata.TITEL === null
      || this.metadata.TITEL === undefined
      || typeof this.metadata.TITEL === 'string';
  }

  get validAnrede() {
    return this.metadata.ANREDE === null
      || this.metadata.ANREDE === undefined
      || typeof this.metadata.ANREDE === 'string';
  }

  get validRole() {
    return this.crmInfo.role === null
      || this.crmInfo.role === undefined
      || typeof this.crmInfo.role === 'string';
  }

  get validMainAreasOfActivity() {
    return this.crmInfo.mainAreasOfActivity === null
      || this.crmInfo.mainAreasOfActivity === undefined
      || typeof this.crmInfo.mainAreasOfActivity === 'string';
  }

  get valid() {
    const {
      validId,
      validFirstname,
      validLastname,
      validEmail,
      validTags,
      validTabs,
    } = this;

    const valid = validId
    && validFirstname
    && validLastname
    && validEmail
    && this.validRoles
    && this.validClients
    && validTags
    && this.validTitel
    && this.validAnrede
    && this.validBild
    && this.validInteressensgebiete
    && this.validAkronym
    && this.validPhone
    && this.validRole
    && this.validMainAreasOfActivity
    && this.validMobilnummer
    && this.validFaxnummer
    && validTabs;

    return valid;
  }

  get validEmpty() {
    const {
      validId,
      validTags,
      validTabs,
    } = this;

    const valid = validId
    && this.validFirstnameEmpty
    && this.validLastnameEmpty
    && this.validEmailEmpty
    && this.validRoles
    && this.validClients
    && validTags
    && this.validTitel
    && this.validAnrede
    && this.validBild
    && this.validInteressensgebiete
    && this.validAkronym
    && this.validPhone
    && this.validRole
    && this.validMainAreasOfActivity
    && this.validMobilnummer
    && this.validFaxnummer
    && validTabs;

    return valid;
  }
}
