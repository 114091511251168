<template>
  <div>
    <!-- Fuzzy -->
    <v-layout
      column
      class="ma-0"
    >
      <v-flex
        v-click-outside="{
          handler: handleClickOutside,
          closeConditional: false,
        }"
        grow
        class="pa-0"
      >
        <v-menu
          v-model="visible"
          offset-y
          :disabled="readonly"
          attach="#bbh-menu-content"
          :close-on-content-click="false"
          :close-on-click="false"
        >
          <template #activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-text-field
                v-if="!solo"
                ref="fuzzySearch"
                v-model="preparedValue"
                class="search-bar"
                :class="{ 'search-bar-menu-active': visible }"
                filled
                rounded
                solo
                :append-icon="noAppendIcon ? appendIcon : undefined"
                :rules="rules"
                :disabled="readonly"
                hide-details="auto"
                autofocus
                autocomplete="off"
                prepend-inner-icon="$search"
                placeholder="Suche"
                color="var(--bbh-blue)"
                @keydown.enter="change()"
                @input="search()"
                @click="handleClick"
              />
            </span>
          </template>

          <template v-if="result">
            <slot
              name="items"
              :items="result"
            >
              <div id="menu-wrapper">
                <div
                  v-for="(results, index) in separatedResults"
                  :key="index"
                >
                  <bbh-list
                    v-if="results.items.length > 0"
                    :list-name="results.title"
                    :items="results.items"
                  >
                    <template #item="{ item }">
                      <v-sheet
                        class="h-max-content"
                        color="transparent"
                        :class="{
                          'min-height-61': !item.isActive,
                        }"
                      >
                        <v-lazy
                          v-model="item.isActive"
                          :options="{
                            threshold: .5,
                          }"
                          class="h-max-content"
                        >
                          <bbh-list-item
                            :item="item"
                            :list-name="results.title"
                            shown-attribute="name"
                            @click:item="emitSelect(item)"
                          />
                        </v-lazy>
                      </v-sheet>
                    </template>
                  </bbh-list>
                </div>
              </div>
            </slot>
          </template>
        </v-menu>
        <div id="bbh-menu-content" class="scroll-bar" />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */

import ElementTyp from '@src/model/enums/ElementTyp';
import { deepClone } from '@st/utils-js';
import Fuse from 'fuse.js';

// https://fusejs.io/api/options.html
const defaultOptions = {
  isCaseSensitive: false,
  includeScore: true,
  includeMatches: false,
  minMatchCharLength: 1,
  shouldSort: true,
  findAllMatches: false,
  ignoreLocation: true,
  useExtendedSearch: false,
  ignoreFieldNorm: false,
};

function pathToKey(stringArray, seperator) {
  let array = stringArray;
  if (!Array.isArray(array) && typeof stringArray === 'string') {
    array = stringArray.split(seperator);
  }
  let result = '';

  array.forEach((str, i) => {
    str = str.toLowerCase();
    if (i === 0) result = str;
    else {
      str = str.substr(0, 1).toUpperCase() + str.substr(1);
      result += str;
    }
  });

  return result;
}

export default {
  name: 'BbhSearch',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      default: null,
    },
    label: { type: String, default: 'Suche' },

    visibleResult: { type: Boolean, default: true },
    solo: { type: Boolean, default: false },

    rules: { type: Array, default: () => ([]) },

    readonly: { type: Boolean, default: false },

    appendIcon: { type: String, default: 'search' },
    noAppendIcon: { type: Boolean, default: false },

    itemKeys: {
      type: Array,
      default: () => [
        'name', 'text', 'elementId', 'clientElementId',
      ],
    },

    items: {
      type: Array,
      default: () => ([]),
    },
    routes: {
      type: Array,
      default: () => ([]),
    },
  },

  data: () => ({
    result: [],

    visible: false,

    preparedValue: '',
  }),

  computed: {
    options() {
      // https://fusejs.io/api/options.html
      return {
        includeScore: true,
        keys: this.itemKeys.map((path) => pathToKey(path.split('.'))),
      };
    },

    resultRoutes() {
      return this.result.filter((result) => result.type === 'ROUTE')
        .map((result) => (
          {
            ...result,
            icon: '$commonFileQuill',
          }));
    },
    resultMusterdokumente() {
      return this.result.filter((result) => result.type === ElementTyp.MUSTERDOKUMENT)
        .map((result) => (
          {
            ...result,
            icon: '$commonFileQuill',
          }));
    },
    resultKlauseln() {
      return this.result.filter((result) => result.type === ElementTyp.KLAUSEL)
        .map((result) => (
          {
            ...result,
            icon: '$officeFileStamp',
          }));
    },
    resultMustervertraege() {
      return this.result.filter((result) => result.type === ElementTyp.MUSTERVERTRAG)
        .map((result) => (
          {
            ...result,
            icon: '$commonFileAward',
          }));
    },
    resultDocuments() {
      return this.result.filter((result) => result.type === ElementTyp.DOKUMENT)
        .map((result) => (
          {
            ...result,
            icon: '$commonFileText',
          }));
    },

    separatedResults() {
      return [
        {
          title: `Menüs (${this.resultRoutes.length})`,
          items: this.resultRoutes,
        },
        {
          title: `Musterdokumente (${this.resultMusterdokumente.length})`,
          items: this.resultMusterdokumente,
        },
        {
          title: `Klauseln (${this.resultKlauseln.length})`,
          items: this.resultKlauseln,
        },
        {
          title: `Musterverträge (${this.resultMustervertraege.length})`,
          items: this.resultMustervertraege,
        },
        {
          title: `Dokumente (${this.resultDocuments.length})`,
          items: this.resultDocuments,
        },
      ];
    },
  },

  watch: {
    showDialog(value) {
      if (!value) {
        this.preparedValue = '';
      }
    },
  },

  async mounted() {
    this.setResult();
  },

  methods: {
    handleClickOutside() {
      if (this.visible) {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.preparedValue = '';
      this.$emit('close');
    },
    handlePaste(event) {
      this.$emit('paste', event);
    },
    emitSelect(item) {
      this.$emit('select', item);
    },

    setResult(result) {
      this.result = [];
      if (this.preparedValue.trim() === '') {
        this.result.push(...this.items);
      } else if (result) this.result.push(...result);

      this.$emit('search', this.result);
    },

    change() {
      this.$emit('press-enter', this.preparedValue);
    },

    getNestedValue(obj, pathToValue) {
      // eslint-disable-next-line no-shadow
      function recursive(obj, pathToValue, keyIndex) {
        if (keyIndex < pathToValue.length) {
          const result = recursive(obj[pathToValue[keyIndex]], pathToValue, keyIndex + 1);
          if (result !== undefined) return result;
        } else {
          return obj;
        }
        return null;
      }
      return recursive(obj, pathToValue, 0);
    },

    flattenItems() {
      const items = deepClone(this.items);
      let array = [];
      if (this.itemKeys.length > 0) {
        const paths = this.itemKeys.map((path) => path.split('.'));

        items.forEach((item) => {
          const obj = {};
          paths.forEach((path) => {
            const joinedKey = pathToKey(path);
            const value = this.getNestedValue(item, path);
            obj[joinedKey] = value;
          });
          array.push(obj);
        });
      } else {
        array = items;
      }

      return array;
    },

    search() {
      if (this.preparedValue.trim() === '') {
        this.setResult(this.items);
        return;
      }

      this.visible = true;

      const flattenedItems = this.flattenItems();
      const options = { ...defaultOptions, ...this.options };

      const run = new Fuse(flattenedItems, options);
      const results = run.search(this.preparedValue)
        .filter((el) => el.score <= 0.6);
      this.filterItems(results, flattenedItems);
    },

    filterItems(result, items) {
      const itemType = typeof this.items[0];

      // eslint-disable-next-line consistent-return, array-callback-return
      const resultA = result.map((resObj) => {
        if (itemType === 'object') return this.items[items.indexOf(resObj.item)];
        if (itemType === 'string') return resObj.item;
      }, this);
      this.setResult(resultA);
    },

    handleClick() {},
  },
};
</script>

<style lang="scss">
.min-height-61 {
  min-height: 61px;
}

.search-bar.v-text-field.v-input {
  border-radius: var(--round-edge);

  & .v-input__slot {
    height: 75px;
    width: 650px;
    background: var(--bbh-grey-10);
  }

  & .v-input__prepend-inner {
    padding-right: 25px;

    align-self: center;
    margin-top: 0;

    & .v-icon {
      margin: 0;

      & .v-icon__component {
        width: 30px;
        height: 30px;

        --bbh-icon-color: var(--bbh-dark-blue);
      }
    }
  }

  & .v-input__append-inner {
    align-self: center;
    color: var(--bbh-black);

    display: none;
  }

  & .v-text-field__slot input {
    font-family: "ApexNew-Book";
    font-size: 24px;
    line-height: 34px;

    color: var(--bbh-black);
    caret-color: var(--bbh-dark-blue);
  }

  &.search-bar-menu-active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.search-menu-item-icon {
  display: grid;
  place-items: center;

  & .v-icon {
    width: 20px;
    height: 20px;
  }
}

#bbh-menu-content .v-menu__content {
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  background: var(--bbh-white);

  height: max-content;
  max-height: calc(100vh * 0.5 - 42px);

  & #bbh-menu-wrapper {
    height: 100%;
    overflow: auto;
  }
}
</style>
