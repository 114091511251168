import {
  post, get, patch,
} from '../axios';

const API_PREFIX = '/freigabe';
const API_PREFIX_TASK = '/workflow';

export default {
  postFreigabe(elementId, revision, body) {
    const URL = `${API_PREFIX}/${elementId}/${revision}`;

    return new Promise((resolve, reject) => {
      post(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {String} taskId
   * @returns
   */
  fetch(taskId) {
    const URL = `${API_PREFIX_TASK}/taskProgress/${taskId}`;
    return new Promise((resolve) => {
      get(URL)
        .then((response) => resolve(response))
        .catch(() => resolve());
    });
  },

  /**
   *
   * @param {String} taskId
   * @param {String} elementId
   * @param {Number} revision
   * @param {Array} patches
   * @returns a promise
   */
  update(taskId, elementId, revision, patches) {
    const url = `${API_PREFIX_TASK}/taskProgress/${elementId}/${taskId}/${revision}`;
    const payload = {
      patch: patches,
    };
    return new Promise((resolve, reject) => {
      patch(url, payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};
