import { isJSON } from '@st/utils-js';
import MandantenDokumentSubelementTyp from './enums/MandantenDokumentSubelementTyp';
import MandantDokumentElementSubelementSections from './MandantDokumentElementSubelementSections';
import ElementTyp from './enums/ElementTyp';
import PersistObj from './PersistObj';

function getLevelKey(level) {
  return `level${(level)}`;
}
export default class MandantDokumentElementSubelement extends PersistObj {
  /**
   *
   * @param {String} id
   * @param {String} name
   * @param {String} type
   * @param {MandantDokumentElementSubelementSections | String} content
   * @param {Boolean} required
   *  if type is DOKUMENT it has to be an Array of MandantDokumentElementSubelementSections
   *  and if the type is BAUSTEIN it has to be a string
   */
  constructor(
    id = null,
    name = '',
    type = MandantenDokumentSubelementTyp.DOKUMENT,
    content = new MandantDokumentElementSubelementSections(),
    required = false,
  ) {
    super(id);
    this.name = name;
    this.type = type;
    this.content = content;
    this.required = required;
  }

  /**
   * Creates MandantDokumentElementSubelement from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  // eslint-disable-next-line default-param-last
  static fromJSON(id = null, value) {
    if (!isJSON(value)) return null;

    const {
      name = '',
      type = MandantenDokumentSubelementTyp.DOKUMENT,
      sections = new MandantDokumentElementSubelementSections(),
      content = '',
      required = false,
    } = value;

    if (MandantenDokumentSubelementTyp.isDokument(type)) {
      let localSections = sections;
      if (!(sections instanceof MandantDokumentElementSubelementSections)) {
        localSections = MandantDokumentElementSubelementSections.fromJSON(sections);
      }

      return new MandantDokumentElementSubelement(
        id,
        name,
        type,
        localSections,
        required,
      );
    } if (MandantenDokumentSubelementTyp.isBaustein(type)) {
      return new MandantDokumentElementSubelement(
        id,
        name,
        type,
        content,
        required,
      );
    }

    return null;
  }

  /**
   * @returns MandantDokumentElementSubelement in JSON representation
   */
  toJSON() {
    if (MandantenDokumentSubelementTyp.isDokument(this.type)) {
      let sections = this.content;
      if (this.content instanceof MandantDokumentElementSubelementSections) {
        sections = this.content.toJSON();
      }

      return {
        name: this.name,
        type: this.type,
        sections,
        required: this.required,
      };
    } if (MandantenDokumentSubelementTyp.isBaustein(this.type)) {
      return {
        name: this.name,
        type: this.type,
        content: this.content,
        required: this.required,
      };
    }

    return {
      name: this.name,
      type: this.type,
      content: this.content,
      required: this.required,
    };
  }

  get sections() {
    return this.content.sections;
  }

  // TODO: Tests
  // TODO: refactoring
  get labels() {
    const localCounter = {};
    let maxLevel;
    let currentAnchor;
    let labelVisible;

    const labels = {};

    this.sections.forEach((section) => {
      let { level } = section.attrs;

      // TODO: API Anpassung, typ sollte in attrs
      const { type } = section;

      const showEntry = level !== 0;

      if (ElementTyp.isKlausel(type)) {
        if (level > 0) {
          currentAnchor = level;
          labelVisible = 1;
        } else labelVisible = 0;

        if (
          localCounter[getLevelKey(level)] === null
          || localCounter[getLevelKey(level)] === undefined
        ) {
          Array.from(
            {
              length: level + 1,
            },
            (v, k) => {
              if (
                localCounter[getLevelKey(k)] === null
                || localCounter[getLevelKey(k)] === undefined
              ) localCounter[getLevelKey(k)] = 1;
              return null;
            },
          );
          if (level > 0) maxLevel = level;
        } else {
          localCounter[getLevelKey(level)] += 1;

          Array.from(
            {
              length: maxLevel + 1,
            },
            (v, k) => {
              if (k > level) {
                delete localCounter[getLevelKey(k)];
              }
              return null;
            },
          );
        }
      } else {
        switch (type) {
        case ElementTyp.UEBERSCHRIFT:
          if (level > 0) {
            currentAnchor = level;
            labelVisible = 1;
          } else labelVisible = 0;
          if (
            localCounter[getLevelKey(level)] === null
                || localCounter[getLevelKey(level)] === undefined
          ) {
            Array.from(
              {
                length: level + 1,
              },
              (v, k) => {
                if (
                  localCounter[getLevelKey(k)] === null
                      || localCounter[getLevelKey(k)] === undefined
                ) localCounter[getLevelKey(k)] = 1;
                return null;
              },
            );
            if (level > 0) maxLevel = level;
          } else {
            localCounter[getLevelKey(level)] += 1;

            Array.from(
              {
                length: maxLevel + 1,
              },
              (v, k) => {
                if (k > level) {
                  delete localCounter[getLevelKey(k)];
                }
                return null;
              },
            );
          }
          break;
        case ElementTyp.ABSCHNITT:
        case ElementTyp.KLAUSEL:
          level = currentAnchor ? level + currentAnchor : level;
          if (labelVisible && showEntry) {
            if (
              localCounter[getLevelKey(level)] === null
                  || localCounter[getLevelKey(level)] === undefined
            ) {
              Array.from(
                {
                  length: level + 1,
                },
                (v, k) => {
                  if (
                    localCounter[getLevelKey(k)] === null
                        || localCounter[getLevelKey(k)] === undefined
                  ) localCounter[getLevelKey(k)] = 1;
                  return null;
                },
              );
              maxLevel = level;
            } else {
              localCounter[getLevelKey(level)] += 1;

              Array.from(
                {
                  length: maxLevel + 1,
                },
                (v, k) => {
                  if (k > level) {
                    delete localCounter[getLevelKey(k)];
                  }
                  return null;
                },
              );
            }
          }
          break;
        default:
          break;
        }
      }

      delete localCounter.level0;

      const ordered = {};

      Object.keys(localCounter)
        .sort()
        .reduce((obj, key) => {
          ordered[key] = localCounter[key];
          return obj;
        }, {});

      const label = labelVisible && showEntry > 0
        ? JSON.stringify(Object.values(ordered).slice(0, level))
          .replace(/(\[|\])/g, '')
          .replaceAll(',', '.')
        : '';

      if (label.length > 0) {
        labels[section.sectionId] = label;
      }
    });

    return labels;
  }

  get validName() {
    return !!this.name
      && typeof this.name === 'string'
      && !!this.name.trim();
  }

  get validType() {
    return !!this.type
      && typeof this.type === 'string'
      && !!this.type.trim()
      && MandantenDokumentSubelementTyp.isType(this.type);
  }

  get validContent() {
    let validContent = false;
    if (MandantenDokumentSubelementTyp.isDokument(this.type)) {
      validContent = !!this.content
      && this.content instanceof MandantDokumentElementSubelementSections
      && this.content.valid;
    } else if (MandantenDokumentSubelementTyp.isBaustein(this.type)) {
      validContent = !!this.content
        && typeof this.content === 'string'
        && !!this.content.trim();
    }
    return validContent;
  }

  get valid() {
    const { validId } = this;

    const valid = validId
      && this.validName
      && this.validType
      && this.validContent;

    return valid;
  }
}
