import { component as MODULE } from '../store/state';

const GETTERS = {
  SUB_HEADER_ACTION_BAR: `${MODULE}sub_header_action_bar`,

  NEW_MUSTERELEMENT: `${MODULE}new_musterelement`,

  INDIVIDUELLER_VERTRAG: `${MODULE}individueller_vertrag`,

  FRAGEBOGEN_BEARBEITEN: `${MODULE}fragebogen_bearbeiten`,
  FRAGEBOGEN: `${MODULE}fragebogen`,

  PAKETVERWALTUNG: `${MODULE}paketverwaltung`,
};

export default GETTERS;
