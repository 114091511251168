import * as Sentry from '@sentry/browser';
import services from '@src/services';
import { authWrapper, get, put } from '../../../../services/axios';
import actions from '../types/action-types';
import gettersTypes from '../types/getter-types';
import mutations from '../types/mutation-types';

import Entscheidung from '../../../../model/Entscheidung';
import Fragebogen from '../../../../model/Fragebogen';
import Hashtag from '../../../../model/Hashtag';
import HashtagTyp from '../../../../model/HashtagTyp';
import HashtagStatus from '../../../../model/enums/HashtagStatus';

export default {
  [actions.FETCH_HASHTAGS_BY_ELEMENTTYPE]({ commit }, type) {
    const prm = new Promise((resolve, reject) => {
      services.editor.hashtags.fetchAllByType(type)
        .then((result) => {
          const hashtagTypes = {};
          result.data.result
            .filter(
              (item, index, items) => items.map(
                (ht) => ht.hashtagTypeId,
              ).indexOf(item.hashtagTypeId) === index,
            )
            .forEach(
              (item) => {
                const tmpObj = {
                  name: item.name,
                  id: item.hashtagTypeId,
                  isRequired: item.isRequired,
                  nr: item.nr,
                };
                hashtagTypes[item.hashtagTypeId] = HashtagTyp.fromJSON(tmpObj);
              },
            );
          const flatten = result.data.result.map((entry) => entry.hashtags).flat();
          const hashtags = flatten.filter((entry) => {
            const first = flatten.find((e) => e.hashtagId === entry.hashtagId);
            return entry === first;
          })
            .map((hashtag) => ({
              id: hashtag.hashtagId,
              name: hashtag.name,
              state: hashtag.state,
              type: hashtagTypes[hashtag.hashtagTypeId],
            }))
            .map((hashtag) => Hashtag.fromJSON(hashtag));

          commit(mutations.SET_HASHTAGS, { hashtags });
          commit(mutations.SET_HASHTAG_TYPES, { hashtagTypes });
          resolve(result);
        })
        .catch((error) => {
          reject(new Error(error.message));
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.FETCH_CHOICES]({ commit }) {
    const url = '/contractgenerator/choice/withQuestions/all';
    const prm = new Promise((resolve, reject) => {
      get(url)
        .then((result) => {
          const choices = result?.data?.result.map((choice) => Entscheidung.fromJSON(choice));
          commit(mutations.SET_CHOICES, { choices });
          resolve(result);
        })
        .catch((error) => reject(new Error(error.message)));
    });

    return authWrapper({ commit }, prm);
  },

  [actions.FETCH_MUSTERVERTRAEGE]({ commit }, payload = {}) {
    const PAYLOAD = {
      logicOperatorBetweenHashtagTypes: payload.logicOperatorBetweenHashtagTypes || 'and',
      logicOperatorWithinHashtagTypes: payload.logicOperatorWithinHashtagTypes || 'and',
      types: payload.types || [
        {
          type: 'MUSTERVERTRAG',
          state: 'migration',
        },
        {
          type: 'MUSTERVERTRAG',
          state: 'modifiable',
        },
        {
          type: 'MUSTERVERTRAG',
          state: 'approval_workflow',
        },
        {
          type: 'MUSTERVERTRAG',
          state: 'approved',
        },
      ],
      hashtagTypes: payload.hashtagTypes || [
        {
          hashtagTypeId: 'eb664327-2548-436f-95d6-e937f47fcdda',
          selectedHashtagIds: [],
        },
        {
          hashtagTypeId: 'd7881aef-0c5c-4f71-b5a0-583245b61f39',
          selectedHashtagIds: [],
        },
      ],
    };

    const prm = new Promise((resolve, reject) => {
      services.editor.search.fetchSearch(PAYLOAD)
        .then((result) => {
          const mappedMustervertraege = result?.data?.results.map(
            (contract) => ({
              ...contract,
              responsibilities: contract.responsibilities?.reduce((obj, cv) => {
                const tmpObj = { ...obj };
                const key = Object.keys(cv)[0];
                const value = cv[key];
                tmpObj[key] = value;
                return tmpObj;
              }, {}),
            }),
          );
          commit(mutations.SET_MUSTERVERTRAEGE, { mustervertraege: mappedMustervertraege });
          resolve(result?.data?.results);
        })
        .catch((error) => {
          commit(mutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.FETCH_MUSTERDOKUMENTE]({ commit }, payload = {}) {
    const PAYLOAD = {
      logicOperatorBetweenHashtagTypes: payload.logicOperatorBetweenHashtagTypes || 'and',
      logicOperatorWithinHashtagTypes: payload.logicOperatorWithinHashtagTypes || 'and',
      types: payload.types || [
        {
          type: 'MUSTERDOKUMENT',
          state: 'modifiable',
        },
        {
          type: 'MUSTERDOKUMENT',
          state: 'approval_workflow',
        },
        {
          type: 'MUSTERDOKUMENT',
          state: 'approved',
        },
        {
          type: 'MUSTERDOKUMENT',
          state: 'migration',
        },
      ],
      hashtagTypes: payload.hashtagTypes || [
        {
          hashtagTypeId: 'd7881aef-0c5c-4f71-b5a0-583245b61f39',
          selectedHashtagIds: [],
        },
        {
          hashtagTypeId: 'd439400f-aaf6-46e2-bb43-2846074a6574',
          selectedHashtagIds: [],
        },
        {
          hashtagTypeId: 'eb664327-2548-436f-95d6-e937f47fcdda',
          selectedHashtagIds: [],
        },
      ],
    };

    const prm = new Promise((resolve, reject) => {
      services.editor.search.fetchSearch(PAYLOAD)
        .then((result) => {
          commit(mutations.SET_MUSTERVERTRAEGE, { mustervertraege: result?.data?.results });
          resolve(result);
        })
        .catch((error) => {
          commit(mutations.SHOW_SNACKBAR_ERROR, error.message);

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.FETCH_QUESTIONNAIRE]({ commit }, { clientId, revision, vertragId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.getVertragQuestionnaire(clientId, revision, vertragId)
        .then((result) => {
          const localResult = result.data;
          localResult.questionnaire = Fragebogen.fromJSON(result.data.questionnaire);

          if (localResult.questionnaire.valid) {
            resolve(localResult);
          } else {
            commit(mutations.SHOW_SNACKBAR_ERROR, 'Der Fragebogen ist nicht valide. Bitte kontaktieren Sie den Support.');
            Sentry.captureMessage('Fragebogen ist nicht valide: ', localResult.questionnaire.attrs.id);

            reject(new Error('Der Fragebogen ist nicht valide'));
          }
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            const newError = new Error();
            newError.response = {
              status: 403.1,
            };
            reject(newError);
          }

          commit(mutations.SHOW_SNACKBAR_ERROR, error.message || error.status);
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.FETCH_HILFE_BY_ROUTE]({ dispatch, commit, getters }, route) {
    const prm = new Promise((resolve, reject) => {
      services.user.hilfe.fetchHilfeByRoute(route)
        .then((result) => {
          if (!result || !result.data || !result.data.hilfeVideoId) {
            resolve({ hilfeVideoId: null });
            return;
          }
          const actualHilfeVideo = getters[gettersTypes.HILFE_VIDEO];
          const actualHilfeVideoId = actualHilfeVideo.id;
          if (result.data.hilfeVideoId !== actualHilfeVideoId
            && result.data.hilfeVideoId !== null
          ) {
            dispatch(actions.FETCH_HILFE_VIDEO_NAME, result.data.hilfeVideoId);
            commit(mutations.SET_HILFE_VIDEO_ID, result.data.hilfeVideoId);
          }
          resolve(result?.data);
        })
        .catch((error) => reject(error));
    });

    return authWrapper({ commit }, prm);
  },

  [actions.FETCH_HILFE_VIDEO_NAME]({ commit }, videoId) {
    const prm = new Promise((resolve, reject) => {
      services.user.hilfe.fetchVideoName(videoId)
        .then((result) => {
          commit(mutations.SET_HILFE_VIDEO_NAME, result.data.name);
          resolve(result.data.name);
        })
        .catch((error) => reject(error));
    });

    return authWrapper({ commit }, prm);
  },

  [actions.ADD_SUBELEMENT_TO_MUSTERVERTRAG]({ commit }, { elementId, subelement }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.contract.addSubelementToMustervertrag(
        elementId,
        subelement.elementId,
      )
        .then((response) => {
          commit(mutations.ADD_SUBELEMENT_TO_MUSTERVERTRAG, {
            vertragId: elementId,
            subelement,
            refPathId: response.data.refPathId,
          });
          resolve(response.data);
        })
        .catch((error) => {
          commit(mutations.SHOW_SNACKBAR_ERROR, error.message);
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.DELETE_SUBELEMENT_IN_MUSTERVERTRAG]({ commit }, { elementId, refPathId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.contract.deleteSubelementInMustervertrag(elementId, refPathId)
        .then((response) => {
          commit(mutations.REMOVE_SUBELEMENT_IN_MUSTERVERTRAG, {
            mustervertragElementId: elementId,
            refPathId,
          });
          resolve(response.data);
        })
        .catch((error) => {
          commit(mutations.SHOW_SNACKBAR_ERROR, error.message);
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.ADD_HASHTAG]({ commit, getters }, hashtag) {
    const url = '/contractgenerator/hashtag';
    const prm = new Promise((resolve, reject) => {
      put(url, hashtag)
        .then((result) => {
          const newHashtag = {
            id: result?.data.id,
            name: result?.data.name,
            state: HashtagStatus.NEW,
            type: getters[gettersTypes.ALL_HASHTAG_TYPES][result.data.hashtagTypeId],
          };
          const newMappedHashtag = Hashtag.fromJSON(newHashtag);
          if (newMappedHashtag.valid) {
            commit(mutations.ADD_HASHTAG, { hashtag: newMappedHashtag });
            resolve(result);
          } else {
            commit(mutations.SHOW_SNACKBAR_ERROR, 'Das neue Hashtag ist nicht valide');
            reject(new Error('Das neue Hashtag ist nicht valide'));
          }
        })
        .catch((error) => {
          if (error.response?.status === 409) {
            commit(mutations.SHOW_SNACKBAR_ERROR, 'Dieser Hashtag existiert bereits');
          }
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.ADD_CHOICE]({ commit }, choice) {
    const url = '/contractgenerator/choice';
    const prm = new Promise((resolve, reject) => {
      put(url, choice)
        .then((result) => {
          commit(mutations.ADD_CHOICE, {
            choice: {
              id: choice.id,
              name: choice.name,
              comment: choice.comment,
              questions: [],
            },
          });
          resolve(result);
        })
        .catch((error) => {
          if (error.response?.status === 409) {
            commit(mutations.SHOW_SNACKBAR_ERROR, 'Diese Entscheidung existiert bereits');
          }
          const tmpError = new Error('Diese Entscheidung existiert bereits');
          tmpError.response = error.response;
          reject(tmpError);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.UPDATE_HASHTAG]({ commit }, hashtag) {
    const url = '/contractgenerator/hashtag';
    const prm = new Promise((resolve, reject) => {
      put(url, hashtag)
        .then((result) => {
          commit(mutations.UPDATE_HASHTAG, { hashtag: result.data });
          resolve(result);
        })
        .catch((error) => {
          if (error.response?.status === 409) {
            commit(mutations.SHOW_SNACKBAR, {
              text: 'Dieses Hashtag existiert bereits',
              type: 'error',
              timeout: '3000',
            });
            reject(new Error('Dieses Hashtag existiert bereits'));
          }
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.UPDATE_CHOICE]({ commit }, choice) {
    const url = '/contractgenerator/choice';
    const prm = new Promise((resolve, reject) => {
      put(url, choice)
        .then((result) => {
          commit(mutations.UPDATE_CHOICE, { choice });
          resolve(result);
        })
        .catch((error) => {
          if (error.response?.status === 409) {
            commit(mutations.SHOW_SNACKBAR, {
              text: 'Diese Entscheidung existiert bereits',
              type: 'error',
              timeout: '3000',
            });
            reject(new Error('Diese Entscheidung existiert bereits'));
          }
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.DEPRECATE_HASHTAG]({ commit }, hashtagId) {
    const url = `/contractgenerator/hashtag/${hashtagId}/deprecate`;
    const prm = new Promise((resolve, reject) => {
      put(url)
        .then((result) => {
          commit(mutations.DEPRECATE_HASHTAG, { hashtag: { id: hashtagId } });
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.DEPRECATE_CHOICE]({ commit }, choiceId) {
    const url = `/contractgenerator/choice/${choiceId}/deprecate`;
    const prm = new Promise((resolve, reject) => {
      put(url)
        .then((result) => {
          commit(mutations.DEPRECATE_CHOICE, { choice: { id: choiceId } });
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [actions.SHOW_SNACKBAR]({ commit }, snackbar) {
    commit(mutations.SHOW_SNACKBAR, { ...snackbar });
  },
};
