import { get, remove } from '../axios';
import config from './config';

const { API_PREFIX } = config;

export default {
  /**
   * get clients servicezugaenge
   *
   * @returns {Promise}
   */
  getServicezugaenge() {
    const URL = `${API_PREFIX}/servicezugaenge`;
    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * delete servicezugaenge by clientId
   *
   * @param {String} clientId
   * @returns {Promise}
   */
  deleteServicezugaenge(clientId) {
    const URL = `${API_PREFIX}/servicezugaenge/${clientId}`;
    return new Promise((resolve, reject) => {
      remove(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};
