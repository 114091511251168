import MandantDokumentElementSubelementSections from '@src/model/MandantDokumentElementSubelementSections';
import { deepClone, isEmptyOrNullish } from '@st/utils-js';
import moment from 'moment';
import { Node } from 'prosemirror-model';
import TextContentSchema from '../../../../components/prosemirror/schemas/TextContentSchema';
import KeycloakUser from '../../../../model/KeycloakUser';
import MandantDokumentElement from '../../../../model/MandantDokumentElement';
import MandantDokumentVertrag from '../../../../model/MandantDokumentVertrag';
import Tag from '../../../../model/Tag';
import types from '../../../types';

const mandantEditorMutations = types.mutations.mandantEditor;

export default {
  // SET
  [mandantEditorMutations.SET_CLIENT_ELEMENT](state, clientElement) {
    const tmpClientElement = clientElement instanceof MandantDokumentElement
      ? clientElement
      : MandantDokumentElement.fromJSON(clientElement);
    state.clientElement = tmpClientElement;
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENTS](state, { clientElements }) {
    const mappedClientElements = clientElements
      .map((indiDoc) => ({
        ...indiDoc,
        externResponsibilities: indiDoc.externResponsibilities.map(
          (resp) => KeycloakUser.fromJSON(resp),
        ),
        bbhResponsibility: KeycloakUser.fromJSON(indiDoc.bbhResponsibility),
        tags: indiDoc.tags.map((tag) => Tag.fromJSON(tag)),
      }))
      .map((indiDoc) => MandantDokumentVertrag.fromJSON(indiDoc));
    state.clientElements = mappedClientElements?.slice();
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_NAME](state, {
    clientElementId,
    clientElementName,
  }) {
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId,
    );

    clientElement.name = clientElementName;

    state.clientElements.splice();
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_NOTES](state, {
    clientElementId,
    clientElementNotes,
  }) {
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId,
    );

    clientElement.notizen = clientElementNotes;
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_RELEASE_FLAG](state, {
    clientElementId,
    freigabeStatus,
  }) {
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId,
    );

    clientElement.freigabeStatus = freigabeStatus;
    const indexOf = state.clientElements.indexOf(clientElement);

    state.clientElements.splice(indexOf, 1, clientElement);
    state.clientElement.freigabeStatus = freigabeStatus;
  },
  [mandantEditorMutations.SET_CURRENT_CLIENT_ELEMENT_ID](state, { currentClientElementId }) {
    state.currentClientElementId = currentClientElementId;
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_TAGS](state, { clientElementId, tags }) {
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId,
    );

    clientElement.tags = tags;
    const indexOf = state.clientElements.indexOf(clientElement);

    state.clientElements.splice(indexOf, 1, clientElement);
    state.clientElement.tags = tags;
  },
  [mandantEditorMutations.SET_REVISIONS_ARRAY](state, {
    revisionsArray,
  }) {
    state.revisionsArray = revisionsArray;
  },

  // UPDATE PROZESS

  [mandantEditorMutations.SET_MANDANT_COMPARE_ELEMENT](state, { mandantCompareElement }) {
    state.mandantCompareElement = mandantCompareElement;
  },
  [mandantEditorMutations.OVERWRITE_SECTION_WITH_UPDATE](state, { oldSectionId }) {
    const newSectionId = state.clientElement.clientElementUpdates[oldSectionId];
    const newSection = state.clientElement.clientElementUpdateSections[newSectionId];

    const subElementId = state.currentClientElementId;
    const subElement = state.clientElement.clientElement.subElements[subElementId];

    const { content } = subElement;
    const oldSectionIdIndex = content.order.findIndex((sectionId) => sectionId === oldSectionId);
    content.order.splice(oldSectionIdIndex, 1, newSectionId);
    content.contents[newSectionId] = newSection;

    if (oldSectionId !== newSectionId) {
      delete content.contents[oldSectionId];

      const { clientElementOriginMapUpdates } = state.clientElement;
      content.originMap[newSectionId] = clientElementOriginMapUpdates[newSectionId];
      delete content.originMap[oldSectionId];
    }

    delete state.clientElement.clientElementUpdates[oldSectionId];

    delete state.clientElement.clientElementOriginMapUpdates[newSectionId];
    delete state.clientElement.clientElementUpdateSections[newSectionId];
  },

  [mandantEditorMutations.SET_CLIENT_ELEMENT_ZWANGSUPDATE](state, payload) {
    state.clientElementZwangsupdate = payload;
  },

  [mandantEditorMutations.REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY](state, key) {
    const keyExists = Object.keys(state.clientElementZwangsupdate).includes(key);
    if (keyExists) {
      delete state.clientElementZwangsupdate[key];
    }
  },

  [mandantEditorMutations.SET_CLIENT_ELEMENT_UPDATES](state, { updates }) {
    state.clientElement.clientElementUpdateSections = updates?.clientElementUpdateSections;
    state.clientElement.clientElementUpdates = updates?.clientElementUpdates;
    state.clientElement.clientElementOriginMapUpdates = updates?.clientElementOriginMapUpdates;
  },

  [mandantEditorMutations.SET_UPDATE_COMPLETED](state, { clientElementId }) {
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId,
    );

    if (isEmptyOrNullish(clientElement)) return;

    clientElement.textUpdate = 0;
    clientElement.updateAbgeschlossen = 1;
    clientElement.update = false;
    state.clientElements.splice();
  },

  [mandantEditorMutations.INCREASE_UPDATE_IS_FINISHED_COUNTER](state) {
    state.updateIsFinishedCounter += 1;
  },

  // UPDATE
  [mandantEditorMutations.UPDATE_MANDANT_ORDER](state, { dokumentId, order }) {
    const mandantenDokument = state.clientElement.clientElement.subElements[dokumentId];
    mandantenDokument.content.order = order;
  },
  [mandantEditorMutations.UPDATE_MANDANT_SECTION](state, { dokumentId, sectionId, content }) {
    const mandantenDokument = state.clientElement.clientElement.subElements[dokumentId];
    mandantenDokument.content.contents[sectionId].content = content;
    if (!mandantenDokument.content.originMap[sectionId]) {
      mandantenDokument.content.originMap[sectionId] = {};
    }
    mandantenDokument.content.originMap[sectionId].modified = true;
  },
  [mandantEditorMutations.UPDATE_SECTION_ATTRS](state, { dokumentId, sectionId, attrs }) {
    const mandantenDokument = state.clientElement.clientElement.subElements[dokumentId];
    mandantenDokument.content.contents[sectionId].attrs = attrs;
    if (!mandantenDokument.content.originMap[sectionId]) {
      mandantenDokument.content.originMap[sectionId] = {};
    }
    mandantenDokument.content.originMap[sectionId].modified = true;
  },
  [mandantEditorMutations.UPDATE_MANDANT_DOKUMENT](state, { dokumentId, newDokument }) {
    state.clientElement.clientElement.subElements[dokumentId] = newDokument;
  },
  [mandantEditorMutations.UPDATE_MANDANT_REVISION](state) {
    state.clientElement.revision += 1;
  },
  [mandantEditorMutations.UPDATE_CLIENT_ELEMENT_DOC_NAME](state, { dokumentId, currentDocName }) {
    state.clientElement.clientElement.subElements[dokumentId].name = currentDocName;
  },

  [mandantEditorMutations.UPDATE_LEVEL_IN_CLIENT_ELEMENT](state, { sectionId, newLevel }) {
    const { currentClientElementId } = state;
    const mandantenDokument = state.clientElement.clientElement.subElements[currentClientElementId];
    mandantenDokument.content.contents[sectionId].attrs.level = newLevel;
  },

  [mandantEditorMutations.UPDATE_TAG_IN_CLIENT_ELEMENTS](state, { tagId, color, name }) {
    if (!tagId || !color || !name) return;

    state.clientElements = state.clientElements.map((clientElement) => {
      const updatedTags = clientElement.tags.map((tag) => {
        if (tag.id === tagId) {
          return { ...tag, color, name };
        }
        return tag;
      });
      return { ...clientElement, tags: updatedTags };
    });
  },

  // ADD
  [mandantEditorMutations.ADD_CLIENT_ELEMENT_TO_CLIENT_ELEMENT_LIST](state, {
    clientElement,
  }) {
    state.clientElements.push(clientElement);
  },

  [mandantEditorMutations.ADD_NACHFOLGER_TO_CLIENT_ELEMENT](state, {
    clientElementId,
    nachfolgerId,
    updateType,
  }) {
    let clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId,
    );

    if (!clientElement) {
      clientElement = {
        clientElementId,
        clientElementNachfolger: [],
      };
    }

    const nachfolger = {
      nachfolgerClientElementId: nachfolgerId,
      updateType,
    };

    clientElement.clientElementNachfolger.push(nachfolger);

    const indexOf = state.clientElements.indexOf(clientElement);

    state.clientElements.splice(indexOf, 1, clientElement);
  },

  [mandantEditorMutations.ADD_SUBELEMENT_TO_CLIENT_ELEMENT_LIST](state, {
    contract,
    newDocument,
  }) {
    const clientElement = state.clientElements
      .find((document) => document.clientElementId === contract.clientElementId);
    clientElement
      .subElements
      .push(newDocument);

    clientElement.revision = contract.revision;
  },

  [mandantEditorMutations.ADD_CLAUSE_TO_CURRENT_MANDANT_DOCUMENT](state, {
    clause,
    sectionId,
  }) {
    const { currentClientElementId } = state;
    const mandantenDokument = state.clientElement.clientElement.subElements[currentClientElementId];
    const clauseContent = clause.content.map((section) => section.content).flat();

    let previousPositionTextLength = 0;
    const comments = clause.content.reduce((acc, section) => {
      const copy = deepClone(section);
      const node = Node.fromJSON(TextContentSchema, { ...copy, type: 'section' });

      const newComments = section.attrs.comments.reduce((result, comment) => {
        const noLength = comment.from !== comment.to;
        const positionOutOfRange = node.nodeSize >= comment.from;
        if (noLength || positionOutOfRange) {
          result.push({
            ...comment,
            from: comment.from + previousPositionTextLength,
            to: comment.to + previousPositionTextLength,
          });
        }
        return result;
      }, []);

      acc.push(...newComments);

      previousPositionTextLength += node.content.size;
      return acc;
    }, []);

    mandantenDokument.content.contents[sectionId].content = clauseContent;
    mandantenDokument.content.contents[sectionId].attrs.comments = comments;
    mandantenDokument.content.originMap[sectionId].modified = true;
  },

  // REMOVE
  [mandantEditorMutations.REMOVE_SUBELEMENT_FROM_CLIENT_ELEMENT_LIST](state, {
    clientElementId,
    id,
    revision,
  }) {
    const clientElement = state.clientElements
      .find((document) => document.clientElementId === clientElementId);

    clientElement
      .subElements
      .splice(
        clientElement.subElements.map((s) => s.id).indexOf(id),
        1,
      );

    clientElement.revision = revision;
  },
  [mandantEditorMutations.REMOVE_SECTION_FROM_SUBELEMENT](state, {
    sectionId,
  }) {
    const subElementId = state.currentClientElementId;

    const { content } = state.clientElement.clientElement.subElements[subElementId];
    if (!(content instanceof MandantDokumentElementSubelementSections)) return;
    content.removeSection(sectionId);

    const originMapKeys = Object.keys(
      state.clientElement?.clientElement?.subElements[subElementId]?.content.originMap || {},
    );
    if (originMapKeys.includes(sectionId)) {
      delete state.clientElement.clientElement.subElements[subElementId].content
        .originMap[sectionId];
    }
    removeUpdateFromClientElement(state, sectionId);
  },

  [mandantEditorMutations.REMOVE_UPDATE_FROM_CLIENT_ELEMENT](state, { sectionId }) {
    removeUpdateFromClientElement(state, sectionId);
  },

  // DEPRECATE
  [mandantEditorMutations.DEPRECATE_CLIENT_ELEMENT](state, { clientElementId }) {
    const indexOfDoc = state.clientElements.findIndex(
      (doc) => doc.clientElementId === clientElementId,
    );
    state.clientElements.splice(indexOfDoc, 1);
  },

  // DELETE
  [mandantEditorMutations.DELETE_CLIENT_ELEMENT_RESPONSIBILITY](state, {
    clientElementId,
    keycloakUserUuid,
  }) {
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId,
    );
    if (!clientElement) return;

    clientElement.externResponsibilities = clientElement.externResponsibilities.filter(
      (responsibility) => responsibility.id !== keycloakUserUuid,
    );

    const indexOf = state.clientElements.indexOf(clientElement);

    state.clientElements.splice(indexOf, 1, clientElement);
  },
  [mandantEditorMutations.DELETE_TAG_FROM_ALL_CLIENT_ELEMENTS](state, { tagId }) {
    state.clientElements = state.clientElements.map((clientElement) => {
      const filteredTags = clientElement.tags.filter((tag) => tag.id !== tagId);
      return { ...clientElement, tags: filteredTags };
    });
  },

  // PUT
  [mandantEditorMutations.PUT_CLIENT_ELEMENT_RESPONSIBILITY](state, {
    clientElementId,
    keycloakUser,
  }) {
    state.clientElements
      .find((document) => document.clientElementId === clientElementId)
      .externResponsibilities.push(keycloakUser);
  },
  [mandantEditorMutations.UPDATE_CLIENT_ELEMENT_REVISIONS_ARRAY](state, {
    revisionNr,
  }) {
    state.revisionsArray.push({
      revisionNr,
      name: moment().format('DD.MM.YYYY HH:mm'),
    });
    state.revisionsArray.sort(
      (a, b) => b.revisionNr - a.revisionNr,
    );
  },

};

function removeUpdateFromClientElement(state, sectionId) {
  const clientElementUpdateKeys = Object.keys(
    state.clientElement?.clientElementUpdates || {},
  );
  if (clientElementUpdateKeys.includes(sectionId)) {
    delete state.clientElement.clientElementUpdates[sectionId];
  }
}
