export default class KommentarType {
  static INTERNAL = 'internal';

  static EXTERNAL = 'external';

  static isValidState(state) {
    const states = [
      this.INTERNAL, this.EXTERNAL,
    ];
    return states.includes(state);
  }

  static isStateInternal(state) {
    return state === this.INTERNAL;
  }

  static isStateExternal(state) {
    return state === this.EXTERNAL;
  }
}
